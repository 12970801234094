import BaseLayout from "../baseLayout/baseLayout";
import LaptopChecked from "../../assets/img-laptop-checked.svg";
import "./restrict.scss";

export const Restrict = () => {
    return <BaseLayout showRightChatArea={false} noRightArea={true} showHeaderActions={false} showFullHeader={false}>
        <div className='restrict-container-wrap'>
            <div className='restrict-container'>
                <div className="restrict-wrap">
                    <img src={LaptopChecked} />
                    <p className="text-bolder">
                    Mr. EzPz is currently available on desktop and laptop only
                    </p>
                    <p className="text-lighter">
                        Join us there and stay tuned for our mobile version, coming soon!
                    </p>
                </div>

                <div className='restrict-footer'>
                    <p className='fw-medium mt-0 mb-0'>
                        &copy; 2024 Mr. EzPz<span className='seperator'> | </span>All Rights Reserved
                    </p>
                    <p className='fw-medium mt-0 mb-0'>
                        <a href="" onClick={() => window.open('/terms-and-conditions','_blank')}>Terms & Conditions</a>
                        <span className='seperator'> | </span>
                        <a href="" onClick={() => window.open('/privacy-policy','_blank')} >Privacy Policy</a>
                    </p>
                </div>
            </div>
        </div>
    </BaseLayout>
};