import React from 'react'
import { Container, Grid, Box } from '@mui/material';
import Image01 from '../../assets/le-img-01.svg'
import Image02 from '../../assets/le-img-02.svg'
import Image03 from '../../assets/le-img-03.svg'
import Image04 from '../../assets/le-img-04.svg'
import SectionSeperator from '../../assets/border-img.svg'
import './home-learning-experience.scss';
import SectionTitle from '../section-title/section-title';

const LearningExperience = ({ }) => {
  return (
    <section className='section-wrap-le'>
        <Container maxWidth='lg'>
            <Box mb={{xs:5, md:10}}>
                <SectionTitle
                    title={'Learning Experiences Designed Just for You'}
                    subTitle={'We bring hyper-personalization with full-circle awareness and a human touch'} 
                />
            </Box>
            <Grid container item spacing={{xs:3, md:11.25}} alignItems={'center'} justifyContent={'center'} className='order-reverse'>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-img text-center'>
                        <img src={Image01} alt='' className='mw-100 w-100' />
                    </Box>
                    <Box display={{xs:'block', md: 'none'}} mt={3}>
                        <h3 className='text-h3 subtitle text-muted mb-0'>Upload class lecture slides or reading materials in seconds. Mr.EzPz turns even the most complex topics into engaging dialogues to break down concepts and connect the dots.</h3>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-content'>
                        <h2 className='text-h2 title mt-0 mb-0'>Individualized and Interactive Learning at Your Fingertips</h2>
                        <Box display={{xs:'none', md: 'block'}}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Upload class lecture slides or reading materials in seconds. Mr.EzPz turns even the most complex topics into engaging dialogues to break down concepts and connect the dots.</h3>
                        </Box>
                    </Box>
                </Grid>
            </Grid> 
            <Grid container item spacing={{xs:3, md:11.25}} alignItems={'center'} justifyContent={'center'} mt={1}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-img text-center'>
                        <img src={Image02} alt='' className='mw-100 w-100' />
                    </Box>
                    <Box display={{xs:'block', md: 'none'}} mt={3}>
                        <h3 className='text-h3 subtitle text-muted mb-0'>Challenge your knowledge with exercises and assessments made by Mr. EzPz. You will receive explanations and answers that will prepare you for class tests.</h3>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-content' mt={{xs:6.25, md:0}}>
                        <h2 className='text-h2 title mt-0 mb-0'>Gain Mastery through Custom-Made Assessments</h2>
                        <Box display={{xs:'none', md: 'block'}}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Challenge your knowledge with exercises and assessments made by Mr. EzPz. You will receive explanations and answers that will prepare you for class tests.</h3>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container item spacing={{xs:3, md:11.25}} alignItems={'center'} justifyContent={'center'} className='order-reverse' mt={1}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-img text-center'>
                        <img src={Image03} alt='' className='mw-100 w-100' />
                    </Box>
                    <Box display={{xs:'block', md: 'none'}} mt={3}>
                        <h3 className='text-h3 subtitle text-muted mb-0'>Key insights surface with your study sessions, measuring your performance across topics. This allows you to identify meaningful goals and focus effort where needed most. &#40;Coming Soon&#41;</h3>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-content' mt={{xs:6.25, md:0}}>
                        <h2 className='text-h2 title mt-0 mb-0'>Map your Academic Growth</h2>
                        <Box display={{xs:'none', md: 'block'}}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Key insights surface with your study sessions, measuring your performance across topics. This allows you to identify meaningful goals and focus effort where needed most. &#40;Coming Soon&#41;</h3>
                        </Box>
                    </Box>
                </Grid>
            </Grid> 
            <Grid container item spacing={{xs:3, md:11.25}} alignItems={'center'} justifyContent={'center'} mt={1}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-img text-center'>
                        <img src={Image04} alt='' className='mw-100 w-100' />
                    </Box>
                    <Box display={{xs:'block', md: 'none'}} mt={3}>
                        <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz recalls details from past conversations and connects on a personal level – checking in on your week, providing support, and celebrating your successes. &#40;Coming Soon&#41;</h3>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-content' mt={{xs:6.25, md:0}}>
                        <h2 className='text-h2 title mt-0 mb-0'>Always Present and Encouraging</h2>
                        <Box display={{xs:'none', md: 'block'}}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz recalls details from past conversations and connects on a personal level – checking in on your week, providing support, and celebrating your successes. &#40;Coming Soon&#41;</h3>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box className="section-seperator text-center" mt={{xs:4.75, md: 11.6}} >
                <img src={SectionSeperator} alt='' className='mw-100 w-100' />
            </Box>
        </Container>
       
    </section>
  )
}

export default LearningExperience;
