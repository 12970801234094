import React, { useState } from 'react'
import FeedbackDialogIcon from '../../assets/ic-chat-feedback.svg'
import FeedbackDialogCharacter from '../../assets/ezpz-feedback.svg'
import Button from '../button/button'
import CustomDialog from '../dialog/dialog'
import "./feedback-dialog.scss"
import TextArea from '../textArea/textArea'
import FeedbackConfirmationDialog from '../feedback-confirmation-dialog/feedback-confirmation-dialog'
import { authFeedback } from '../../axios'
import { encodeToBase64 } from '../../methods/commonMethods'

const FeedbackDialog = ({openFeeback, setOpenFeedback}) => {
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [openFeedbackConfirmation, setFeedbackConfirmation] = useState(false);
    const [disableForm, setDisableForm] = useState(false);

    const handleCancelFeedback = () => {
        setOpenFeedback(false);
    }

    const handlefeedbackInput = (e) => {
        setFeedbackMessage(e.target.value);
    }

    const postFeedback = (feedbackMessage) => {
        const feedbackModel = { feedback_message: feedbackMessage.toString() };
        return new Promise (async (resolve, reject) => {
            await authFeedback.post(`/feedback`, {payload: encodeToBase64(feedbackModel)}).then(() => {
                return resolve();
            }).catch(() => {
                return reject();
            });
        });
    }

    const handleSubmitFeedback = async () => {
        if (feedbackMessage) {
            try {
                setDisableForm(true);
                await postFeedback(feedbackMessage);
                setFeedbackConfirmation(true);
            } catch (error) {
                console.error('Failed to Submit Feedback :' + error);
            } finally {
                setDisableForm(false);
            }
        }
    }

  return (
    <>
    <div className='feedback-dialog-wrap'>
       <CustomDialog size='md' open={openFeeback}>
        <div className='feedback-dialog-container'>
            <div className='dialog-left-area'>
                <img src={FeedbackDialogCharacter} alt='EzPz' />
            </div>
            <div class='dialog-right-area'>
                <div className='feedback-form-area'>
                    <div className='title-wrap'>
                        <div className='title'>
                            <img src={FeedbackDialogIcon} className='icon' alt='feedback' />
                            <h2 className='text-h2 fw-sbold m-0'>Feedback</h2>
                        </div>
                        <div className='description'>
                            <p className='text-body2 fw-medium m-0'>
                                We value your opinion. Share your feedback anytime and help us improve Mr. EzPz. Thank you for being part of the journey.
                            </p>
                        </div>
                    </div>
                    <div className='form'>
                        <div className='form-group'>
                            <label className='input-label text-h4 m-0 fw-medium'>Message</label>
                            <div className='field-wrap field-child'>
                                <TextArea name={"feedback"} rows={14} onChange={handlefeedbackInput} disabled={disableForm}/>
                                <div className='action-area'>
                                    <Button outlineBtn text={'Cancel'} onClick={handleCancelFeedback} disabled={disableForm} />
                                    <Button primary text={'Submit'} onClick={handleSubmitFeedback} disabled={disableForm} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </CustomDialog>
    </div>
    {openFeedbackConfirmation && <FeedbackConfirmationDialog openFeedbackConfirmation={openFeedbackConfirmation} setFeedbackConfirmation={setFeedbackConfirmation} setOpenFeedback={setOpenFeedback} />}
    </>
  )
}

export default FeedbackDialog;
