import { useState } from 'react';
import Button from '../button/button';
import CustomDialog from '../dialog/dialog';
import { Mermaid } from "mdx-mermaid/lib/Mermaid";
import ZoomIn from "../../assets/ic-zoom-in.svg";
import ZoomOut from "../../assets/ic-zoom-out.svg";
import "./diagram-dialog.scss";

export const DiagramDialog = ({ open, closeDialog, chartCode }) => {
    const [zoomLevel, setZoomLevel] = useState(1);

    const handleZoomIn = () => {
        if (zoomLevel < 2) setZoomLevel(zoomLevel + 0.1);
    };

    const handleZoomOut = () => {
        if (zoomLevel > 0.1) setZoomLevel(Math.max(0.1, zoomLevel - 0.1));
    };

    return (
        <CustomDialog
            size='lg'
            open={open}
            dialogTitle={'Diagram'}
            onClose={() => closeDialog()}
        >
            <div className="zoom-btn">
                <Button outlineBtn onClick={handleZoomIn} iconOnly={ZoomIn} />
                <Button outlineBtn onClick={handleZoomOut} iconOnly={ZoomOut} />
            </div>
            <div className="diagram-container">
                <div className="svg-container" style={{ width: `${zoomLevel * 100}%`, height: `${zoomLevel * 100}%`, overflow: 'auto' }}>
                    <Mermaid chart={`${chartCode}`} />
                </div>
            </div>
        </CustomDialog>
    );
};
