// Learning Imports
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import SampleDiagram from '../../assets/sample-diagram.svg';
import { BaseLayout, Button, CustomDrawer, PageHeader, PdfContainer, ExplainContent, HistoryContent, AIResponse, UserResponse, Loader, CustomDialog, Highlights, FeedbackConfirmationDialog, FeedbackDialog } from "../../components";
import ChatBotIcon from '../../assets/chat-bot-bubble-icon.svg'
import DeleteIcon from '../../assets/ic-del.svg'
import LogoutIcon from '../../assets/ic-logout.svg'
import ArrowIcon from '../../assets/arrow-right.svg'
import QuestionIcon from '../../assets/ic-question-fill.svg';
import StarsIcon from '../../assets/ic-stars.svg'
import { scrollToBottom, convertToLocalDate, extractWordsFromStart, convertToLocalTime, getDocData, encodeToBase64, randomizeQuiz } from "../../methods/commonMethods";
import { getHighlights } from "../../components/observables/observables"
import { authChat } from "../../axios";
import { ButtonDictionary, ConversationTypId, ButtonType } from "../../constants/enums";
import { Mermaid } from "mdx-mermaid/lib/Mermaid";
import "./learning.scss"
import { useLocation } from "react-router-dom";
import FormattedAIResponse from "../../components/chatWindow/formattedAIResponse";
import { DiagramDialog } from "../../components/diagram-dialog/diagram-dialog";

// Quiz Imports
// import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import GuidingLightIcon from '../../assets/ic-bulb-black.svg'
import RegenrateIcon from "../../assets/ic-regenrate.svg";
import { QuizHeader, Radio, ToggleButton, QuizQuestion, Checkbox } from "../../components";
import { QuizSettingsQuestionTypeId } from "../../constants/enums";
// import { authChat } from "../../axios";
import { FALSE } from "sass";
// import { convertToLocalDate, encodeToBase64, getDocData } from "../../methods/commonMethods";
// import DeleteIcon from '../../assets/ic-del.svg'
import SettingsIcon from '../../assets/ic-settings.svg'
import GifLoaderMain from "../../assets/gif-loader-main.gif";
import "./quiz.scss";
import { getQuizHistoryItems } from "../../components/observables/observables";


const Main = () => {

    const { type } = useParams();
    const [pageState, setPageState] = useState()

    useEffect(() => {
        setPageState(type)
    }, [type])

    // const MemoizedPdfViewer = memo(PdfContainer)



    // Learning Page
    const location = useLocation()

    //############################ DEFINE STATES HERE ########################//

    const [disableSendBtn, setDisableSendBtn] = useState(false)
    const [disableSuggestionClick, setDisableSuggestionClick] = useState(false)
    const [showSuggestionBlock, setShowSuggestionBlock] = useState(false)

    //======================== State for Text magic selection pop up ========================//
    const [showTextMagicPopup, setShowTextMagicPopup] = useState(false)

    const [showAction, setShowAction] = useState(false)

    //########################## Summary Drawer State ###########################//
    const [showSummaryDrawer, setShowSummaryDrawer] = useState(false)

    //######################### State for Summary Data ##########################//
    // Example model: {docTitle: "", summary: ""}
    const [summaryData, setSummaryData] = useState("")

    //########################### Diagram drawer state ############################//
    const [diagramDrawerDataFromAPI, setDiagramDrawerDataFromApi] = useState({})

    //========================== State for diagram drawer data ======================//
    // Example Model: { isDrawerVisible: false, selectedText: '' }
    const [diagramDrawerData, setDiagramDrawerData] = useState({})

    //########################## Summarize Drawer State ############################//

    //========================= State to show summarize drawer ===========================//
    // Example Model: { isDrawerVisible: false, selectedText: '' }
    const [summarizeDrawerData, setSummarizeDrawerData] = useState({})

    //########################## Simplify Drawer States start ########################//

    //========================== State to show simplify drawer =======================//
    // Example Model: { isDrawerVisible: false, selectedText: '' }
    const [simplifyDrawerData, setSimplifyDrawerData] = useState({})

    //########################## Simplify Drawer States end ########################//

    //============================= State for show Delete Confirmation ==============================//
    // const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    //============================= State to persist DocHistoryItemId for delete confirmation ==========================//
    const [docHistoryItemId, setDocHistoryItemId] = useState(0)

    //======================= State for Doc History Drawer Items ====================//

    //=============================== State to track if user is coming from doc history item ==============================//
    const [isComingFromHistory, setIsComingFromHistory] = useState(false)

    // Example Model: { title: 'Explain', description: 'Oxidizing ketone bodies', date: '20 Oct 23', onClick: () => { }, onDelete: () => { } }
    const [docHistoryDrawerItems, setDocHistoryDrawerItems] = useState([])

    //======================= State for DocHistory drawer ====================//
    const [showDocHistoryDrawer, setShowDocHistoryDrawer] = useState(false)

    //========================= State for loader =========================//
    const [showAIResponseLoader, setShowAIResponseLoader] = useState(false)

    //================= ref for scroll to bottom ==================//
    const containerRef = useRef(null);

    //===================== State for user input ====================//
    // Example Model:   {message:'', role:'', isUpdated: false}
    const [objExpDrawerInput, setObjExpDrawerInput] = useState({})

    //===================== State for AI response ======================//
    // Example Model:   {message:'', role:''}
    const [objExpDrawerOutput, setObjExpDrawerOutput] = useState({})

    //====================== State for Conversation ==========================//
    // Example model:   [array contains objects of objExpDrawerInput and objExpDrawerOutput]
    const [arrayExpDrawerConversation, setArrayExpDrawerConversation] = useState([])

    //====================== Set ref of PDFUploaded to call its functions =================//
    const refPdfUploaded = useRef(null)

    //=============================== Ref for scrollable drawer content ================================//
    const refDrawerContentScrollable = useRef(null)

    //======================= State for Explain Drawer Data ===========================//
    // Example Model: { isDrawerVisible: false, selectedText: '' }
    const [explainDrawerData, setExpDrawerData] = useState({})

    //====================== State for explaination heading ==============================//
    // Example Model:   { explaination: '', questions: [] }
    const [objExplaination, setObjExplaination] = useState([])

    //===================== State for content of drawer Explain, Simplify, Summarize =========================//
    const [contentArray, setContentArray] = useState([])
    const [showContentLoader, setShowContentLoader] = useState(false)

    const [diagramDialog, setDiagramDialog] = useState(false)

    //############################### STATES END ###################################//



    //############################### DEFINE USEEFFECTS HERE ###############################//

    // useEffect(() => {
    //     // console.log("State-changed-in-learning", explainDrawerData)
    // }, [explainDrawerData])

    // useEffect(() => {
    //     // console.log("expl-in-learning", objExplaination)
    // }, [objExplaination])

    //============================== Use effect for disble need more suggestions when submit button is disable==========================//
    useEffect(() => {
        if (disableSendBtn) {
            setDisableSuggestionClick(true)
        } else {
            setDisableSuggestionClick(false)
        }
    }, [disableSendBtn])

    //======================== Use effect for disabling send btn when ai is getting response ================================//
    useEffect(() => {
        if (showAIResponseLoader) {
            // disable send btn
            setDisableSendBtn(true)
        } else {
            // enable send btn
            setDisableSendBtn(false)
        }
    }, [showAIResponseLoader])

    //=========================== Use Effect for showing text magic selection upon upload only ===========================//
    // useEffect(() => {
    //     let isDocUploaded = localStorage.getItem("isDocUploaded")
    //     if (isDocUploaded) {
    //         setShowTextMagicPopup(true)
    //         localStorage.removeItem("isDocUploaded")
    //     }
    // }, [])

    useEffect(() => {
        //console.log("objInput", objExpDrawerInput)

        // Handling to call function only when objExpDrawerInput.message is updated
        if (objExpDrawerInput.isUpdated) {
            //console.log("Function called")
            // Check if the ref is set and the function exists in the child component
            if (refPdfUploaded.current && refPdfUploaded.current.handleChat) {
                const highlightType = JSON.parse(localStorage.getItem("HighlightType"))
                let selectedText = ""

                switch (highlightType) {
                    case ButtonType.Explain:
                        selectedText = explainDrawerData.selectedText
                        break

                    case ButtonType.Simplyfy:
                        selectedText = simplifyDrawerData.selectedText
                        break

                    case ButtonType.Summarize:
                        selectedText = summarizeDrawerData.selectedText
                        break

                    case ButtonType.Diagram:
                        selectedText = diagramDrawerData.selectedText
                        break

                    default:
                        return
                }

                // Call the child function
                refPdfUploaded.current.handleChat(objExpDrawerInput.message, ConversationTypId.DrawerConversation, selectedText);
            }
            setObjExpDrawerInput({})
        }

    }, [objExpDrawerInput.message])

    useEffect(() => {
        // console.log("Conversation", arrayExpDrawerConversation)

        if (explainDrawerData.isDrawerVisible) {
            // Scroll to bottom if user response or AI response is appended in conversation
            scrollToBottom(refDrawerContentScrollable)
        }

        else if (simplifyDrawerData.isDrawerVisible) {
            // Scroll to bottom if user response or AI response is appended in conversation
            scrollToBottom(refDrawerContentScrollable)
        }

        else if (summarizeDrawerData.isDrawerVisible) {
            // Scroll to bottom if user response or AI response is appended in conversation
            scrollToBottom(refDrawerContentScrollable)
        }

        else if (diagramDrawerData.isDrawerVisible) {
            // Scroll to bottom if user response or AI response is appended in conversation
            scrollToBottom(refDrawerContentScrollable)
        }

    }, [arrayExpDrawerConversation])

    // useEffect(() => {
    //     console.log("historyState", docHistoryDrawerItems)
    // }, [docHistoryDrawerItems])

    //################################## USE EFFECTS END ##################################//


    //############################ API Calls ##############################//

    //*************Replaced by observables************/
    //======================== Function to get doc history items based on current doc history id =========================//
    const getDocHistory = async () => {
        const docData = await getDocData()
        if (docData) {
            authChat.get(`/docHistory/GetDocHistoryItems?documentId=${docData.document_id}`)
                .then((res) => {
                    setDocHistoryDrawerItems(res.data.docHistoryItems)
                    // console.log("GetDocHistoryItems", res)
                })
                .catch((err) => {
                    console.log("getDocHistory error: ", err)
                })
        }

    }

    const getSummary = async () => {
        const docData = await getDocData()
        if (docData) {
            if (!summaryData.summary) {
                authChat.get(`/summary?documentID=${docData.document_id}`)
                    .then((res) => {
                        setSummaryData({
                            docTitle: docData.customDocName,
                            summary: res.data.generated_summary
                        })
                        setShowAction(true)
                    })
                    .catch((err) => {
                        console.log("getSummary error: ", err.message)
                    })
            }
        }
    }

    const getRelatedQuestions = async (selectedTExt, drawerMainContent) => {
        const docData = await getDocData()
        if (docData) {
            authChat.post(`/getRelatedQuestions`, {payload: encodeToBase64({
                documentId: docData.document_id,
                user_highlight: selectedTExt,
                responseAIMessage: objExpDrawerOutput.message ?? drawerMainContent
            })})
            .then((res) => {
                setObjExplaination({
                    ...objExplaination,
                    questions: res.data.relatedQuestions
                })

                setDisableSuggestionClick(false)
            })
            .catch((err) => {
                console.log("getRelatedQuestions error:", err)
            })
        }
    }

    const regenerateAiResponse = async (selectedText, highlightBtnType, regenerateOptionType) => {
        const docData = await getDocData()
        if (docData) {

            const teachMeModel = {
                user_highlight: selectedText,
                documentID: docData.document_id,
                highlightBtnType: highlightBtnType,
                regenerateType: regenerateOptionType
            }

            authChat.post(`/teachme`, {payload: encodeToBase64(teachMeModel)})
                .then((res) => {
                    // console.log("Regenrate response", res)

                    updateDocHistoryItemAiResponse(res.data.TxtResponse)

                    setObjExplaination({
                        ...objExplaination,
                        explaination: res.data.TxtResponse
                    })

                    // Pager Data
                    setContentArray((preValues) => [
                        ...preValues,
                        res.data.TxtResponse
                    ])

                    // set loader to false since response is received
                    setShowContentLoader(false)
                })
                .catch((err) => {
                    console.log("regenerateAiResponse error:", err)
                })
        }
    }

    const updateDocHistoryItemAiResponse = (aiResponse) => {
        const updateDocHistoryModel = {
            DocumentHistoryItemId: JSON.parse(localStorage.getItem("currentDocHistoryItemId")),
            AiResponse: aiResponse
        }

        authChat.post("/docHistory/updateAiResponse", {payload: encodeToBase64(updateDocHistoryModel)})
            .then((res) => {
                updateHighlightsHistory()
            })
            .catch((err) => {

            })
    }


    //########################################### DEFINE FUNCTIONS HERE ########################################//

    //################################## Explain Drawer Functions ###################################//

    //======================= Function to receive values from PDF Section component and update the Explain Drawer data =========================//
    //============ Pass this function as a prop in the child component =============//
    //============ Pass the prop of second child in third child and do it again till it reaches PDF Section component============//
    //============ Create a function that sets values of the passed prop and func is called upon an event like click =============//
    const setExplainDrawerState = (newValues) => {
        // console.log("DrawerNewValues", newValues)
        setExpDrawerData(newValues)
    }

    //===================== Func to set explaination data ========================//
    const setExplainationText = (response) => {
        setObjExplaination({
            explaination: response.data.TxtResponse,
            questions: response.data.relatedQuestions
        })

        // console.log("objExp", objExplaination)
        setShowAction(true)
        setShowSuggestionBlock(true)
        // console.log("ResponseLearning", response)

        // Pager Data
        setContentArray([
            response.data.TxtResponse
        ])
    }

    //==================== Func to execute upon clicking send button ======================//
    const onClickSendBtn = (inputValue) => {

        const trimmedMessage = inputValue.trim()
        if (trimmedMessage) {
            if (trimmedMessage === '' || trimmedMessage === '"' || trimmedMessage === '" "' || trimmedMessage === '""') {
                alert("Invalid input! Please enter valid text.");
            } else {
                //console.log("InputReceived", inputValue)

                // setShowAIResponseLoader to true to show loader
                setShowAIResponseLoader(true)

                const inputModel = { message: inputValue, role: 'user', isUpdated: true }

                //console.log("Input", objExpDrawerInput.message)
                setObjExpDrawerInput(inputModel)

                setArrayExpDrawerConversation((preValues) => [
                    ...preValues,
                    inputModel
                ])

                //**************************************** Remove questions when a chat is send ***************************************/
                // remove questions from objExplanation
                setObjExplaination((prevState) => {
                    const { questions, ...rest } = prevState
                    return rest
                })
                //***************************************************************************************************************************************/
            }
        }
    }

    //============================= Func to handle ExpDrawer Chat Response ==========================//
    const handleExpDrawerChatResponse = (chatResponse) => {
        // console.log("chatLearning", chatResponse)

        // setShowAIResponseLoader to false to hide loader
        setShowAIResponseLoader(false)

        const aiResponseModel = { message: chatResponse.data.message, role: 'assisstant' }

        setObjExpDrawerOutput(aiResponseModel)

        setArrayExpDrawerConversation((preValues) => [
            ...preValues,
            aiResponseModel
        ])

        setDisableSuggestionClick(false)
    }

    //======================== Reset Explain Drawer when back btn clicked =====================//
    const onBackBtnExplainDrawer = () => {
        if (isComingFromHistory) {
            setShowDocHistoryDrawer(true)
        }

        setShowAction(false)

        setIsComingFromHistory(false)

        setExpDrawerData({})

        setObjExplaination({})

        setObjExpDrawerInput({})

        setObjExpDrawerOutput({})

        setArrayExpDrawerConversation([])

        setContentArray([])

        setShowAIResponseLoader(false)

        setDisableSuggestionClick(false)

        setShowSuggestionBlock(false)

        // remove explain drawer conversation id form local storage
        localStorage.removeItem("conversation_id")

        // remove current doc history id
        localStorage.removeItem("currentDocHistoryItemId")

        // remove current drawer type
        localStorage.removeItem("HighlightType")

        // remove state of socratic learning
        localStorage.removeItem("isSocraticLearning")
    }

    //========================== Function for on click related question ===========================//
    const handleOnClickRelatedQuestion = (question) => {
        //console.log("Question", question)

        // Call onClickSendBtn to behave as if user has send the input
        onClickSendBtn(question)
        setObjExplaination(prevState => {
            const { questions, ...rest } = prevState
            return rest
        })

        setDisableSuggestionClick(true)
    }

    const onClickCopy = async (text) => {
        if (text) {
            await navigator.clipboard.writeText(text)
        }
    }

    const onClickSuggestions = (selectedText, drawerMainContent) => {
        if (objExplaination.questions) {
            setObjExplaination(prevState => {
                const { questions, ...rest } = prevState
                return rest
            })
            // ({
            //     explaination: response.data.TxtResponse,
            //     questions: response.data.relatedQuestions
            // })
        } else {
            setDisableSuggestionClick(true)
            getRelatedQuestions(selectedText, drawerMainContent)
        }
    }

    const getOptionIdFromChild = (optionId) => {
        // set loader to true
        setShowContentLoader(true)

        // console.log("option", optionId)
        const highlightType = JSON.parse(localStorage.getItem("HighlightType"))
        let selectedText = ""

        switch (highlightType) {
            case ButtonType.Explain:
                selectedText = explainDrawerData.selectedText
                break

            case ButtonType.Simplyfy:
                selectedText = simplifyDrawerData.selectedText
                break

            case ButtonType.Summarize:
                selectedText = summarizeDrawerData.selectedText
                break

            default:
                return
        }

        regenerateAiResponse(selectedText, highlightType, optionId)

        setObjExplaination({})
    }

    const formatContent = (strContent) => {
        if (strContent) {
            if (strContent.includes("\n")) {
                const pointsArray = strContent.split('\n').filter(point => point.trim() !== '')

                // Trim each substring until an alphabet or a number is encountered
                // const trimmedArray = pointsArray.map(point => point.replace(/^[^\w\d]+/, ''));
                return (
                    pointsArray.map((point, index) => (
                        <p key={index} className="text-body1 text-grey-light-a mt-6 fw-normal" >{point}</p>
                    ))
                )
            } else {
                return <p className="text-body1 text-grey-light-a mt-6 fw-normal" >{strContent}</p>
            }
        }
    }

    //#################################### Simplify Drawer Functions ###############################//
    const onClickSimplifyDrawerBackBtn = () => {
        if (isComingFromHistory) {
            setShowDocHistoryDrawer(true)
        }

        setShowAction(false)

        setIsComingFromHistory(false)

        setSimplifyDrawerData({})

        setObjExplaination({})

        setObjExpDrawerInput({})

        setObjExpDrawerOutput({})

        setArrayExpDrawerConversation([])

        setContentArray([])

        setShowAIResponseLoader(false)

        setDisableSuggestionClick(false)

        setShowSuggestionBlock(false)

        // remove explain drawer conversation id form local storage
        localStorage.removeItem("conversation_id")

        // remove current doc history id
        localStorage.removeItem("currentDocHistoryItemId")

        // remove current drawer type
        localStorage.removeItem("HighlightType")

        // remove state of socratic learning
        localStorage.removeItem("isSocraticLearning")
    }

    //################################# Summarize Drawer Functions ############################//
    const onClickSummarizeDrawerBackBtn = () => {
        if (isComingFromHistory) {
            setShowDocHistoryDrawer(true)
        }

        setShowAction(false)

        setIsComingFromHistory(false)

        setSummarizeDrawerData({})

        setObjExplaination({})

        setObjExpDrawerInput({})

        setObjExpDrawerOutput({})

        setArrayExpDrawerConversation([])

        setContentArray([])

        setShowAIResponseLoader(false)

        setDisableSuggestionClick(false)

        setShowSuggestionBlock(false)

        // remove explain drawer conversation id form local storage
        localStorage.removeItem("conversation_id")

        // remove current doc history id
        localStorage.removeItem("currentDocHistoryItemId")

        // remove current drawer type
        localStorage.removeItem("HighlightType")

        // remove state of socratic learning
        localStorage.removeItem("isSocraticLearning")
    }

    //################################ Summary Drawer Functions ###########################//
    const onClickSummaryBtn = () => {
        setShowSummaryDrawer(true)
        // const docData = JSON.parse(localStorage.getItem("doc_data"))
        // if (docData) {
        //     setSummaryData({
        //         docTitle: docData.documentTitle
        //     })

        // }
        getSummary()
    }

    const onBackBtnSummaryDrawer = () => {
        setShowSummaryDrawer(false)
        setShowAction(false)
    }

    //################################# Diagram Drawer Functions ##################################//

    const onClickDiagramDrawerBackBtn = () => {
        if (isComingFromHistory) {
            setShowDocHistoryDrawer(true)
        }

        setIsComingFromHistory(false)

        setDiagramDrawerData({})

        setDiagramDrawerDataFromApi({})

        setObjExpDrawerInput({})

        setObjExpDrawerOutput({})

        setArrayExpDrawerConversation([])

        setContentArray([])

        setDisableSuggestionClick(false)

        setShowSuggestionBlock(false)

        setShowAIResponseLoader(false)
        // remove explain drawer conversation id form local storage
        localStorage.removeItem("conversation_id")

        // remove current doc history id
        localStorage.removeItem("currentDocHistoryItemId")

        // remove state of socratic learning
        localStorage.removeItem("isSocraticLearning")
    }

    const setDiagramDrawerDataFromProp = (response) => {
        setDiagramDrawerDataFromApi({
            mermaidJsCode: response.data.mermaidJSCode,
            txtResponse: response.data.TxtResponse
        })
    }


    //#################################### Doc History Drawer Functions ###################################//

    //========================= Function for on click Doc History ===========================//
    const onClickDocHistory = () => {
        setShowDocHistoryDrawer(true)
        getDocHistory()
    }

    //======================== Function for on click back btn in doc history drawer ========================//
    const onClickBackBtnDocHistory = () => {
        setShowDocHistoryDrawer(false)
        setDocHistoryDrawerItems([])
    }

    //========================= Function when doc history item is clicked =============================//
    const onClickDocHistoryItem = (objItem) => {
        // console.log("objItem", objItem)
        setIsComingFromHistory(true)
        getDocHistoryConversationByConversationId(objItem.conversationId)
        // console.log("Item clicked", objItem)
        localStorage.setItem("conversation_id", JSON.stringify(objItem.conversationId))
        localStorage.setItem("currentDocHistoryItemId", JSON.stringify(objItem.documentHistoryItemId))
        localStorage.setItem("HighlightType", objItem.highlightBtnTypeId)
        setShowDocHistoryDrawer(false)

        const lastIndex = objItem?.aiResponseArray?.length - 1

        switch (objItem.highlightBtnTypeId) {
            case ButtonType.Explain:
                setExpDrawerData({ isDrawerVisible: true, selectedText: objItem.selectedText })
                setObjExplaination({ explaination: objItem.aiResponseArray[lastIndex] })
                setContentArray(objItem.aiResponseArray)
                break

            case ButtonType.Simplyfy:
                setSimplifyDrawerData({ isDrawerVisible: true, selectedText: objItem.selectedText })
                setObjExplaination({ explaination: objItem.aiResponseArray[lastIndex] })
                setContentArray(objItem.aiResponseArray)
                break

            case ButtonType.Summarize:
                setSummarizeDrawerData({ isDrawerVisible: true, selectedText: objItem.selectedText })
                setObjExplaination({ explaination: objItem.aiResponseArray[lastIndex] })
                setContentArray(objItem.aiResponseArray)
                break

            case ButtonType.Diagram:
                setDiagramDrawerData({ isDrawerVisible: true, selectedText: objItem.selectedText })
                setDiagramDrawerDataFromApi({ txtResponse: objItem.txtResponse, mermaidJsCode: objItem.aiResponse })
                break

            default:
                return
        }
        setShowAction(true)
        setShowSuggestionBlock(true)
        // setDocHistoryDrawerItems([])
    }

    const updateObjExplanation = (currentContent) => {
        // console.log(currentContent)
        setObjExplaination({ explaination: currentContent })
    }

    //==================== Function to get doc history item conversation ========================//
    const getDocHistoryConversationByConversationId = (conversationId) => {
        // console.log("ItemConversationId", conversationId)
        if (conversationId) {
            authChat.get(`/docHistory/GetItemConversation?conversationId=${conversationId}&conversationTypeId=${ConversationTypId.DrawerConversation}`)
                .then((res) => {
                    // debugger
                    // setObjExplaination(
                    //     { explaination: res.data.AIContent.content }
                    // )
                    // console.log("ConversationResponse", res)
                    res.data.conversation.map((objMessage => {
                        setArrayExpDrawerConversation((preValues) => [
                            ...preValues,
                            { message: objMessage.content, role: objMessage.role }
                        ])
                    }))
                    //setArrayExpDrawerConversation(res.data.conversation)
                })
        }
    }

    //############################### Text Selection Magic Pop Functions ###############################//

    const onClickGotItInTextMagicPopup = () => {
        setShowTextMagicPopup(false)
    }

    //#################################### Doc History Drawer Functions end ###################################//

    //############################### Drawer Functions to Call conditionally #############################//
    const handlePropToSetDrawerState = (drawerStateValues, btnType) => {
        switch (btnType) {
            case ButtonType.Explain:
                setExplainDrawerState(drawerStateValues)
                break

            case ButtonType.Simplyfy:
                setSimplifyDrawerData(drawerStateValues)
                break

            case ButtonType.Summarize:
                setSummarizeDrawerData(drawerStateValues)
                break

            case ButtonType.Diagram:
                setDiagramDrawerData(drawerStateValues)
                break

            default:
                return
        }
    }

    const onDeleteDocHistoryItem = (docHistoryItemId) => {
        setDocHistoryItemId(docHistoryItemId)
        setShowConfirmDelete(true)
    }

    const onNoConfirmDeleteDocHistoryItem = () => {
        setShowConfirmDelete(false)
        setDocHistoryItemId(0)
    }

    const onYesConfirmDeleteDocHistoryItem = () => {
        setDocHistoryDrawerItems([])
        setShowConfirmDelete(false)
        if (docHistoryItemId) {
            authChat.get(`/docHistory/Delete?documentHistoryItemId=${docHistoryItemId}`)
                .then(() => {
                    getDocHistory()
                })
                .catch((err) => {
                    console.log("Delete doc history item error: ", err.message)
                })
        }
    }

    const docLoadedSuccessfully = (isDocLoadedSuccessfully) => {
        if (isDocLoadedSuccessfully) {
            const isDocUploaded = localStorage.getItem("isDocUploaded")
            if (isDocUploaded) {
                setShowTextMagicPopup(true)
                localStorage.removeItem("isDocUploaded")
            }
        }
    }

    //################################ Highlights ##########################//
    const updateHighlightsHistory = useCallback(() => {
        getHighlights.subscribe({
            next: (highlightHistoryItems) => {
                setDocHistoryDrawerItems(highlightHistoryItems)
            },
            error: err => {
                console.log("updateHighlightsHistory", err)
            }
        })
    }, [])

    const formatTimeDate = (dateTime) => {
        const timeDate = convertToLocalTime(dateTime) + " | " + convertToLocalDate(dateTime);
        return timeDate;
    }

    const openDiagramDialog = () => {
        setDiagramDialog(true);
    }

    const closeDiagramDialog = () => {
        setDiagramDialog(false);
    }



    //############################################ FUNCTIONS AREA END #############################################//










    // Quiz Page Functions
    //################################### States ########################################//

    //############################### Quiz History Drawer States ##############################//

    //========================== State for keeping quiz history items count to increment by 1 ============================//
    const [quizHistoryItemsCount, setQuizHistoryItemsCount] = useState(0)

    //========================= State to persist quiz id for delete ===============================//
    const [currentQuizId, setCurrentQuizId] = useState(0)

    //============================== State to show confirm delete pop up ============================//
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    //================================ State to hold quiz history items =============================//
    const [quizHistoryItems, setQuizHistoryItems] = useState([])

    //=============================== State to show quiz history drawer ============================//
    const [showQuizHistoryDrawer, setShowQuizHistoryDrawer] = useState(false)

    //############################### Quiz Submitted States ############################//

    //=========================== State to keep score ============================//
    const [quizScore, setQuizScore] = useState(0)

    //=========================== State to show explaination on toggle ======================//
    const [isExplainationToggled, setIsExplainationToggled] = useState(false)

    //========================= State to show quiz submitted drawer ========================//
    const [showQuizSubmittedDrawer, setShowQuizSubmittedDrawer] = useState(false)

    //###############################################################################//

    const [callTimeout, setCallTimeout] = useState(false)

    const [showLoader, setShowLoader] = useState(false)

    //============================ State for quiz setting edit ==========================//
    const [isEdit, setIsEdit] = useState(false)

    //=========================== State for question in guiding popup =========================//
    const [QuestionForHint, setQuestioonForHint] = useState("")

    //========================== State to store hint ==============================//
    const [hints, setHints] = useState([])

    //========================== State to store quiz completed time ============================//
    const [quizCompletedTimeInSecs, setQuizCompletedTimeInSeconds] = useState(0)

    //======================== State to store end time of quiz ===========================//
    const [quizEndDateTime, setQuizEndDateTime] = useState("")

    //========================== State to store start time of quiz =============================//
    const [quizStartDateTime, setQuizStartDateTime] = useState("")

    //============================ State to record user response ===========================//
    const [userAnswers, setUserAnswers] = useState({})

    //============================ State to view guiding light =============================//
    const [showGuidingPopup, setShowGuidingPopup] = useState(false)

    //============================= State for total question count ==============================//
    const [totalQuestionsCount, setTotalQuestionsCount] = useState(0)

    //============================= State to know if form is submitted ==========================//
    const [isQuizSubmitted, setIsQuizSubmitted] = useState(false)

    //============================== State for current quiz ===========================//
    const [currentQuiz, setCurrentQuiz] = useState({})

    //============================== State for timer ===============================//
    const [seconds, setSeconds] = useState(0); // timer minutes in seconds

    //============================= State for timer active =========================//
    const [isTimerActive, setisTimerActive] = useState(false)

    //============================== State tO show quiz drwer ============================//
    const [showQuizDrawer, setShowQuizDrawer] = useState(false)

    //============================= State for quiz settings question types =============================//
    const [quizSettingsQuestionTypesData, setQuizSettingsQuestionTypesData] = useState([
        { name: "Multiple Choice", id: QuizSettingsQuestionTypeId.MultipleChoice },
        { name: "True / False", id: QuizSettingsQuestionTypeId.TrueFalse },
        { name: "Select All", id: QuizSettingsQuestionTypeId.SelectAll }
    ])

    //============================== State to restore quiz settings upon cancel ===============================//
    const [prevQuizSettings, setPrevQuizSettings] = useState({})

    //============================= State for quiz setting ============================//
    const [showQuizSetting, setShowQuizSetting] = useState(false);
    const [quizSetting, setQuizSetting] = useState({
        questionCount: 5,
        timer: 5,
        questionTypes: [QuizSettingsQuestionTypeId.MultipleChoice]
    })

    const [quizGenerationFailed, setQuizGenerationFailed] = useState(false)

    //############################### Use Effects ###############################//
    useEffect(() => {
        console.log("Settings", quizSetting)
    }, [quizSetting])

    useEffect(() => {
        let interval

        if (isTimerActive && seconds > 0) {
            setCallTimeout(true)
            interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1)
            }, 1000)
            console.log("startfatetime", quizStartDateTime)
        }
        else if (callTimeout && seconds === 0) {
            // setIsQuizSubmitted(true)
            setCallTimeout(false)
            onClickQuizDrawerSubmit()
        }

        return () => {
            clearInterval(interval);
        };

    }, [isTimerActive, seconds])

    // useEffect(() => {
    //     let timerId

    //     // Start timer if it's active
    //     if (isTimerActive) {
    //         timerId = setTimeout(() => {

    //             //Timer has ended call the function
    //             onClickQuizDrawerSubmit()
    //         }, quizSetting.timer * 61000);
    //     }

    //     // Clean up the timer when the component unmounts or when the timer is no longer active
    //     return () => {
    //         clearTimeout(timerId);
    //     };
    // }, [isTimerActive])

    useEffect(() => {
        console.log("QuizState", currentQuiz)
        if (currentQuiz.Questions) {
            setTotalQuestionsCount(currentQuiz.Questions.length)
        }
    }, [currentQuiz])

    useEffect(() => {
        console.log("AnswerState", userAnswers)
    }, [userAnswers])

    //################################# Functions ####################################//

    // Function to render when doc loading is completed.
    // Note: Do not remove this function as it is common for quiz and learning. If removed quiz page will start giving error that the prop is not a function.
    // const docLoadedSuccessfully = () => {

    // }

    //=================================== Quiz Settings Functions ================================//
    const handleQuestionIncrement = () => {
        if (quizSetting.questionCount < 8) {
            setQuizSetting({
                ...quizSetting,
                questionCount: quizSetting.questionCount + 1
            })
        }
    };

    const handleQuestionDecrement = () => {
        if (quizSetting.questionCount > 1) {
            setQuizSetting({
                ...quizSetting,
                questionCount: quizSetting.questionCount - 1
            })
        }
    };

    const handleTimerIncrement = () => {
        if (quizSetting.timer < 60) {
            setQuizSetting({
                ...quizSetting,
                timer: quizSetting.timer + 1
            })
        }
    };

    const handleTimerDecrement = () => {
        if (quizSetting.timer > 1) {
            setQuizSetting({
                ...quizSetting,
                timer: quizSetting.timer - 1
            })
        }
    };

    const onClickQuizMe = () => {
        setShowQuizSetting(true)
        setPrevQuizSettings(quizSetting)
    }

    const onClickQuizSettingCancel = () => {
        setShowQuizSetting(false)
        setQuizSetting(prevQuizSettings)
        setIsEdit(false)
    }

    const onClickQuizSettingDone = () => {
        if (quizSetting.questionCount <= 8 && quizSetting.timer <= 60 && quizSetting.questionTypes.length > 0) {
            setSeconds(0)
            setShowLoader(true)
            setPrevQuizSettings(quizSetting)
            setShowQuizSetting(false)
            setShowQuizDrawer(true)
            addUpdateQuizSettings(quizSetting.questionCount, quizSetting.timer, quizSetting.questionTypes)
            if (isEdit) {
                if (prevQuizSettings.questionCount !== quizSetting.questionCount) {
                    setCurrentQuiz({})
                    setSeconds(0)
                    GetQuiz()
                } else {
                    setSeconds(quizSetting.timer * 60)
                    setQuizStartDateTime((new Date()).toISOString())
                }
            } else {
                GetQuiz(quizSetting.questionCount, quizSetting.questionTypes)
            }
            setIsEdit(false)
        }
    }

    const handleQuestionCountInputChange = (event) => {
        // Parse the input value to an integer and update the count state
        const newValue = parseInt(event.target.value, 10);

        // Check if newValue is a valid number
        if (!isNaN(newValue) && newValue <= 8) {
            setQuizSetting({
                ...quizSetting,
                questionCount: newValue
            })
        }
    };

    const handleTimerInputChange = (event) => {
        // Parse the input value to an integer and update the count state
        const newValue = parseInt(event.target.value, 10);

        // Check if newValue is a valid number
        if (!isNaN(newValue) && newValue <= 60) {
            setQuizSetting({
                ...quizSetting,
                timer: newValue
            })
        }
    }

    const handleCheckboxChange = (e) => {
        let isSelected = e.target.checked
        let value = parseInt(e.target.value)

        // If a checkbox is checked
        if (isSelected) {
            // If select all checkbox is checked then check all checkboxes
            if (value === QuizSettingsQuestionTypeId.SelectAll) {
                setQuizSetting({
                    ...quizSetting,
                    questionTypes: quizSettingsQuestionTypesData.map(questionType => questionType.id)
                })
            }

            // If checkbox other than select all checkbox is checked
            else {
                // if all checkboxes are checked other than select all then also check select all 
                if (quizSetting.questionTypes.length === quizSettingsQuestionTypesData.length - 2) {
                    setQuizSetting({
                        ...quizSetting,
                        questionTypes: [...quizSetting.questionTypes, value, QuizSettingsQuestionTypeId.SelectAll]
                    })
                }

                // else only check the checkbox which is checked
                else {
                    setQuizSetting({
                        ...quizSetting,
                        questionTypes: [...quizSetting.questionTypes, value]
                    })
                }
            }

            // If a checkbox is unchecked
        } else {

            // if select all is unchecked then uncheck all checkboxes
            if (value === QuizSettingsQuestionTypeId.SelectAll) {
                setQuizSetting({
                    ...quizSetting,
                    questionTypes: []
                })
            }

            // else only uncheck the checkbox which is unchecked and also uncheck the select all
            else {
                setQuizSetting(prevSetting => ({
                    ...prevSetting,
                    questionTypes: prevSetting.questionTypes.filter((questionType) => questionType !== value && questionType !== QuizSettingsQuestionTypeId.SelectAll)
                })
                )
            }

        }
    }

    //########################################### Quiz Drawer Functions #############################################//

    const onClickQuizDrawerCancelBtn = () => {
        setCallTimeout(false)
        setShowLoader(false)
        setQuizGenerationFailed(false)
        setShowQuizDrawer(false)
        setQuizScore(0)
        setisTimerActive(false)
        setCurrentQuiz({})
        setSeconds(0)
        setTotalQuestionsCount(0)
        setUserAnswers({})
        localStorage.removeItem("currentQuizId")
        setQuizStartDateTime("")
    }

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = Math.round(timeInSeconds % 60);
        return `${String(minutes).padStart(2, '0')} mins ${String(remainingSeconds).padStart(2, '0')} sec(s)`;
    };

    const onClickQuizDrawerSubmit = () => {
        setCallTimeout(false)
        const endDatTimeStr = new Date().toISOString()
        const startDateTimeStr = quizStartDateTime
        setQuizEndDateTime(endDatTimeStr)
        setisTimerActive(false)
        setIsQuizSubmitted(true)
        setSeconds(0)
        let score = calculateScore(userAnswers)
        calculateCompletedTimeForQuiz(startDateTimeStr, endDatTimeStr)
        InsertUserResponse(score, quizStartDateTime, endDatTimeStr, userAnswers, totalQuestionsCount, quizSetting.timer)
        setShowQuizSubmittedDrawer(true)
        setShowQuizDrawer(false)
    }

    const calculateCompletedTimeForQuiz = (startDateTimeStr, endDateTimeStr) => {
        const startDateTime = new Date(startDateTimeStr)
        const endDateTime = new Date(endDateTimeStr)

        const completionTimeInSecs = Math.abs(endDateTime - startDateTime) / 1000
        setQuizCompletedTimeInSeconds(completionTimeInSecs)
        setQuizEndDateTime("")
        setQuizStartDateTime("")
    }

    const calculateScore = (dictUserAnswers) => {
        let score = 0
        console.log("currentQuiz.questions", currentQuiz.Questions)
        currentQuiz.Questions.map((question) => {
            let userSelectedOption = question?.Options.find(item => item.OptionId === dictUserAnswers[question.QuestionId])
            if (userSelectedOption) {
                if (userSelectedOption.isCorrect === true) {
                    score++
                }
            }
            return (null)
        })
        setQuizScore(score)
        return score
    }
    useEffect(() => {
        console.log("Score", quizScore)
    }, [quizScore])

    const onOptionChange = (questionId, optionId) => {
        setUserAnswers((prevState) => ({
            ...prevState,
            [questionId]: optionId
        }))
    }

    const onClickGuidingLight = (hints, question) => {
        setQuestioonForHint(question)
        setShowGuidingPopup(true)
        setHints(hints)
    }

    const onClickGuidingLightClose = () => {
        setShowGuidingPopup(false)
        setHints([])
    }

    const onClickEditQuizSetting = () => {
        setIsEdit(true)
        setShowQuizSetting(true)
    }

    //######################################## Quiz Submitted Drawer Function #################################//
    const onClickQuizSubmittedDrawerDone = () => {
        setShowQuizSubmittedDrawer(false)
        setUserAnswers({})
        setCurrentQuiz({})
        setTotalQuestionsCount(0)
        setQuizScore(0)
        setQuizEndDateTime("")
        setQuizStartDateTime("")
        localStorage.removeItem("currentQuizId")
        // setQuizHistoryItems([])
        setQuizCompletedTimeInSeconds(0)
    }

    const handleExplainationToggle = () => {
        setIsExplainationToggled(!isExplainationToggled)
    }

    const onClickReTake = () => {
        setQuizScore(0)
        setQuizCompletedTimeInSeconds(0)
        setQuizEndDateTime("")
        setUserAnswers({})
        setShowQuizSubmittedDrawer(false)
        setShowQuizDrawer(true)
        setisTimerActive(true)
        setQuizStartDateTime((new Date()).toISOString())
        setSeconds((quizSetting.timer * 60) - 1)
        setCurrentQuiz(randomizeQuiz(currentQuiz))
        // setTimeout(() => {
        //     console.log("Quiz ended")
        //     onClickQuizDrawerSubmit()
        // }, quizSetting.timer * 60000);
    }

    //################################### Quiz History Drawer Functions #################################//
    const onClickQuizHistory = () => {
        setShowQuizHistoryDrawer(true)
        getQuizHistory()
    }

    const onClickBackBtnQuizHistory = () => {
        setShowQuizHistoryDrawer(false)
        setQuizHistoryItems([])
        setQuizHistoryItemsCount(0)
    }

    const onNoConfirmDeleteQuizHistoryItem = () => {
        setShowConfirmDelete(false)
        setCurrentQuizId(0)
    }

    const onYesConfirmDeleteQuizHistoryItem = () => {
        setShowConfirmDelete(false)
        // setQuizHistoryItems([])
        deleteQuizByQuizId(currentQuizId)
    }

    const onClickQuizHistoryItem = (quizhistoryItem) => {
        localStorage.setItem("currentQuizId", quizhistoryItem.QuizId)

        getQuizHistoryItemByQuizId(quizhistoryItem.QuizId)

        setShowQuizSubmittedDrawer(true)
        calculateCompletedTimeForQuiz(quizhistoryItem.StartTime, quizhistoryItem.EndTime)
        setQuizScore(quizhistoryItem.ObtainedScore)
        setShowQuizHistoryDrawer(false)
        setQuizSetting({
            ...quizSetting,
            timer: quizhistoryItem.Timer
        })
    }

    const onDeleteQuizHistoryItem = (quizId) => {
        setShowConfirmDelete(true)
        setCurrentQuizId(quizId)
    }

    const updateQuizHistory = () => {
        getQuizHistoryItems.subscribe({
            next: (quizHistoryItems) => {
                setQuizHistoryItems(quizHistoryItems)
                setQuizHistoryItemsCount(quizHistoryItems.length + 1)
            },
            error: err => {
                console.log("updateQuizHistory error: ", err)
            }
        })
    }


    //############################################### API CALLS ######################################################//
    const addUpdateQuizSettings = (questionCount, timer, questionTypes) => {
        const quizSettingsModel = {
            QuestionCount: questionCount,
            Timer: timer,
            QuestionTypes: questionTypes.includes(QuizSettingsQuestionTypeId.SelectAll) ? [QuizSettingsQuestionTypeId.SelectAll] : questionTypes
        }

        authChat.post("/quizSettings/create", {payload: encodeToBase64(quizSettingsModel)})
            .then((res) => {
            })
            .catch((err) => {
                console.log("addQuizSettings error: ", err)
            })
    }

    const GetQuiz = async (questionCount, questionTypes) => {
        const docData = await getDocData();
        const createGetQuizModel = {
            documentId: docData.document_id,
            questionCount: questionCount,
            questionTypes: questionTypes
        }
        if (docData) {
            authChat.post(`/quiz/Create_GetQuiz`, {payload: encodeToBase64(createGetQuizModel)})
                .then((res) => {
                    const quiz = JSON.parse(res.data.quiz)
                    setCurrentQuiz(quiz)
                    localStorage.setItem("currentQuizId", quiz.QuizId)
                    setisTimerActive(true)
                    setSeconds((quizSetting.timer * 60) - 1)
                    setShowLoader(false)
                    setQuizStartDateTime((new Date()).toISOString())
                })
                .catch((err) => {
                    console.log("addGetQuizId error: ", err)
                    setQuizGenerationFailed(true);
                    setShowLoader(false);
                })
        }

    }

    const InsertUserResponse = async (obtainedScore, startDateTime, endDateTime, userAnswers, totalScore, timer) => {
        const docData = await getDocData();
        const quizId = localStorage.getItem("currentQuizId")
        if (docData && quizId) {
            const userResponseModel = {
                // quizId: localStorage.getItem("currentQuizId")
                QuizId: quizId,
                ObtainedScore: obtainedScore,
                TotalScore: totalScore,
                StartDateTime: startDateTime,
                EndDateTime: endDateTime,
                Timer: timer,
                UserResponse: userAnswers
            }
            authChat.post("/quiz/storeUserAnswers", {payload: encodeToBase64(userResponseModel)})
                .then((res) => {
                    updateQuizHistory()
                })
                .catch((err) => {
                    console.log("InsertUserResponse error: ", err)
                })
        }
    }

    //**************Replaced by Observables*************/
    const getQuizHistory = async () => {
       const docData = await getDocData()
        if (docData) {
            authChat.get(`/quizHistory/GetQuizHistoryItems?documentId=${docData.document_id}`)
                .then((res) => {
                    // console.log("res", res)
                    setQuizHistoryItems(res.data.quizHistoryItems)
                    setQuizHistoryItemsCount(res.data.quizHistoryItems.length + 1)
                })
        }
    }

    const deleteQuizByQuizId = (quizId) => {
        console.log("QuizId", quizId)
        if (quizId) {
            authChat.get(`/quizHistory/DeleteQuiz?quizId=${quizId}`)
                .then((res) => {
                    updateQuizHistory()
                })
                .catch((err) => {
                    console.log("deleteQuizByQuizId error: ", err)
                })
        }
    }

    const getQuizHistoryItemByQuizId = (quizId) => {
        if (quizId) {
            authChat.get(`/quiz/GetQuizHistoryItemByQuizId?quizId=${quizId}`)
                .then((res) => {
                    console.log("Quiz", res)
                    const quiz = JSON.parse(res.data.quiz)
                    setCurrentQuiz(quiz)
                    setUserAnswers(res.data.userResponse)
                })
                .catch((err) => {
                    console.log("getQuizByQuizId error: ", err)
                })
        }
    }

    return (
        <>
        {/* //Learning Page JSX */}
        {pageState === 'learning' ?
        <BaseLayout
            tabLabel={'Highlights'}
            tabContent={
                <>
                    <Highlights
                        updateHighlightsHistory={updateHighlightsHistory}
                        items={
                            docHistoryDrawerItems.map((objItem) => ({
                                chatbotIcon: true,
                                title: 'Selected Text',
                                selectedText: extractWordsFromStart(objItem.selectedText, 5),
                                contentType: ButtonDictionary[objItem.highlightBtnTypeId],
                                contentBrief: extractWordsFromStart((objItem.highlightBtnTypeId === 4) ? objItem.txtResponse : objItem.aiResponseArray[objItem.aiResponseArray?.length - 1], 25),
                                onClick: () => { onClickDocHistoryItem(objItem) },
                                createdOn: formatTimeDate(objItem.createdOn)
                            }))
                            // [
                            //     {
                            //         chatbotIcon: true,
                            //         title: 'Selected Text',
                            //         selectedText: 'Oxidizing ketone bodies ...',
                            //         contentType: 'Explanation',
                            //         contentBrief: 'Ketone bodies are molecules that the body produces when it does not have enough glucose to use as fuel. Normally, our bodies use glucose for energy, but ...',
                            //         onClick: () => { }
                            //     },
                            //     {
                            //         chatbotIcon: true,
                            //         title: 'Selected Text',
                            //         selectedText: 'Oxidizing ketone bodies ...',
                            //         contentType: 'Explanation',
                            //         contentBrief: 'Ketone bodies are molecules that the body produces when it does not have enough glucose to use as fuel. Normally, our bodies use glucose for energy, but ...',
                            //         onClick: () => { }
                            //     }
                            // ]
                        }
                    />
                </>
            }
        >
            <PageHeader
                actionArea={
                    <>
                        <Button primary text={'Summary'} type='submit' onClick={onClickSummaryBtn} />
                        {/* <Button primary text={'Bookmarks'} type='submit' onClick={onClickDocHistory} /> */}
                    </>
                }
            />
            {/* <PdfContainer
                refPdfUploaded={refPdfUploaded}
                propToSetExplainDrawerData={handlePropToSetDrawerState}
                propForExplaination={setExplainationText}
                propExpDrawerChatResponse={handleExpDrawerChatResponse}
                propForDiagramData={setDiagramDrawerDataFromProp}
                showHighlightActions={true}
                propForDocLoaded={docLoadedSuccessfully}
                updateHighlightsHistory={updateHighlightsHistory}
            /> */}

            {/* <MemoizedPdfViewer/> */}
            

            {/* Summary Drawer */}
            <CustomDrawer
                anchor={'right'}
                size={'md'}
                open={showSummaryDrawer}
                onBackBtn={onBackBtnSummaryDrawer}
                enableChat={false}
                backdropLight
            >
                <ExplainContent
                    title={'Document'}
                    selctedText={summaryData.docTitle}
                    contentType={'Summary'}
                    contentArray={[]}
                    updateObjExplanation={() => {}}
                    contentBrief={
                        <>
                            {!summaryData.summary && (<Loader />)}
                            <p className="text-body1 text-grey-light-a fw-normal">{summaryData.summary}</p>
                        </>
                    }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }

                    actionArea
                    // style
                    // length
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(summaryData.summary) }}
                // regenrate={showAction}
                />
            </CustomDrawer>

            {/* Explain Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={explainDrawerData.isDrawerVisible}
                onBackBtn={onBackBtnExplainDrawer}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div className="user-chat">


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    if (objMessage.message.includes("\n")) {
                                        const pointsArray = objMessage.message.split('\n').filter(point => point.trim() !== '')

                                        // Trim each substring until an alphabet or a number is encountered
                                        // const trimmedArray = pointsArray.map(point => point.replace(/^[^\w\d]+/, ''));
                                        return (
                                            <FormattedAIResponse pointsArray={pointsArray} chatBotIcon={true} />
                                        )
                                    } else {
                                        return (<AIResponse aiResponse={objMessage.message}  chatBotIcon={true} />)
                                    }
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div className="chat-bubble-wrap">
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right chat-suggestion-link">
                                        <div className="chat-bubble cp" onClick={() => handleOnClickRelatedQuestion(question)}>
                                            <img src={ArrowIcon} alt="" />
                                            <small  className="text-body2 fw-sbold">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-sbold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-sbold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }

                    {/* <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">question </small>
                        </div>
                    </div>
                    <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">What is the role of insulin in glucose metabolism?</small>
                        </div>
                    </div>
                    <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">What are the types of ketone bodies?</small>
                        </div>
                    </div> */}
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={explainDrawerData.selectedText}
                    contentType={'Explanation'}
                    updateObjExplanation={updateObjExplanation}
                    contentArray={contentArray}
                    showContentLoader={showContentLoader}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    pager

                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Simplify Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={simplifyDrawerData.isDrawerVisible}
                onBackBtn={onClickSimplifyDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={simplifyDrawerData.selectedText}
                    contentType={'Simplify'}
                    contentArray={contentArray}
                    updateObjExplanation={() => {}}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Summarize Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={summarizeDrawerData.isDrawerVisible}
                onBackBtn={onClickSummarizeDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={summarizeDrawerData.selectedText}
                    contentType={'Summarize'}
                    contentArray={contentArray}
                    updateObjExplanation={() => {}}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Diagram Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={diagramDrawerData.isDrawerVisible}
                onBackBtn={onClickDiagramDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        <div ref={refDrawerContentScrollable} />
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={diagramDrawerData.selectedText}
                    contentType={'Diagram'}
                    contentArray={[]}
                    updateObjExplanation={() => {}}
                    contentBrief={
                        <>
                            {!diagramDrawerDataFromAPI.mermaidJsCode && (<Loader />)}
                            {
                                diagramDrawerDataFromAPI.mermaidJsCode && <div>
                                    <p className="text-body1 text-grey-light-a mt-6 fw-normal">{diagramDrawerDataFromAPI.txtResponse}</p>
                                    <Mermaid chart={diagramDrawerDataFromAPI.mermaidJsCode} />
                                </div>
                            }
                        </>
                    }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                // style
                // length
                // enlarge
                // download
                // copy
                // regenrate
                    enlarge={true}
                    mermaidJsCode= {diagramDrawerDataFromAPI.mermaidJsCode}
                    openDiagramDialog={openDiagramDialog}
                />
            </CustomDrawer>

            {/* History Drawer */}
            {/* <CustomDrawer
                anchor={'right'}
                size={'sm'}
                open={showDocHistoryDrawer}
                onBackBtn={onClickBackBtnDocHistory}
            >
                <HistoryContent
                    title={'Bookmark history'}
                    historyTitle={'Action'}
                    historyDateTitle={'Date'}
                    listItemOptions={
                        docHistoryDrawerItems.map((objItem) => ({
                            title: ButtonDictionary[objItem.highlightBtnTypeId],
                            description: extractWordsFromStart(objItem.selectedText, 5),
                            date: convertToLocalDate(objItem.createdOn),
                            onClick: () => { onClickDocHistoryItem(objItem) },
                            onDelete: () => { onDeleteDocHistoryItem(objItem.documentHistoryItemId) }
                        }))
                        // [
                        //     {
                        //         title: 'Explain',
                        //         description: 'Oxidizing ketone bodies',
                        //         date: '20 Oct 23',
                        //         onClick: () => { },
                        //         onDelete: () => { }
                        //     },
                        //     {
                        //         title: 'Summarize',
                        //         description: 'Oxidizing ketone bodies',
                        //         date: '20 Oct 23',
                        //         onClick: () => { },
                        //         onDelete: () => { }
                        //     },
                        //     {
                        //         title: 'Simplify',
                        //         description: 'How does our body How does our body ...',
                        //         date: '20 Oct 23',
                        //         onClick: () => { },
                        //         onDelete: () => { }
                        //     }
                        // ]
                    }
                />
            </CustomDrawer>

            <CustomDialog
                open={showConfirmDelete}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={DeleteIcon}
                dialogTitle={'Delete Item'}
                dialogAction={
                    <>
                        <Button text='No' outlineBtn type={'submit'} onClick={onNoConfirmDeleteDocHistoryItem} />
                        <Button text='Yes' primary type={'submit'} onClick={onYesConfirmDeleteDocHistoryItem} />
                    </>
                }
            >
                <p className="text-body1 text-center m-0">Are you sure you want to delete this item?</p>
            </CustomDialog> */}
            {/* History Drawer end */}

            {/* Logout Popup
            <CustomDialog
                open={showConfirmLogoutPopup}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={LogoutIcon}
                dialogTitle={'Logout'}
                dialogAction={
                    <>
                        <Button onClick={onClickConfirmLogoutNo} text='No' outlineBtn type={'submit'} />
                        <Button onClick={onClickConfirmLogoutYes} text='Yes' primary type={'submit'} />
                    </>
                }
            >
                <p className="text-body1 text-center m-0">Are you sure you want to logout?</p>
            </CustomDialog> */}

            {/* Text Magic Dialog */}
            <div className="dialog-text-magic">
                <CustomDialog
                    open={showTextMagicPopup}
                    onClose={() => { }}
                    size={'sm'}
                    dialogIcon={StarsIcon}
                    dialogAction={
                        <>
                            <div className="btn-right">
                                <Button onClick={onClickGotItInTextMagicPopup} text='Got it' primary type={'submit'} />
                            </div>
                        </>
                    }
                >
                    <div className="text-left">
                        <h5 className="text-h5 fw-sbold mt-0 mb-5">Let's Get Started</h5>
                        {/* <p className="text-body1 m-0">Explain, Simplify, Summarize and Visualize</p> */}
                        <p className="text-body2 mt-16 text-muted lh-22 mb-0">Please highlight any text you wish to understand more. Once selected, I can provide detailed explanations, summarize concepts, and create diagrams to help connect the dots.</p>
                    </div>
                </CustomDialog>
            </div>
            {/* <FeedbackConfirmationDialog /> */}
            {/* <FeedbackDialog /> */}
            {diagramDialog && <DiagramDialog open={diagramDialog} closeDialog={closeDiagramDialog} chartCode={diagramDrawerDataFromAPI && diagramDrawerDataFromAPI.mermaidJsCode}/>}
        </BaseLayout>








        : <BaseLayout
            tabLabel={'Quiz History'}
            tabContent={
                <HistoryContent
                    updateQuizHistory={updateQuizHistory}
                    hasLogo
                    listItemOptions={
                        quizHistoryItems.map((objItem, index) => ({
                            title: `Quiz # ${quizHistoryItemsCount - 1 - index}`,
                            description: `Score : ${objItem.ObtainedScore} / ${objItem.TotalScore}`,
                            date: convertToLocalDate(objItem.createdOn),
                            onClick: () => { onClickQuizHistoryItem(objItem) },
                            onDelete: () => { onDeleteQuizHistoryItem(objItem.QuizId) }
                        }))

                        // [
                        //     {
                        //         title: 'Quiz # 07',
                        //         description: 'Score : 20 / 30',
                        //         date: '20 Oct 23',
                        //         onDelete: () => { },
                        //         onClick: () => { }
                        //     },
                        //     {
                        //         title: 'Quiz # 06',
                        //         description: 'Score : 10 / 30',
                        //         date: '15 Oct 23',
                        //         onDelete: () => { },
                        //         onClick: () => { }
                        //     }
                        // ]
                    }
                />
            }
        >
            <PageHeader
                actionArea={
                    <>
                        <Button primary text={'Quiz me'} type='submit' onClick={onClickQuizMe} />
                        {/* <Button primary text={'Quiz History'} type='submit' onClick={onClickQuizHistory} /> */}
                    </>
                }
            />
            {/* Summary Drawer */}
            <CustomDrawer
                anchor={'right'}
                size={'md'}
                open={showSummaryDrawer}
                onBackBtn={onBackBtnSummaryDrawer}
                enableChat={false}
                backdropLight
            >
                <ExplainContent
                    title={'Document'}
                    selctedText={summaryData.docTitle}
                    contentType={'Summary'}
                    contentArray={[]}
                    updateObjExplanation={() => {}}
                    contentBrief={
                        <>
                            {!summaryData.summary && (<Loader />)}
                            <p className="text-body1 text-grey-light-a fw-normal">{summaryData.summary}</p>
                        </>
                    }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }

                    actionArea
                    // style
                    // length
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(summaryData.summary) }}
                // regenrate={showAction}
                />
            </CustomDrawer>

            {/* Explain Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={explainDrawerData.isDrawerVisible}
                onBackBtn={onBackBtnExplainDrawer}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div className="user-chat">


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    if (objMessage.message.includes("\n")) {
                                        const pointsArray = objMessage.message.split('\n').filter(point => point.trim() !== '')

                                        // Trim each substring until an alphabet or a number is encountered
                                        // const trimmedArray = pointsArray.map(point => point.replace(/^[^\w\d]+/, ''));
                                        return (
                                            <FormattedAIResponse pointsArray={pointsArray} chatBotIcon={true} />
                                        )
                                    } else {
                                        return (<AIResponse aiResponse={objMessage.message}  chatBotIcon={true} />)
                                    }
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div className="chat-bubble-wrap">
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right chat-suggestion-link">
                                        <div className="chat-bubble cp" onClick={() => handleOnClickRelatedQuestion(question)}>
                                            <img src={ArrowIcon} alt="" />
                                            <small  className="text-body2 fw-sbold">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-sbold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-sbold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }

                    {/* <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">question </small>
                        </div>
                    </div>
                    <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">What is the role of insulin in glucose metabolism?</small>
                        </div>
                    </div>
                    <div className="chat-bubble-wrap msg-received">
                        <div className="chat-bubble">
                            <small className="text-body2 text-muted fw-bold">What are the types of ketone bodies?</small>
                        </div>
                    </div> */}
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={explainDrawerData.selectedText}
                    contentType={'Explanation'}
                    updateObjExplanation={updateObjExplanation}
                    contentArray={contentArray}
                    showContentLoader={showContentLoader}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    pager

                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Simplify Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={simplifyDrawerData.isDrawerVisible}
                onBackBtn={onClickSimplifyDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={simplifyDrawerData.selectedText}
                    contentType={'Simplify'}
                    contentArray={contentArray}
                    updateObjExplanation={() => {}}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Summarize Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={summarizeDrawerData.isDrawerVisible}
                onBackBtn={onClickSummarizeDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        {/* <div ref={refDrawerContentScrollable} /> */}
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} ref={refDrawerContentScrollable} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={summarizeDrawerData.selectedText}
                    contentType={'Summarize'}
                    contentArray={contentArray}
                    updateObjExplanation={() => {}}
                    // contentBrief={
                    //     <>
                    //         {!objExplaination.explaination && (<Loader />)}
                    //         {formatContent(objExplaination.explaination)}
                    //     </>
                    // }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                    // style
                    // length
                    // enlarge
                    // download
                    copy={showAction}
                    onClickCopy={() => { onClickCopy(objExplaination.explaination) }}
                    regenrate={showAction}
                    getOptionIdFromChild={getOptionIdFromChild}
                />
            </CustomDrawer>

            {/* Diagram Drawer */}
            <CustomDrawer
                //refDrawerContentScrollable={refDrawerContentScrollable}
                anchor={'right'}
                size={'md'}
                open={diagramDrawerData.isDrawerVisible}
                onBackBtn={onClickDiagramDrawerBackBtn}
                enableChat
                backdropLight
                onClickSendBtn={onClickSendBtn}
                disableSendBtn={disableSendBtn}
                chatArea={<>
                    {/* Div to show related questions of Explaination */}
                    <div>


                        {
                            arrayExpDrawerConversation.map((objMessage, index) => {

                                // Check if role is user then show user response div else show ai response div since role will be assisstant
                                //================ Div to show user response ================//
                                if (objMessage.role === 'user') {
                                    return (<UserResponse userResponse={objMessage.message} chatBotIcon={true} />)
                                }

                                //============ Div to show assisstant response ===========//
                                else {
                                    return (<AIResponse aiResponse={objMessage.message} chatBotIcon={true} />)
                                }
                            })
                        }
                        {showAIResponseLoader && (<Loader />)}
                        <div ref={refDrawerContentScrollable} />
                    </div>

                    {objExplaination.questions &&
                        (
                            <div>
                                <div className="info-area">
                                    <img src={QuestionIcon} alt="" />
                                    <h4 className="text-h4 m-0">Here are some related questions <strong className="fw-bold text-fw-normal">Mr.EzPz</strong> can help you answer</h4>
                                </div>
                                {objExplaination.questions.map((question, index) => (

                                    <div key={index} className="chat-bubble-wrap msg-received text-right">
                                        <div className="chat-bubble">
                                            <small onClick={() => handleOnClickRelatedQuestion(question)} className="text-body2 text-muted fw-bold cp">{question}</small>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    {
                        (showSuggestionBlock && objExplaination.explaination) &&
                        <div>
                            <div className={`chat-bubble-wrap msg-sent text-right bubble-suggestion }`}>
                                <div onClick={() => { onClickSuggestions(explainDrawerData.selectedText, objExplaination.explaination) }} className={`need-suggestion chat-bubble cp ${disableSuggestionClick ? "unclickable" : ""}`}>
                                    {
                                        objExplaination.questions
                                            ? <small className="text-body2 fw-bold">Hide Suggestions?</small>
                                            : <small className="text-body2 fw-bold">Need More Suggestions?</small>
                                    }
                                </div>
                            </div>

                        </div>
                    }
                </>}
            >
                <ExplainContent
                    title={'Selected Text'}
                    selctedText={diagramDrawerData.selectedText}
                    contentType={'Diagram'}
                    contentArray={[]}
                    updateObjExplanation={() => {}}
                    contentBrief={
                        <>
                            {!diagramDrawerDataFromAPI.mermaidJsCode && (<Loader />)}
                            {
                                diagramDrawerDataFromAPI.mermaidJsCode && <div>
                                    <p className="text-body1 text-grey-light-a mt-6 fw-normal">{diagramDrawerDataFromAPI.txtResponse}</p>
                                    <Mermaid chart={diagramDrawerDataFromAPI.mermaidJsCode} />
                                </div>
                            }
                        </>
                    }
                    // contentBrief={
                    //     <>
                    //         <img src={SampleDiagram}  alt="diagram" />
                    //     </>
                    // }
                    actionArea
                // style
                // length
                // enlarge
                // download
                // copy
                // regenrate
                    enlarge={true}
                    mermaidJsCode= {diagramDrawerDataFromAPI.mermaidJsCode}
                    openDiagramDialog={openDiagramDialog}
                />
            </CustomDrawer>

            <CustomDialog
                open={showQuizSetting}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={SettingsIcon}
                dialogTitle={'Quiz Setting'}
                dialogAction={
                    <>
                        <Button text='Cancel' outlineBtn type={'submit'} onClick={onClickQuizSettingCancel} />
                        <Button text='Done' primary type={'submit'} onClick={onClickQuizSettingDone} />
                    </>
                }
            >
                <div className="quiz-settings-area">
                    <div className="item item-flex">
                        <div className="label-area">
                            <p className="fw-sbold text-body1 m-0">Question Count</p>
                            <p className="text-small m-0 fw-medium">(Question count limit: 8)</p>
                        </div>
                        <div className="count-item">
                            <Button text='-' outlineBtn iconOnly type={'submit'} onClick={handleQuestionDecrement} />
                            <input type="text" value={quizSetting.questionCount} onChange={handleQuestionCountInputChange} />
                            <Button text='+' outlineBtn iconOnly type={'submit'} onClick={handleQuestionIncrement} />
                        </div>
                    </div>
                    <div className="item item-flex">
                        <div className="label-area">
                            <p className="fw-sbold text-body1 m-0">Timer <span className="text-muted">(mins)</span></p>
                            <p className="text-small m-0 fw-medium">(Timer limit: 60 mins)</p>
                        </div>
                        <div className="count-item">
                            <Button text='-' outlineBtn iconOnly type={'submit'} onClick={handleTimerDecrement} />
                            <input type="text" value={quizSetting.timer} onChange={handleTimerInputChange} />
                            <Button text='+' outlineBtn iconOnly type={'submit'} onClick={handleTimerIncrement} />
                        </div>
                    </div>
                    <div className="item">
                        <p className="fw-sbold text-body1 m-0">Question Type</p>
                        <div className="checkboxes-wrap">
                            {
                                quizSettingsQuestionTypesData.map((questionType) => {
                                    if (questionType.id === QuizSettingsQuestionTypeId.SelectAll) {
                                        return (
                                            <Checkbox
                                                label={questionType.name}
                                                onChange={handleCheckboxChange}
                                                checked={Array.from(quizSetting.questionTypes).includes(questionType.id)}
                                                value={questionType.id}
                                            />
                                            // <label>
                                            //     <input
                                            //         type="checkbox"
                                            //         onChange={handleCheckboxChange}
                                            //         checked={Array.from(quizSetting.questionTypes).includes(questionType.id)}
                                            //         value={questionType.id}
                                            //     />
                                            //     {questionType.name}
                                            // </label>
                                        )
                                    } else {
                                        return (
                                            <Checkbox
                                                label={questionType.name}
                                                onChange={handleCheckboxChange}
                                                checked={Array.from(quizSetting.questionTypes).includes(questionType.id)}
                                                value={questionType.id}
                                            />
                                            // <label>
                                            //     <input
                                            //         type="checkbox"
                                            //         onChange={handleCheckboxChange}
                                            //         checked={Array.from(quizSetting.questionTypes).includes(questionType.id)}
                                            //         value={questionType.id}
                                            //     />
                                            //     {questionType.name}
                                            // </label>
                                        )
                                    }
                                })
                            }
                            {/* <label>
                                <input
                                    type="checkbox"
                                    checked={(quizSetting.questionTypes === false).length > 0}
                                    onChange={() => handleCheckboxChange('selectAll')}
                                />
                                Select All
                            </label>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={quizSetting.questionTypes.multipleChoice}
                                    onChange={() => handleCheckboxChange('multipleChoice')}
                                />
                                Multiple Choice
                            </label>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={quizSetting.questionTypes.trueFalse}
                                    onChange={() => handleCheckboxChange('trueFalse')}
                                />
                                True/False
                            </label>

                            <label>
                                <input
                                    type="checkbox"
                                    checked={quizSetting.questionTypes.fillInTheBlank}
                                    onChange={() => handleCheckboxChange('fillInTheBlank')}
                                />
                                Fill in the Blank
                            </label> */}
                        </div>
                    </div>
                </div>
            </CustomDialog>

            {/* <PdfContainer propForDocLoaded={docLoadedSuccessfully} /> */}

            {/* <MemoizedPdfViewer/> */}

            {/* Quiz Drawer */}
            <CustomDrawer
                anchor={'right'}
                size={'md'}
                open={showQuizDrawer}
                backdropLight
                quizHeader={
                    <>
                        <QuizHeader
                            // onClickEdit={onClickEditQuizSetting}
                            editAction // 1
                            // selectedText={'Oxidizing ketone bodies'}
                            // quizCount={7}
                            questionCount={totalQuestionsCount}
                            timer={formatTime(seconds)} //When Attempt Quiz
                        // timeTaken={'30 mins'}  //Quiz Attempted
                        // quizScore={'20 / 30'} //Quiz Attempted
                        // toggleAction={ //1
                        //     <>
                        //         <p className="text-body1 m-0 fw-bold">Explanation</p>
                        //         <ToggleButton varient={'on-off'} />
                        //     </>
                        // }
                        />
                    </>
                }
                quizAction={
                    <>
                        <Button outlineBtn text={'Cancel'} type='submit' onClick={onClickQuizDrawerCancelBtn} />
                        {
                            !showLoader && !quizGenerationFailed && <Button primary text={'Submit'} type='submit' onClick={onClickQuizDrawerSubmit} />
                        }
                    </>
                }
            >
                {showLoader && <div className='text-center circle-loader'>
                    <img src={GifLoaderMain} />
                </div>}
                {quizGenerationFailed && <div className="text-center circle-loader">
                        <h5>Quiz generation failed, please try again !</h5>
                    </div>}
                {
                    !showLoader && !quizGenerationFailed && <QuizQuestion
                        questionItem={currentQuiz.Questions &&
                            currentQuiz.Questions.map((question, index) => ({
                                questionCount: index + 1,
                                question: question.Question,
                                questionGuidline: () => { onClickGuidingLight(question.Hints, question.Question) },
                                questionOptions: question.Options.map((option) => ({
                                    option: option.OptionName,
                                    optionName: question.QuestionId,
                                    onOptionChange: () => { onOptionChange(question.QuestionId, option.OptionId) }
                                }))
                            }))
                            // [
                            //     {
                            //         questionCount: 1,
                            //         question: 'Which of the following is NOT a ketone body?',
                            //         questionGuidline: () => { },
                            //         questionOptions: [
                            //             {
                            //                 option: 'Acetoacetate',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Beta-hydroxybutyrate',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Glyceraldehyde-3-phosphate',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Acetone',
                            //                 optionName: 'radio'
                            //             }
                            //         ]
                            //     },
                            //     {
                            //         questionCount: 2,
                            //         question: 'Where are ketone bodies primarily produced?',
                            //         correct: true,
                            //         questionOptions: [
                            //             {
                            //                 option: 'Kidneys',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Liver',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Pancreas',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Adrenal glands',
                            //                 optionName: 'radio'
                            //             }
                            //         ],
                            //         explaination: "Mitochondria are the powerhouse of the cell and are responsible for various metabolic processes, including the oxidation of fatty acids and ketone bodies to produce energy in the form of ATP"
                            //     },
                            //     {
                            //         questionCount: 3,
                            //         question: 'Which organelle is involved in the oxidation of ketone bodies?',
                            //         wrong: true,
                            //         questionOptions: [
                            //             {
                            //                 option: 'Nucleus',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Golgi apparatus',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Mitochondria',
                            //                 optionName: 'radio'
                            //             },
                            //             {
                            //                 option: 'Endoplasmic reticulum',
                            //                 optionName: 'radio'
                            //             }
                            //         ],
                            //         answer: 'Mitochondria',
                            //         explaination: "Mitochondria are the powerhouse of the cell and are responsible for various metabolic processes, including the oxidation of fatty acids and ketone bodies to produce energy in the form of ATP"
                            //     }
                            // ]
                        }
                    />
                }
            </CustomDrawer>

            {/* {Giuding Popup} */}
            <CustomDialog
                open={showGuidingPopup}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={GuidingLightIcon}
                dialogTitle={'Guiding Light'}
                dialogAction={
                    <>
                        {/* <Button text='No' outlineBtn type={'Cancel'} onClick={() => { }} /> */}
                        <Button text='Done' primary type={'Done'} onClick={onClickGuidingLightClose} />
                    </>
                }
            >
                <h5 className="text-h5 fw-sbold mt-0 text-dark">{QuestionForHint}</h5>
                {
                    hints.map((hint) => (
                        <p className="text-body2 m-0 text-dark lh-22">{hint}</p>
                    ))
                }
                {/* <div className="guiding-light-action"> */}
                {/* {
                        hints.map((hint) => (
                            <div className="pager">
                                <p className="text-body2 m-0">{hint}</p>
                            </div>
                        ))
                    } */}
                {/* <div className="action-btn-wrap">
                        <a href="javascript:void(0)" className="action-btn mt-0">
                            <img src={RegenrateIcon} alt="" />
                            <span className="btn-text text-body2">Regenrate</span>
                        </a>
                    </div> */}
                {/* </div> */}
            </CustomDialog>

            {/* Quiz Submitted Drawer */}
            <CustomDrawer
                anchor={'right'}
                size={'md'}
                open={showQuizSubmittedDrawer}
                backdropLight
                quizHeader={
                    <>
                        <QuizHeader
                            // selectedText={'Oxidizing ketone bodies'}
                            quizCount={7}
                            questionCount={totalQuestionsCount}
                            timeTaken={formatTime(quizCompletedTimeInSecs)}
                            quizScore={quizScore + ' / ' + totalQuestionsCount}
                            toggleAction={
                                <>
                                    <p className="text-body1 m-0 fw-bold">Explanation</p>
                                    <ToggleButton onChange={handleExplainationToggle} varient={'on-off'} checked={isExplainationToggled} />
                                </>
                            }
                        />
                    </>
                }
                quizAction={
                    <>
                        <Button outlineBtn text={'Done'} type='submit' onClick={onClickQuizSubmittedDrawerDone} />
                        <Button primary text={'Re-take'} type='submit' onClick={onClickReTake} />
                    </>
                }
            >
                {
                    <QuizQuestion
                        questionItem={currentQuiz.Questions &&
                            currentQuiz.Questions.map((question, index) => {
                                let correctAnswers = question.Options.filter(item => item.isCorrect === true)
                                let correctAnswerIds = correctAnswers.map(item => item.OptionId)

                                // select the correct option object for the question, use this to fill answer and explaination prop
                                let objOption = question.Options.find(item => item.isCorrect === true)

                                if (userAnswers ? correctAnswerIds.includes(userAnswers[question.QuestionId]) : false) {
                                    return (
                                        {
                                            questionCount: index + 1,
                                            question: question.Question,
                                            correct: true,
                                            showExplaination: isExplainationToggled,
                                            answer: objOption.OptionName,
                                            explaination: objOption.Explaination,
                                            questionOptions: question.Options.map((option) => ({
                                                option: option.OptionName,
                                                optionName: question.QuestionId,
                                                // if option selected by user matches the same option id then show as checked else it is unchecked
                                                checked: (userAnswers[question.QuestionId] === option.OptionId) || false,
                                                readonly: true
                                            }))
                                        })
                                } else {
                                    return (
                                        {
                                            questionCount: index + 1,
                                            question: question.Question,
                                            wrong: true,
                                            showExplaination: isExplainationToggled,
                                            answer: objOption.OptionName,
                                            explaination: objOption.Explaination,
                                            questionOptions: question.Options.map((option) => ({
                                                option: option.OptionName,
                                                optionName: question.QuestionId,
                                                checked: (userAnswers ? userAnswers[question.QuestionId] === option.OptionId : false),
                                                readonly: true
                                            }))
                                        })
                                }
                            })
                        }
                    />
                }
            </CustomDrawer>

            {/* History Drawer */}
            {/* <CustomDrawer
                anchor={'right'}
                size={'sm'}
                open={showQuizHistoryDrawer}
                onBackBtn={onClickBackBtnQuizHistory}
            >
                <HistoryContent
                    title={'Quiz History'}
                    historyTitle={'Action'}
                    historyDateTitle={'Date'}
                    listItemOptions={
                        quizHistoryItems.map((objItem, index) => ({
                            title: `Quiz # ${quizHistoryItemsCount - 1 - index}`,
                            description: `Score : ${objItem.ObtainedScore} / ${objItem.TotalScore}`,
                            date: convertToLocalDate(objItem.createdOn),
                            onClick: () => { onClickQuizHistoryItem(objItem) },
                            onDelete: () => { onDeleteQuizHistoryItem(objItem.QuizId) }
                        }))
                    }
                />
            </CustomDrawer> */}

            {/* { Quiz history item confirm delete popup } */}
            <CustomDialog
                open={showConfirmDelete}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={DeleteIcon}
                dialogTitle={'Delete Item'}
                dialogAction={
                    <>
                        <Button text='No' outlineBtn type={'submit'} onClick={onNoConfirmDeleteQuizHistoryItem} />
                        <Button text='Yes' primary type={'submit'} onClick={onYesConfirmDeleteQuizHistoryItem} />
                    </>
                }
            >
                <p className="text-body1 text-center m-0">Are you sure you want to delete this item?</p>
            </CustomDialog>

            {diagramDialog && <DiagramDialog open={diagramDialog} closeDialog={closeDiagramDialog} chartCode={diagramDrawerDataFromAPI && diagramDrawerDataFromAPI.mermaidJsCode}/>}
        </BaseLayout>}

        <PdfContainer
            refPdfUploaded={refPdfUploaded}
            propToSetExplainDrawerData={handlePropToSetDrawerState}
            propForExplaination={setExplainationText}
            propExpDrawerChatResponse={handleExpDrawerChatResponse}
            propForDiagramData={setDiagramDrawerDataFromProp}
            showHighlightActions={true}
            propForDocLoaded={docLoadedSuccessfully}
            updateHighlightsHistory={updateHighlightsHistory}
        />
    </>
    );
};

export default Main;