import React, { ReactNode, useEffect } from "react";
import PropTypes from 'prop-types';
import ChatBotIcon from '../../assets/chat-bot-bubble-icon.svg'
import ChatWindowLogo from '../../assets/chat-window-logo.svg'
import NoHighlights from '../../assets/img-empty-highlights.svg'
import './chatWindow.scss'

const Highlights = ({ items, updateHighlightsHistory }) => {
    useEffect(() => {
        updateHighlightsHistory()
    }, [])
    return (
        <div className="chat-window-wrap">
            <div className="chat-area-wrap scrollable">
            <div className="chat-logo-area">
                <img src={ChatWindowLogo} alt="" />
            </div>
            <p className="logo-bottom-text">
                Click on any of the text bubbles to revisit or continue your highlight-specific discussions
            </p>
            <div className="chat-bubble-wrap msg-received">
                    {items.length ? items.map(({ title, selectedText, contentType, contentBrief, chatbotIcon, onClick, createdOn }, index) => (
                        <div className="highlight-item" key={index}>
                            {chatbotIcon &&
                                <div className="chat-bot-icon">
                                    <img className="chat-bot-icon-magic" src={ChatBotIcon} alt="" />
                                </div>
                            }
                            <div className="chat-bubble" onClick={onClick}>
                                <div className="item">
                                    <p className="text-body1 fw-bold text-dark m-0 title">
                                        {title}
                                    </p>
                                    <p className="selected-text text-body2 fw-medium text-muted mb-0">
                                        {selectedText}
                                    </p>
                                </div>
                                <div className="item">
                                    <p className="text-body1 text-dark fw-bold m-0 content-type">
                                        {contentType}
                                    </p>
                                    <p className="content-brief text-body2 fw-medium text-muted mb-0">
                                        {contentBrief}
                                    </p>
                                </div>
                                <div className="timestamp">
                                    <p className="timestamp-text">{createdOn}</p>
                                </div>
                            </div>
                        </div>
                    )) : (!items.length && <div className="no-highlights-wrap">
                        <img src={NoHighlights} alt="No Highlights" />
                    </div>)
                    }
            </div>
            </div>
        </div>
    );
};

export default Highlights;

Highlights.propTypes = {
    items: PropTypes.array
};