const DB_NAME = 'mrezpz';
const STORE_NAME = 'mrezpzData';

export const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open(DB_NAME, 1);

        request.onerror = (event) => {
            reject("Error opening database");
        };

        request.onsuccess = (event) => {
            const db = event.target.result;
            resolve(db);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            const objectStore = db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        };
    });
};

export const saveDataIndexedDB = (dataArray) => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);

            dataArray.forEach(data => {
                store.put(data);
            });

            transaction.oncomplete = () => {
                resolve();
            };
        } catch (error) {
            reject(error);
        }
    });
};

export const getAllDataIndexedDB = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.getAll();

            request.onsuccess = (event) => {
                const data = event.target.result;
                resolve(data);
            };
        } catch (error) {
            reject(error);
        }
    });
};

export const getDataIndexedDB = (key) => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(STORE_NAME, 'readonly');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.get(key);

            request.onsuccess = (event) => {
                const data = event.target.result;
                resolve(data);
            };

            request.onerror = (event) => {
                reject("Error retrieving data by key");
            };
        } catch (error) {
            reject(error);
        }
    });
};

export const deleteAllIndexedDB = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(STORE_NAME, 'readwrite');
            const store = transaction.objectStore(STORE_NAME);
            const request = store.clear(); // Clear all records in the object store

            request.onsuccess = (event) => {
                resolve();
            };

            request.onerror = (event) => {
                reject("Error deleting all data");
            };
        } catch (error) {
            reject(error);
        }
    });
};

