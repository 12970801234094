import React from "react";
import PropTypes from 'prop-types';
import './button.scss'
import { clsx } from "clsx";

const Button = ({ text, className, primary, secondary, defaultBtn, fullWidth, type, onClick, outlineBtn, iconOnly, withIcon, disabled, exception }) => {
    return (
        <button type={type}
            className={clsx(className, {
            btn: true,
            'btn-primary': primary,
            'btn-secondary': secondary,
            'btn-default': defaultBtn,
            'outline-btn': outlineBtn,
            'full-width': fullWidth,
            'icon-only': iconOnly,
            'with-icon': withIcon,
            'exception': exception
          })}
          onClick={onClick}
          disabled={disabled}
        >
            {text}
            {iconOnly &&
                <img src={iconOnly} alt="" />
            }
        </button>
    );
};

export default Button;

Button.propTypes = {
    text: PropTypes.string,
    type:PropTypes.string,
    className: PropTypes.string,
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    defaultBtn: PropTypes.bool,
    outlineBtn: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onClick: PropTypes.func,
    iconOnly: PropTypes.any,
    withIcon: PropTypes.bool,
    disabled: PropTypes.bool
};