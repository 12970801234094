import React from "react";
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import './tooltip.scss'
import { clsx } from "clsx";

const ToolTip = ({ children, title, arrow, open, disablePortal = true, placement, ref, leaveDelay, ...PropTypes}) => {
    return (
        <div className="tooltip-wrap">
            <Tooltip title={title} arrow={arrow} placement={placement} open={open} leaveDelay={leaveDelay}
            PopperProps={{
                disablePortal
            }}>
                <div {...PropTypes} ref={ref}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};

export default ToolTip;

ToolTip.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    arrow: PropTypes.bool,
    placement: 'top' | 'bottom' | 'top-start' | 'top-end' | 'left-start' | 'left' | 'left-end' | 'right-start' | 'right-end' | 'right' | 'bottom-start' | 'bottom-end',
    open: PropTypes.bool,
    disablePortal: PropTypes.bool,
    leaveDelay: PropTypes.number
};