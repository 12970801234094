import { BaseLayout } from "../../components";
import './activity.scss';

import pdfIcon from '../../assets/pdf-icon.png';
import wordIcon from '../../assets/word-icon.png';
import pptIcon from '../../assets/ppt-icon.png';
import NoActivity from '../../assets/img-activity-empty.svg';
import { authChat } from "../../axios";

import React, { useState, useEffect, useRef } from 'react';

import DateFormatter from "../../components/dateformatter/dateformatter";
import { useNavigate } from "react-router";
import GifLoaderMain from "../../assets/gif-loader-main.gif";
import { FileExtension } from "../../constants/enums";
import moment from 'moment';
import {deleteAllIndexedDB, saveDataIndexedDB } from "../../methods/indexedDB";
import { encodeToBase64 } from "../../methods/commonMethods";

const ActivityPage = ({ }) => {

  const [showLoader, setShowLoader] = useState(false)

  // State for search results for activity items
  const [searchResults, setSearchResults] = useState({})

  //For Displaying and Grouping Activity by Date - Monday or 1 week ago etc..
  const [groupedData, setGroupedData] = useState({});
  ////////////////////////////////

  //For Search Functionality
  const [searchTerm, setSearchTerm] = useState('');
  const activitiesRef = useRef(null);
  ////////////////////////////////


  // FUNCTIONS///////////////////////
  const getUserActivity = async (userId) => {
    let UserActivityJsonResponse = []

    await authChat.get(`/get_user_activity`)
      .then((res) => {
        UserActivityJsonResponse = res.data
      })

    return UserActivityJsonResponse
  };

  const navigate = useNavigate()

  const handleActivityDocumentClick = (documentId, DocumentURL) => {
    setShowLoader(true)

    authChat.post(`/get_uploaded_document/`, {payload: encodeToBase64({
      documentId: documentId,
      documentURL: DocumentURL})
    }).then(async (res) => {
        const docDataId = crypto.randomUUID()
        await deleteAllIndexedDB()
        await saveDataIndexedDB([{ id: docDataId, doc_data: JSON.stringify(res.data.doc_data) }])
        localStorage.setItem("doc_data_id", docDataId)

        if (res.data.main_conversation_id === null) {
          localStorage.removeItem("main_conversation_id")
        } else {
          localStorage.setItem("main_conversation_id", JSON.stringify(res.data.main_conversation_id))
        }
        navigate('/main/learning')
        setShowLoader(false)
      })
  };

  const groupByLastOpenedDate = (data) => {
    const today = moment().local();

    return data.reduce((group, item) => {
      const itemDate = moment(item.LastOpened).local();

      const dayDifference = today.diff(itemDate, 'days');
      const weekDifference = Math.floor(dayDifference / 7);

      let groupKey = '';
      if (dayDifference === 0) {
        groupKey = 'Today';
      } else if (weekDifference === 0) {
        groupKey = itemDate.format('dddd');
      } else {
        groupKey = `${weekDifference} week${weekDifference !== 1 ? 's' : ''} ago`;
      }
      if (!group[groupKey]) {
        group[groupKey] = [];
      }
      // const { LastOpened } = item;
      // group[groupKey] = group[LastOpened] ?? [];
      group[groupKey].push(item);
      return group;
    }, {});
  };

  /////////////////////////////////

  useEffect(() => {
    // For Displaying and Grouping Activity by Date - Monday or 1 week ago etc..
    const userStateData = JSON.parse(localStorage.getItem("userState"))
    const data = getUserActivity(userStateData.userId).then((res) => {
      const groupedDataByLastOpened = groupByLastOpenedDate(res)
      setGroupedData(groupedDataByLastOpened);
      setSearchResults(groupedDataByLastOpened)
    });
    ////////////////////////////////

  }, []);

  useEffect(() => {

    const searchTermTrimmed = searchTerm.trim()
    if (searchTermTrimmed) {
      let filteredData = {}
      Object.entries(groupedData).forEach(([date, documents]) => {
        const filteredDocs = documents.filter(document => document.DocumentTitle.toLowerCase().includes(searchTermTrimmed.toLowerCase()))
        if (filteredDocs.length > 0) {
          filteredData[date] = filteredDocs
        }
      })
      setSearchResults(filteredData)
    } else {
      setSearchResults(groupedData)
    }
  }, [searchTerm]);

  // Function to render file icon
  const renderIcon = (fileExtension) => {
    if (fileExtension === FileExtension.Doc || fileExtension === FileExtension.Docx) {
      return <img src={wordIcon} alt="Word File" />
    }
    else if (fileExtension === FileExtension.Pdf) {
      return <img src={pdfIcon} alt="Pdf File" />
    }
    else if (fileExtension === FileExtension.Ppt || fileExtension === FileExtension.Pptx) {
      return <img src={pptIcon} alt="Powerpoint File" />
    }
  }

  console.log(groupedData)

  return (
    <BaseLayout noRightArea acitivityIcon={false} feedbackFormIcon={true} showRightChatArea={false}>
      <div class="activity-container-wrap">
        <div className="activity-container">
          <h1 className="activity-heading mt-0 mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" viewBox="0 0 35 28" fill="none">
              <path d="M21.5775 28H21.21C20.4693 27.9265 19.7714 27.6186 19.2175 27.1212C18.6636 26.6237 18.2827 25.9625 18.13 25.2337L13.44 3.48838L8.61 14.6937C8.47338 15.0069 8.24815 15.2732 7.96206 15.4599C7.67598 15.6465 7.34155 15.7454 7 15.7442H1.75C1.28587 15.7442 0.840752 15.5597 0.512563 15.2314C0.184374 14.903 0 14.4577 0 13.9934C0 13.529 0.184374 13.0837 0.512563 12.7553C0.840752 12.427 1.28587 12.2425 1.75 12.2425H5.845L10.2375 2.10522C10.5332 1.42496 11.0372 0.856214 11.6768 0.480995C12.3164 0.105776 13.0586 -0.0565323 13.7963 0.0174728C14.5341 0.0914778 15.2293 0.397972 15.7817 0.892765C16.3341 1.38756 16.7152 2.04508 16.87 2.77054L21.56 24.4983L26.39 13.328C26.5213 13.0083 26.7442 12.7346 27.0307 12.5414C27.3173 12.3483 27.6545 12.2443 28 12.2425H33.25C33.7141 12.2425 34.1592 12.427 34.4874 12.7553C34.8156 13.0837 35 13.529 35 13.9934C35 14.4577 34.8156 14.903 34.4874 15.2314C34.1592 15.5597 33.7141 15.7442 33.25 15.7442H29.155L24.7625 25.8815C24.4943 26.5057 24.0503 27.0383 23.4848 27.4145C22.9193 27.7907 22.2566 27.9941 21.5775 28Z" fill="black" />
            </svg>&nbsp;Activity
          </h1>
          <div className="activity-search-wrap">
            <input type="text" placeholder="Search ..." className="activity-search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <svg className="activity-search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M15.5451 14.6625L17.9864 17.1025C18.1002 17.2204 18.1632 17.3782 18.1618 17.5421C18.1603 17.706 18.0946 17.8627 17.9787 17.9786C17.8629 18.0945 17.7061 18.1602 17.5422 18.1616C17.3784 18.1631 17.2205 18.1001 17.1026 17.9862L14.6614 15.545C13.0661 16.9118 11.0034 17.6076 8.90612 17.4863C6.80887 17.3651 4.8401 16.4363 3.41291 14.8948C1.98573 13.3532 1.21108 11.3189 1.25151 9.21851C1.29194 7.11815 2.14431 5.11509 3.62976 3.62964C5.11521 2.14419 7.11827 1.29181 9.21863 1.25138C11.319 1.21095 13.3534 1.98561 14.8949 3.41279C16.4364 4.83998 17.3652 6.80875 17.4865 8.906C17.6077 11.0032 16.9119 13.0659 15.5451 14.6612V14.6625ZM9.3751 16.25C11.1985 16.25 12.9471 15.5257 14.2365 14.2363C15.5258 12.947 16.2501 11.1983 16.2501 9.37498C16.2501 7.55162 15.5258 5.80293 14.2365 4.51362C12.9471 3.22431 11.1985 2.49998 9.3751 2.49998C7.55174 2.49998 5.80305 3.22431 4.51374 4.51362C3.22443 5.80293 2.5001 7.55162 2.5001 9.37498C2.5001 11.1983 3.22443 12.947 4.51374 14.2363C5.80305 15.5257 7.55174 16.25 9.3751 16.25Z" fill="black" />
            </svg>
          </div>
        </div>
        {showLoader && <div className='text-center circle-loader'>
          <img src={GifLoaderMain} />
        </div>}
        {
          !showLoader && <div className="inner-activity-container scrollable" ref={activitiesRef}>
            {Object.keys(searchResults).length ? Object.keys(searchResults).map((date) => (
              <div className="activity-item">
                {/* <div date={date} className="activity-item-heading-wrap">
                  <DateFormatter date={date} />
                </div> */}
                <h2 title={date} className="activity-item-heading">{date}</h2>
                <div className="activity-list-wrap">

                  {searchResults[date].map(item => (
                    <div className="activity-list-item-wrap">
                      <div className="activity-list-item">
                        <div className="activity-list-item-icon">
                          {renderIcon(item.OriginalFileExtension)}
                          {/* <img src={pdfIcon} alt="pdf" /> */}
                        </div>
                        <div key={item.DocumentID} className="activity-list-item-title"
                          onClick={() => handleActivityDocumentClick(item.DocumentID, item.DocumentURL)}
                        >
                          {item.DocumentTitle}
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            )): <div className="no-activity-wrap">
              <img src={NoActivity} alt="No Activity" />
              </div>}
          </div>
        }
      </div>
    </BaseLayout>
  )
}

export default ActivityPage;

ActivityPage.propTypes = {
};