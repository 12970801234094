import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { BsRecordCircle } from "react-icons/bs";
import { AiOutlineFormatPainter } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import myGif from '../../assets/EzPz_Upload.svg'
import GifLoaderMain from "../../assets/gif-loader-main.gif";
import { authUploadDoc } from "../../axios";
import LogoutButton from "../logout"
import ezpzLogo from '../../assets/login-ezpz-logo.png';
import ezpzText from '../../assets/login-ezpz-text.png';
import ezpzUploadInactive from '../../assets/upload-inactive.png';
import UploadComplete from '../../assets/upload-complete.png';
import WordFileIcon from '../../assets/ic-file-word.svg';
import PdfFileIcon from "../../assets/ic-file-pdf.svg"
import PptFileIcon from "../../assets/ic-file-ppt.svg"
import EditIcon from '../../assets/ic-edit.svg';
import Button from "../button/button";
import DeleteIcon from "../../assets/ic-del.svg"
import { BaseLayout } from "..";
import './UploadFile.scss';
import { bytesToSize, getFileNameWithoutExtension } from "../../methods/commonMethods";
import { FileExtension } from "../../constants/enums";
import { Grid, Tooltip } from "@mui/material";
import TooltipHover from "../TooltipHover/TooltipHover"
import { deleteAllIndexedDB, saveDataIndexedDB } from "../../methods/indexedDB";

const UploadFile = () => {

  const navigate = useNavigate()

  const [prevDocTitle, setPrevDocTitle] = useState("")

  const [docInfo, setDocInfo] = useState({
    docTitle: "",
    fileSize: 0,
    fileExtension: "",
    file: {}
  })

  const [editDocTitle, setEditDocTitle] = useState(false)
  const [isFileSelected, setIsFileSelected] = useState(false)

  const [isDocLoading, setIsDocLoading] = useState(false)
  const fileInputRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [bgColor, setBgColor] = useState(
    "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))"
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleUpload = () => {
    fileInputRef.current.click();
  };
  // Screen Size Check
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      // Adjust the width threshold as needed
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleFileUpload = async (event) => {
    // setIsDocLoading(true)
    const file = event.target.files[0];

    const fileExtension = file?.name.split(".").pop().toLowerCase();
    // Check for supported formats
    const supportedFormats = ["doc", "docx", "pptx", "ppt", "pdf"];
    if (!supportedFormats.includes(fileExtension)) {
      alert(
        "Unsupported file format. Please upload a doc, docx, ppt, pptx, or pdf file."
      );
      return;
    } else {
      // Check for size limit
      const fileSizeInBytes = file?.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      if (fileSizeInMB.toFixed(2) <= 10) {

        // Setting up doc title and size
        const docInfoModel = {
          docTitle: getFileNameWithoutExtension(file.name),
          fileSize: bytesToSize(file.size),
          fileExtension: fileExtension,
          file: file
        }

        setDocInfo(docInfoModel)
        setIsFileSelected(true)

        // if fileSize is less than 50 uploaa file
        // uploadFile(file, fileExtension)
      } else {
        alert("Upload a document with file size less than 10MB")
      }
    }
  };


  // Upload File api call
  const uploadFile = (file, fileExtension) => {
    // console.log("doc", file)
    setLoading(true)
    const formData = new FormData();
    formData.append("documentTitle", docInfo.docTitle)
    formData.append("uploaded_file", file)
    formData.append("originalFileExtension", fileExtension)
    authUploadDoc.post("/process_document_upload/", formData)
      .then(async (res) => {
        const docDataId = crypto.randomUUID()
        const fileExtensionId = crypto.randomUUID()
        await deleteAllIndexedDB()
        await saveDataIndexedDB([{ id: docDataId, doc_data: JSON.stringify(res.data) }, { id: fileExtensionId, fileExtension: JSON.stringify(fileExtension) }])
        localStorage.setItem("doc_data_id", docDataId)
        localStorage.setItem("fileExtension", fileExtensionId)
        localStorage.removeItem("main_conversation_id")
        setLoading(false)
        setIsDocLoading(false)
        localStorage.setItem("isDocUploaded", true)

        // if (isSmallScreen) {
        //   navigate('/letstart')
        // } else {
        //   navigate('/learning')
        // }

        navigate('/main/learning')
      })
      .catch((err) => {
        alert("File could not be uploaded.")
        console.log(err)
        setLoading(false)
        setIsDocLoading(false)
        navigate("/uploadFile")
      })
  }

  // Upload complete Done btn
  const onClickDone = () => {

    uploadFile(docInfo.file, docInfo.fileExtension)

    setIsDocLoading(true)
    setIsFileSelected(false)
    setEditDocTitle(false)

    setDocInfo({})
    setPrevDocTitle("")
  }

  // Upload complete Edit btn
  const onClickEdit = () => {
    setPrevDocTitle(docInfo.docTitle)
    setEditDocTitle(true)
  }

  // After file is selected
  const onClickDelete = () => {

    setIsFileSelected(false)
    setEditDocTitle(false)

    setDocInfo({})
  }

  // When doc title is being edited, set the doc title state
  const onChangeDocTitle = (e) => {
    setDocInfo({
      ...docInfo,
      docTitle: e.target.value
    })
  }

  // On click cancel when renaming document title
  const onClickRenameDocTitleCancel = () => {
    setDocInfo({
      ...docInfo,
      docTitle: prevDocTitle
    })

    setEditDocTitle(false)
  }

  // on click Done btn when renaming document
  const onClickRenameDocDone = () => {

    if (docInfo.docTitle.trim()) {
      if (docInfo.docTitle.length > 150) {
        alert("Title should not exceed 150 characters.")
      } else {
        setEditDocTitle(false)
      }
    } else {
      alert("Doc title is empty!")
    }
  }

  const renderIcon = (fileExtension) => {
    if (fileExtension === FileExtension.Doc || fileExtension === FileExtension.Docx) {
      return <img src={WordFileIcon} alt="Word File" />
    }
    else if (fileExtension === FileExtension.Pdf) {
      return <img src={PdfFileIcon} alt="Pdf File" />
    }
    else if (fileExtension === FileExtension.Ppt || fileExtension === FileExtension.Pptx) {
      return <img src={PptFileIcon} alt="Powerpoint File" />
    }
  }

  const formatDocTitle = (docTitle) => {
    if (docTitle.length > 65) {
      return docTitle.slice(0, 62) + '...'
    } else {
      return docTitle
    }
  }

  return (
    <BaseLayout noRightArea uploadIcon={false} showRightChatArea={false} feedbackFormIcon={false}>
      <div className='upload-container-wrap'>
        <div className='upload-container'>
          {/* <div className="upload-wrap">
            <img className='ezpz-logo' src={ezpzLogo} alt="ezpz logo" />
            <img className='ezpz-text' src={ezpzText} alt="ezpz text" />
          </div> */}
          {!isDocLoading && <div className="upload-file-wrap text-center">
            <div className="upload-file-icon">
              <img src={ezpzUploadInactive} alt="ezpz upload inactive" />
              {/* {isUploadComplete
                ? (
                  <img src={UploadComplete} alt="ezpz upload complete" />
                )
                :
                <img src={ezpzUploadInactive} alt="ezpz upload inactive" />
              } */}
            </div>
            <div className="upload-file-text">
              <h1 className="fw-bold mt-0">

                {/* if renaming doc title then show "Rename Document" else "Upload your Document" */}
                {
                  editDocTitle
                    ? (
                      <>Rename Document</>
                    )
                    : (
                      <>Upload your Document</>
                    )
                }

                {/* {isUploadComplete
                  ? (
                    <>Upload Complete!</>
                  )
                  :
                    <>Upload your Document</>
                } */}
              </h1>
            </div>
            <div className="upload-file-instructions">
              <p className="mt-0">Upload notes, articles, or presentations max up to <b>10MB</b>. Set the stage for a transformative learning experience.</p>
            </div>

            {/* If file is selected then show its info */}
            {
              isFileSelected && <div className="uploaded-file-area">
                <div className="file-icon">

                  {/* dynamically render file icon based on file extension */}
                  {renderIcon(docInfo.fileExtension)}

                  <span className="file-size text-caption">
                    {docInfo.fileSize}
                  </span>
                </div>
                <div className="file-name-area">

                  <Grid>
                    <div className="tooltip-wrap">
                      <TooltipHover
                        disableFocusListener
                        disableTouchListener
                        arrow
                        title={
                          <span className="text-body2 fw-medium">
                            <span>{docInfo.docTitle}</span>
                          </span>
                        }
                      >
                        {
                          !editDocTitle && <span className="file-name text-h4">
                            {formatDocTitle(docInfo.docTitle)}
                          </span>
                        }
                      </TooltipHover>
                    </div>
                  </Grid>

                  {
                    editDocTitle && <div className="file-edit-area">
                      <input onChange={onChangeDocTitle} type="text" className="edit-file-name" value={docInfo.docTitle} />
                      <span className="file-extension text-h5">{`.${docInfo.fileExtension}`}</span>
                    </div>
                  }
                </div>

                {
                  !editDocTitle && <>
                    <div className="file-action">
                      <Button onClick={onClickEdit} type={'submit'} iconOnly={EditIcon} outlineBtn />
                    </div>
                    <div className="file-action">
                      <Button onClick={onClickDelete} type={'submit'} iconOnly={DeleteIcon} outlineBtn />
                    </div>
                  </>
                }
              </div>
            }

            <div className="upload-file-btn">

              {/* If file is selected then show Done btn else show Upload btn */}
              {isFileSelected
                ? <>
                  {
                    editDocTitle
                      ? (
                        <>
                          <Button text="Cancel" outlineBtn onClick={onClickRenameDocTitleCancel} />
                          <Button text="Done" secondary onClick={onClickRenameDocDone} />
                        </>
                      )
                      : (
                        <>
                          <Button text="Done" secondary onClick={onClickDone} />
                        </>
                      )
                  }
                </>
                :
                <>
                  <input
                    type="file"
                    accept=".doc,.ppt,.pdf,.docx,.pptx"
                    onChange={handleFileUpload}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  {/* onClickUpload */}
                  <Button text="Upload" primary onClick={handleUpload} />
                </>
              }
            </div>
          </div>}
          {isDocLoading && <div className="text-center">
            <img src={GifLoaderMain} />
            <h3>Uploading Document</h3>
          </div>}
        </div>

        {/* <div className='upload-footer'>
          <p>
            Copyright 2024 Mr. EzPz
          </p>
          <p>
            All rights reserved
          </p>
        </div> */}
      </div>

    </BaseLayout>
    //     <>
    //     {/* {loading?<div className={styles.loading}><img src={myGif} className={styles.story_gif}/></div>: */}
    //     <div className={styles.main_div}>
    //       <LogoutButton/>
    //       <div className={styles.first_section}>
    //         <div>
    //           <p className={styles.main_heading}>Please Upload A Document</p>
    //           <div className={styles.upload_section}>
    //           <input
    //         type="file"
    //         accept=".doc,.ppt,.pdf,.docx,.pptx"
    //         onChange={handleFileUpload}
    //         ref={fileInputRef}
    //         style={{ display: "none" }}
    //       />
    //       {loading?<RotatingLines
    //   strokeColor="grey"
    //   strokeWidth="5"
    //   animationDuration="0.75"
    //   width="70"
    //   visible={true}
    // />:<> <button  className={styles.upload_btn} onClick={handleUpload}>Upload File</button>
    //             <p className={styles.drag_and_drop}>or drag and drop here</p></>}

    //           </div>
    //           <p className={styles.pdf_format_only}>
    //           PDF, PPTX, and DOCX format only. Not exceeding 50 Mbs
    //           </p>
    //         </div>
    //       </div>
    //       <div className={styles.second_section}  style={{
    //                 background: bgColor,
    //               }}>
    //         <div className={styles.second_section_text}>
    //           {isSmallScreen? <div className={styles.text_box}>
    //           <p>
    //           Your documents are the start; true learning is the goal. Let's get there together. 
    //           </p>
    //           </div>: <div>
    //           <p>
    //           Your documents are the start; true learning is the goal. 
    //           </p>
    //           <p>
    //           Let's get there together. 
    //           </p>
    //           </div>}
    //         </div>
    //         <div className={styles.gif_container}>
    //           <img src={myGif} className={styles.story_gif}/>
    //           </div>
    //       </div>
    //     </div>

    //     </>
  );
};

export default UploadFile;

UploadFile.propTypes = {
  isUploadComplete: PropTypes.bool
};
