import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import ChatBotIcon from '../../assets/chat-bot-bubble-icon.svg'
import './chatWindow.scss'

const AIResponse = ({ aiResponse, chatBotIcon = true }) => {
    return (
        <div className="chat-bubble-wrap msg-received">
            {chatBotIcon &&
                <div className="chat-bot-icon">
                    <img className="chat-bot-icon-magic" src={ChatBotIcon} alt="" />
                </div>
            }
            <div className="chat-bubble">
                <p className="text-body2 text-muted m-0">{aiResponse}</p>
            </div>
        </div>
    );
};

export default AIResponse;

AIResponse.propTypes = {
    aiResponse: PropTypes.any,
    chatBotIcon: PropTypes.bool
};