import React from "react";
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import ArrowIcon from '../../assets/ic-next.svg'
import './pagination.scss'
import Button from "../button/button";

const Pager = ({
    totalCount,
    currentPage,
    onPreviousClick,
    onNextClick,
    prevDisabled,
    nextDisabled
}) => {

    return (
        <div className="pagination-wrap">
            {/* <Pagination count={count} /> */}
            <div className="prev-btn pager-btn">
                <Button iconOnly={ArrowIcon} onClick={onPreviousClick} defaultBtn disabled={prevDisabled} />
            </div>
            <div className="pager-count">
                <h4 className="text-h4 mt-0 mb-0">
                    {currentPage} / {totalCount}
                </h4>
            </div>
            <div className="next-btn pager-btn">
                <Button iconOnly={ArrowIcon} onClick={onNextClick} defaultBtn disabled={nextDisabled} />
            </div>
        </div>
        
    );
};

export default Pager;

Pager.propTypes = {
    totalCount: PropTypes.any,
    currentPage: PropTypes.any,
    onPreviousClick: PropTypes.any,
    onNextClick: PropTypes.any,
    prevDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool
};