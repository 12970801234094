import React from "react";
import PropTypes from 'prop-types';
import { clsx } from "clsx";
import './textArea.scss'

const TextArea = ({ name, placeholder, rows, value, helpText, endIcon, actionBtn, onChange, onKeyPress, required, disabled }) => {
    return (
        <div className="text-area-wrap">
            <textarea 
            className={clsx({
                'form-control': true,
                'has-action-btn': actionBtn
            })}
            name={name}
            placeholder={placeholder}
            rows={rows}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            required={required}
            disabled={disabled}
            ></textarea>
            {endIcon &&
                <div className="endIcon">
                    <img src={endIcon} alt="" />
                </div>
            }
            {actionBtn &&
                <div className="action-btn">
                    {actionBtn}
                </div>
            }
            {helpText &&
                <small className="text-small text-muted help-text">{helpText}</small>
            }
        </div>
        
    );
};

export default TextArea;

TextArea.propTypes = {
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.any,
    helpText: PropTypes.any,
    endIcon: PropTypes.any,
    actionBtn: PropTypes.any,
    onChange: PropTypes.any
};