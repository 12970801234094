import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react";
import styles from "./PDFUploaded.module.css";
import character from "../../assets/1-a.png";
import { BsRecordCircle } from "react-icons/bs";
import { AiOutlineFormatPainter } from "react-icons/ai";
import PDFsection from "../pdfSection/PDFsection";
import Typist from "react-typist";
import EyesCom from "./EyesAnimation";
import EyesCom2 from "./EyesAnimation2";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import MermaidChatMessage from "./Mermaid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Reveal } from "react-reveal";
import axios from "axios";
import myGif from "../../assets/gif_data.gif";
import Modal from "./Modal";
import myGifUpload from "../../assets/EzPz_Upload.svg";
import Slide from 'react-reveal/Slide';

import { Mermaid } from 'mdx-mermaid/Mermaid';
import { authChat, authAxios } from "../../axios";
import { encodeToBase64, getDocData } from "../../methods/commonMethods";



const PDFUploaded = forwardRef(({ propToSetExplainDrawerData, propForExplaination, propExpDrawerChatResponse, propForDiagramData, showHighlightActions, propForDocLoaded, updateHighlightsHistory }, ref) => {

  // Expose the child function to the parent component through the ref
  useImperativeHandle(ref, () => ({
    handleChat,
  }));

  const messageContainerRef = useRef(null);
  const [lines, setLines] = useState();
  const [mermaidCode, setMermaidCode] = useState();
  const [Thread, setThread] = useState([]);
  const [currentLineIndex, setCurrentLineIndex] = useState(0);
  const [showDropDown, setShowDropDown] = useState(false);
  const [zoomOutLevel, setZoomOutLevel] = useState(0);
  const [fileData, setFileData] = useState();
  const [searchHighlight, setSearchHighlight] = useState("");
  const [bgColor, setBgColor] = useState(
    "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))"
  );
  const [dropDownData, setDropDownData] = useState([]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // const [explainDrawerData, setExpDrawerData] = useState({
  //   isExplainDrawerVisible: false,
  //   selectedText: '',
  //   explaination: ''
  // })

  // useEffect(() => {
  //   console.log("State-changed-in-upload", explainDrawerData)
  // }, [explainDrawerData])

  // Scaling Pdf Viewer on Different Screens
  useEffect(() => {
    const setFileDataState = async () => {
      const fileData = await getDocData()
      setFileData(fileData)
    }
    setFileDataState()
    localStorage.setItem("themeColor", "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))")
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      if (window.innerWidth <= 768) {
        setFontScale(-5.8);
        setHighlighterKey(1691865075703);
      } else if (window.innerWidth <= 768) {
        setFontScale(-4.5);
        setHighlighterKey(1691865075703);
      } else if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setFontScale(-3.5);
        setHighlighterKey(1691865075707);
      } else if (window.innerWidth > 1024 && window.innerWidth <= 1440) {
        setFontScale(-1.3);
        setHighlighterKey(1691865075707);
      } else if (window.innerWidth > 1440 && window.innerWidth <= 1920) {
        setFontScale(-1);
        setHighlighterKey(1691865075707);
      } else if (window.innerWidth > 1920 && window.innerWidth <= 2560) {
        setFontScale(1);
        setHighlighterKey(1691865075707);
      } else {
        setFontScale(-1.3);
        setHighlighterKey(1691865998260);
      }

      // Adjust the width threshold as needed
    };

    // Initial check
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Scroll View to bottom
  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      setZoomOutLevel(messageContainerRef.current.scrollTop);
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };
  // Scroll View to Top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // You can use 'auto' instead of 'smooth' for instant scrolling
    });
  };
  const [opacityScale, setOpacityScale] = useState(1);
  const [showDrawer, setShowDrawer] = useState(false);
  const [summeryData, setSummeryData] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeHighlight, setActiveHighlight] = useState("");
  const [modalMermaidCode, setModalMermaidCode] = useState("");
  const [showTheme, setShowTheme] = useState(false);
  const [TypingTextState, setTypingTextState] = useState("")
  const [position, setPosition] = useState(50); // Initial position (percentage)
  const [typingKey, setTypingKey] = useState(0);
  const [askedQuestion, setAskedQuestion] = useState("");
  const [chatLoading, setChatLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [threadHistory, setThreadHistory] = useState([]);
  const [lastTeachHistory, setLastTeachHistory] = useState({});
  const [lastChatHistory, setLastChatHistory] = useState({});
  const [trackerList, setTrackerList] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [initialY, setInitialYY] = useState(0);
  const [historY, setHistorY] = useState(0);
  const [accessToken, setAccessToken] = useState(0);
  const [summryLoading, setSummryLoading] = useState(false);
  const [animateTextStop, setAnimateTextStop] = useState(false);
  const [standAnimation, setStandAnimation] = useState(false);


  // Scroll View 
  const handleScroll = () => {
    if (messageContainerRef.current) {
      setScrollY(messageContainerRef.current.scrollTop);
    }
  };

  // Attach scroll event listener on mount
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener on unmount
    return () => {
      if (messageContainerRef.current) {
        messageContainerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // History Scroll
  useEffect(() => {
    if (historY === 0 && scrollY > 50) {
      setInitialYY(scrollY - 150)
      setHistorY(scrollY)
    } else {
      if (scrollY > historY && historY !== 0) {
        setInitialYY(scrollY - 150)
        setHistorY(scrollY)
      }
    }
  }, [scrollY])

  // Handle Modal
  const handleOpenModal = (mermaidCode) => {

    setOpenModal(true);
    setModalMermaidCode(mermaidCode);
  };

  // Close Modal
  const handleCloseModal = () => setOpenModal(false);

  // Typing Done
  const handleTypingDone = async () => {
    setShowFields(true);
    scrollToBottom();
  };
  // Mouse Movemnet
  const handleMouseMove = (event) => {
    const slider = document.getElementById("slider-container");
    const rect = slider.getBoundingClientRect();
    const newPosition = Math.max(
      0,
      Math.min(100, ((event.clientX - rect.left) / rect.width) * 100)
    );
    setPosition(newPosition);
  };
  // Mouse Movemnet
  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Update File Title Movemnet Apis not Completed
  const UpdateFileTitle = (title) => {
    const data = {
      fileId: fileData.fileId,
      fileName: title,
    };
    axios
      .patch("http://192.168.100.9:8001/ezypzy/file_save/", data)
      .then((res) => {
        ResetFiledata();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Delete File Data Movemnet Apis not completed
  const ResetFiledata = () => {
    axios
      .get(
        `http://192.168.100.9:8001/ezypzy/file_save/?fileId=${fileData.fileId}`
      )
      .then((res) => {
        setFileData(res.data.result);
        localStorage.setItem("file", JSON.stringify(res.data.result));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const scrollTargetRef = useRef(null);
  var fullHeight = 0;
  if (messageContainerRef) {
    setTimeout(() => {
      if (messageContainerRef.current) {
        fullHeight = messageContainerRef.current.scrollTop - 100;
      }
    }, 1000); // Check if messageContainerRef is not null
  }

  //Click Teach me or diagram api call
  const handleTechAndDiagram = (action, value, btnType) => {
    setAnimateTextStop(false)
    setHistorY(0)
    setInitialYY(0)
    setLines('')
    setMermaidCode('')
    if (trackerList.length === 0) {
      setTrackerList([value])
    } else {
      if (value.content?.text) {
        if (trackerList.find(item => item.content.text === value.content.text)) {
          // Handle case where value.content.text is not unique
          console.log('Duplicate text. Do not add to trackerList.');
        } else {
          console.log("TrackerListValue", trackerList)
          setTrackerList([...trackerList, value]);
        }
      }

    }
    if (lastTeachHistory?.user_highlight) {
      setThread([
        ...Thread,
        {
          main: lastTeachHistory.user_highlight,
          aires: lastTeachHistory.TxtResponse,
          dropDownData: lastTeachHistory.relatedQuestions,
          createDate: Date.now(),
          diagram: lastTeachHistory.mermaidJSCode ? lastTeachHistory.mermaidJSCode : "",
        },
      ]);
      setLastTeachHistory({})
    } else if (lastChatHistory?.message) {
      setThread([
        ...Thread,
        {
          main: "",
          title: askedQuestion,
          aires: lastChatHistory.message,
          dropDownData: lastChatHistory.relatedQuestions,
          createDate: Date.now(),
          diagram: "",
        },
      ]);
      setLastChatHistory({})
    }
    setAskedQuestion('')


    if (value !== activeHighlight) {
      setActiveHighlight(value.content.text);

    }
    if (value === "no data") {
      alert("Select Any Line from Pdf");
    } else {
      const valueData = value.content?.text ? value.content.text : searchHighlight
      if (action === "teach") {
        setLoading(true);
        setMermaidCode("")

        const teachMeModel = { user_highlight: valueData, documentID: fileData?.document_id, highlightBtnType: btnType }

        authAxios
          .post("/teachme", {payload: encodeToBase64(teachMeModel)})
          .then((res) => {
            addDocHistoryItem(btnType, valueData, res.data.TxtResponse)
            setShowDropDown(false);
            setShowFields(false);
            setLines(res.data.TxtResponse);
            setDropDownData(res.data.relatedQuestions);
            setLastTeachHistory(res.data)
            scrollToBottom()
            // setExpDrawerData({
            //   ...explainDrawerData,
            //   explaination: res.data.TxtResponse
            // })
            // console.log("Res", res)
            propForExplaination(res)
            // console.log("Prop", propForExplaination)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });


      } else {
        // const fileExtension=JSON.parse(localStorage.getItem("fileExtension"))
        const fileExtension = ".pdf";
        setLoading(true);
        const visualizeRequest = { user_highlight: valueData, documentID: fileData?.document_id, originalFileExtension: fileExtension };
        authAxios
          .post(`/visualize`, {payload: encodeToBase64(visualizeRequest)})
          .then((res) => {
            addDocHistoryItem(btnType, valueData, res.data.TxtResponse + "txtEnded" + res.data.mermaidJSCode)
            setShowDropDown(false);
            setShowFields(false);
            setLines(res.data.TxtResponse);
            setDropDownData(res.data.relatedQuestions);
            setMermaidCode(res.data?.mermaidJSCode);
            setLastTeachHistory(res.data)
            scrollToBottom()
            propForDiagramData(res)
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }

    }

  };

  // Function to add docHistory
  const addDocHistoryItem = async (highlightBtnTypeId, selectedText, aiResponse) => {
    const trimmedText = selectedText.trim()

    if (!trimmedText) {
      alert("Select Any Line from Pdf")
    } else {
      const docData = await getDocData();
      const addDocHistoryItemModel = {
        DocumentId: docData.document_id,
        SelectedText: trimmedText,
        HighlightBtnTypeId: highlightBtnTypeId,
        AIResponse: aiResponse
      }
      authChat.post("/docHistory/create", {payload: encodeToBase64(addDocHistoryItemModel)})
        .then((res) => {
          // console.log("Res", res)
          localStorage.setItem("currentDocHistoryItemId", JSON.stringify(res.data.currentDocHistoryItemId))
          updateHighlightsHistory()
        })
    }
  }

  // Function to update conversation of doc history
  const updateConversationDocHistory = (conversationId) => {
    const updateDocHistoryModel = {
      DocumentHistoryItemId: JSON.parse(localStorage.getItem("currentDocHistoryItemId")),
      ConversationId: conversationId
    }
    // console.log("objUpdate", updateDocHistoryModel)

    authChat.post("/docHistory/update", {payload: encodeToBase64(updateDocHistoryModel)})
    .then((res) => {
      updateHighlightsHistory()
    })
  }

  //Click Chat api call
  const handleChat = (message, conversationTypeId, selectedText = "") => {
    setAnimateTextStop(false)
    if (searchHighlight) {
      setSearchHighlight('')
    }
    setMermaidCode('')
    const trimmedValue = message.trim();
    if (trimmedValue === '' || trimmedValue === '"' || trimmedValue === '" "' || trimmedValue === '""') {
      message = ''; // Clear the input field
      alert("Invalid input! Please enter valid text.");
    } else {
      setLines('  ')
      if (lastTeachHistory?.user_highlight) {
        setThread([
          ...Thread,
          {
            main: lastTeachHistory.user_highlight,
            aires: lastTeachHistory.TxtResponse,
            dropDownData: lastTeachHistory.relatedQuestions,
            createDate: Date.now(),
            diagram: lastTeachHistory.mermaidJSCode ? lastTeachHistory.mermaidJSCode : "",
          },
        ]);
        setLastTeachHistory({})
      } else if (lastChatHistory?.message) {
        setThread([
          ...Thread,
          {
            main: "",
            title: askedQuestion,
            aires: lastChatHistory.message,
            dropDownData: lastChatHistory.relatedQuestions,
            createDate: Date.now(),
            diagram: "",
          },
        ]);
        setLastChatHistory({})
      }
      setAskedQuestion(message);
      setMermaidCode("")
      const conversation_id = JSON.parse(localStorage.getItem("conversation_id"));
      let isSocraticLearning = localStorage.getItem("isSocraticLearning") ?? true
      
      if (conversation_id) {
        setChatLoading(true)
        authChat.post(`/chat`, {payload: encodeToBase64({
          message: message,
          user_highlight: selectedText ?? activeHighlight,
          documentId: fileData?.document_id,
          conversationTypeId: conversationTypeId,
          isSocraticLearning: isSocraticLearning,
          conversation_id: conversation_id
        })})
        .then((res) => {
            setShowDropDown(false);
            setShowFields(false);
            setLines(res.data.message);
            localStorage.setItem(
              "conversation_id",
              JSON.stringify(res.data.conversation_id)
            );
            setDropDownData(res.data.relatedQuestions);
            setLastChatHistory(res.data)
            propExpDrawerChatResponse(res)
            scrollToBottom()
          })
          .catch((err) => {
            console.log(err);
            setShowDropDown(true);
            setLines("No Data Found");
            setShowFields(true);
          })
          .finally(() => {
            setChatLoading(false);
          });
      } else {
        setChatLoading(true)
        authChat
          .post(
            `/chat`, {payload: encodeToBase64({
              message: message,
              user_highlight: selectedText ?? activeHighlight,
              documentId: fileData?.document_id,
              conversationTypeId: conversationTypeId,
              isSocraticLearning: isSocraticLearning
            })
          })
          .then((res) => {
            setShowDropDown(false);
            setShowFields(false);
            setLines(res.data.message);
            localStorage.setItem("conversation_id", JSON.stringify(res.data.conversation_id));
            setDropDownData(res.data.relatedQuestions);
            setLastChatHistory(res.data)

            scrollToBottom()
            propExpDrawerChatResponse(res)
            updateConversationDocHistory(res.data.conversation_id)
          })
          .catch((err) => {
            console.log(err);
            setLines("No Data Found");
            setShowDropDown(true);
            setShowFields(true);
          })
          .finally(() => {
            setChatLoading(false);
          });
      }
    }
  };

  //Active Consersatin
  useEffect(() => {
    if (activeHighlight) {
      const conversation_id = JSON.parse(
        localStorage.getItem("conversation_id")
      );
      if (conversation_id) {
        localStorage.removeItem(conversation_id);
      }
      if (Thread?.length > 0) {
        setThreadHistory([...threadHistory, Thread])
      }
    }
  }, [activeHighlight]);

  // Code to send summary request when document uploads
  //Get Document Summary api
  // useEffect(() => {
  //   if (fileData?.document_id) {
  //     setSummryLoading(true)
  //     authAxios
  //       .get(`/summary?documentID=${fileData?.document_id}`)
  //       .then((res) => {
  //         console.log(res);
  //         setSummeryData(res.data.generated_summary);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       }).finally(() => {
  //         setSummryLoading(false)
  //       })
  //   }

  // }, [fileData]);

  //scroll To Bottom
  useEffect(() => {
    scrollToBottom();
    setTypingKey((prevKey) => prevKey + 1);
  }, [lines]);
  const [fontScale, setFontScale] = useState(1.0);
  const [highlighterKey, setHighlighterKey] = useState(Date.now());
  //handle Zoom Out
  const handleZoomOut = () => {
    setFontScale((prevFontScale) => prevFontScale - 0.5);
    setHighlighterKey(Date.now());
  };

  //handle Zoom In
  const handleZoomIn = () => {
    setFontScale((prevFontScale) => prevFontScale + 0.5);
    setHighlighterKey(Date.now());
  };

  useEffect(() => {
    scrollToBottom();
  }, [showDropDown, lines, Thread]);

  useEffect(() => {
    if (searchHighlight) {
      handleTrackedata();
    } else {
      setThread([]);
    }

  }, [threadHistory])

  useEffect(() => {
    if (searchHighlight) {

      if (lastTeachHistory?.user_highlight) {
        const tempData = Thread
        tempData.push({
          main: lastTeachHistory.user_highlight,
          aires: lastTeachHistory.TxtResponse,
          dropDownData: lastTeachHistory.relatedQuestions,
          createDate: Date.now(),
          diagram: lastTeachHistory.mermaidJSCode ? lastTeachHistory.mermaidJSCode : "",
        })
        setThreadHistory([...threadHistory, tempData])
        setLastTeachHistory({})
      } else if (lastChatHistory?.message) {

        const tempData = Thread
        tempData.push({
          main: "",
          title: askedQuestion,
          aires: lastChatHistory.message,
          dropDownData: lastChatHistory.relatedQuestions,
          createDate: Date.now(),
          diagram: "",
        })
        setThreadHistory([...threadHistory, tempData])
        setLastChatHistory({})
      } else if (threadHistory?.length > 0) {
        handleTrackedata()
      }
    }
  }, [searchHighlight])

  //Manage Tracker

  const handleTrackedata = () => {
    let foundIndex = -1;


    for (let i = 0; i < threadHistory.length; i++) {
      const innerArray = threadHistory[i];
      const indexWithMain = innerArray.findIndex(item => item.main === searchHighlight);

      if (indexWithMain !== -1) {
        foundIndex = i;
        break;
      }
    }
    if (threadHistory?.length > 0) {

      setThread(threadHistory[foundIndex])
      const len = threadHistory[foundIndex].length
      setLines(threadHistory[foundIndex][len - 1]?.aires)
      console.log(threadHistory[foundIndex][len - 1]?.mermaidJSCode)
      setMermaidCode(threadHistory[foundIndex][len - 1]?.diagram)
      setDropDownData(threadHistory[foundIndex][len - 1]?.dropDownData)
      setShowFields(true)
      setAskedQuestion(threadHistory[foundIndex][len - 1]?.title)
      scrollToTop()
    }

  }
  const handleSearch = () => {
    setSearchHighlight('')
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        console.log('Tab is hidden');
      } else {
        setAnimateTextStop(true)
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  const handleMouseEnterEyes = () => {
    setStandAnimation(true);
    // Add your logic for onMouseEnter here
  };

  const handleMouseLeaveEyes = () => {
    setStandAnimation(false);
    // Add your logic for onMouseLeave here
  };

  return (
    <div
      className={
        isSmallScreen ? styles.first_section_small : styles.first_section
      }
    //style={{ width: isSmallScreen ? "100%" : `${position}%` }}
    >
      <PDFsection
        fileData={fileData}
        isSmallScreen={isSmallScreen}
        UpdateFileTitle={UpdateFileTitle}
        handleTechAndDiagram={handleTechAndDiagram}
        lines={lines}
        dropDownData={dropDownData}
        summeryData={summeryData}
        mermaidCode={mermaidCode}
        Thread={Thread}
        handleZoomOut={handleZoomOut}
        handleZoomIn={handleZoomIn}
        fontScale={fontScale}
        highlighterKey={highlighterKey}
        position={position}
        handleChat={handleChat}
        loading={loading}
        typingKey={typingKey}
        setSearchHighlight={setSearchHighlight}
        searchHighlight={searchHighlight}
        bgColor={bgColor}
        setBgColor={setBgColor}
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        chatLoading={chatLoading}
        askedQuestion={askedQuestion}
        TypingTextState={TypingTextState}
        setTypingTextState={setTypingTextState}
        trackerList={trackerList}
        handleOpenModal={handleOpenModal}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        modalMermaidCode={modalMermaidCode}
        handleSearch={handleSearch}
        showFields={showFields}
        summryLoading={summryLoading}
        showHighlightActions={showHighlightActions}
        propToSetExplainDrawerData={propToSetExplainDrawerData}
        propForDocLoaded={propForDocLoaded}
      />
    </div>

  );
});

export default PDFUploaded;
