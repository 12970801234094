import React, { ReactNode, forwardRef, useState } from "react";
import PropTypes from 'prop-types';
import './pdfArea.scss'
import { PdfLoader, PdfHighlighter, Highlight, AreaHighlight, Popup } from "react-pdf-highlighter";
import PDFUploaded from "../pdfUploaded/PDFUploaded";

const PdfContainer = forwardRef(({ propToSetExplainDrawerData, propForExplaination, refPdfUploaded, propExpDrawerChatResponse, propForDiagramData, showHighlightActions, propForDocLoaded, updateHighlightsHistory }, ref) => {

    // const fileUrl = "https://mrezpzstorage01.blob.core.windows.net/mrezpzdocumentstorage/docID_81_docID_68_Animal.pdf"

    // const [state, setState] = useState({
    //     highlights: []
    // })

    // const resetHash = () => {
    //     document.location.hash = "";
    // };

    // const HighlightPopup = ({ comment }) =>
    //     comment.text ? (
    //         <div className="Highlight__popup">
    //             {comment.emoji} {comment.text}
    //         </div>
    //     ) : null;

    return (
        <div className="pdf-container-wrap">
            <div className="inner-container scrollable">
                {/* Dynamic PDF Will Load Here  */}

                <PDFUploaded
                    ref={refPdfUploaded}
                    propToSetExplainDrawerData={propToSetExplainDrawerData}
                    propForExplaination={propForExplaination}
                    propExpDrawerChatResponse={propExpDrawerChatResponse}
                    propForDiagramData={propForDiagramData}
                    showHighlightActions={showHighlightActions}
                    propForDocLoaded={propForDocLoaded}
                    updateHighlightsHistory={updateHighlightsHistory}
                />

                {/* <div className="App" style={{ display: "flex", height: "100vh" }}>
                    <div
                        style={{
                            height: "100vh",
                            width: "75vw",
                            position: "relative",
                        }}
                    >
                        <PdfLoader
                            url={fileUrl}
                        >
                            {(pdfDocument) => (
                                <PdfHighlighter
                                    pdfDocument={pdfDocument}
                                    enableAreaSelection={(event) => event.altKey}
                                    onScrollChange={resetHash}
                                    highlightBackgroundColor="red"
                                    // pdfScaleValue={String(Math.pow(1.2, fontScale))}
                                    scrollRef={(scrollTo) => {
                                        this.scrollViewerTo = scrollTo;
                                        this.scrollToHighlightFromHash();
                                    }}
                                    onSelectionFinished={(
                                        position,
                                        content,
                                        hideTipAndSelection,
                                        transformSelection
                                    ) => (
                                        // console.log("Tip called")
                                        // this.addHighlight({ content, position, comment: "" },searchHighlight, setSearchHighlight);
                                        // // hideTipAndSelection();
                                        // transformSelection();
                                        // return(
                                        //     <p className="Tip__compact">akjdkjadskfdsa</p>
                                        // )
                                        <>{
                                            true &&
                                            <Tip
                                            onOpen={transformSelection}
                                            onConfirm={(comment) => {
                                                this.addHighlight({ content, position, comment });

                                                hideTipAndSelection();
                                            }}
                                        />
                                        }</>
                                        
                                    )}
                                    highlightTransform={(
                                        highlight,
                                        index,
                                        setTip,
                                        hideTip,
                                        viewportToScaled,
                                        screenshot,
                                        isScrolledTo
                                    ) => {
                                        const isTextHighlight = !Boolean(
                                            highlight.content && highlight.content.image
                                        );

                                        const component = isTextHighlight ? (
                                            <Highlight
                                                isScrolledTo={isScrolledTo}
                                                position={highlight.position}
                                                comment={highlight.comment}
                                                style={{
                                                    background: 'purple', // Change the color for the latest highlight
                                                }}
                                            />
                                        ) : (
                                            <AreaHighlight
                                                isScrolledTo={isScrolledTo}
                                                highlight={highlight}
                                                onChange={(boundingRect) => {
                                                    this.updateHighlight(
                                                        highlight.id,
                                                        { boundingRect: viewportToScaled(boundingRect) },
                                                        { image: screenshot(boundingRect) }
                                                    );
                                                }}
                                            />
                                        );

                                        return (
                                            <Popup
                                                popupContent={<HighlightPopup {...highlight} />}
                                                onMouseOver={(popupContent) =>
                                                    setTip(highlight, (highlight) => popupContent)
                                                }
                                                onMouseOut={hideTip}
                                                key={index}
                                                children={component}
                                            />
                                        );
                                    }}
                                    highlights={state.highlights}
                                />
                            )}
                        </PdfLoader>
                    </div>
                </div> */}
            </div>
        </div>

    );
});

export default PdfContainer;

PdfContainer.propTypes = {
};