import React from "react";
import "./TooltipHover.scss"
import PropTypes from 'prop-types';
import { Tooltip } from "@mui/material";

const TooltipHover = ({ children, title, arrow, open, disablePortal = true, placement, ref, leaveDelay, ...PropTypes }) => {
    return (
        <div className="tooltiphover-wrap">
            <Tooltip
                disableFocusListener
                disableTouchListener
                title={title}
                arrow={arrow}
                PopperProps={{
                    disablePortal
                }}>
                <div {...PropTypes} ref={ref}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
};

export default TooltipHover;

TooltipHover.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    arrow: PropTypes.bool,
    placement: 'top' | 'bottom' | 'top-start' | 'top-end' | 'left-start' | 'left' | 'left-end' | 'right-start' | 'right-end' | 'right' | 'bottom-start' | 'bottom-end',
    open: PropTypes.bool,
    disablePortal: PropTypes.bool,
    leaveDelay: PropTypes.number
};