import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import Header from "../header/header";
import './baseLayout.scss'
import ChatWindow from "../chatWindow/chatWindow";
import CustomDrawer from "../drawer/drawer";
import CustomTab from "../tab/tab";
import { clsx } from "clsx";
import ChatWindowLogo from '../../assets/chat-window-logo.svg'

const BaseLayout = ({ children, noRightArea, showRightChatArea = true, showHeaderActions = true, showFullHeader = true, uploadIcon = true, acitivityIcon = true, feedbackFormIcon = true, onboardingIcon = true, tabContent, tabLabel }) => {
    return (
        <div className="base-layout-wrap">
            <div
                className={clsx({
                    'left-area': true,
                    'no-right-area': noRightArea
                })}>
                {showFullHeader && <Header acitivityIcon={acitivityIcon} uploadIcon={uploadIcon} feedbackFormIcon={feedbackFormIcon} showHeaderActions={showHeaderActions} onboardingIcon={onboardingIcon} />}
                <div className="main-content">
                    {children}
                </div>
            </div>
            {showRightChatArea && (
                <div className="right-area">
                    {/* <div className="logo-area">
                        <img src={ChatWindowLogo} alt="" />
                    </div> */}
                    <CustomTab
                        chatTabs
                        items={[
                            {
                                label: "Chat",
                                content: (
                                    <ChatWindow />
                                )
                            },
                            {
                                label: tabLabel,
                                content: tabContent
                            }
                        ]}
                    />
                </div>
            )}
            <div>
                <CustomDrawer
                    open={false} />
            </div>
        </div>
    );
};

export default BaseLayout;

BaseLayout.propTypes = {
    children: PropTypes.any,
    tabLabel: PropTypes.any,
    tabContent: PropTypes.any,
    noRightArea: PropTypes.bool
};