import React, { ReactNode, useState } from "react";
import PropTypes from 'prop-types';
import HeaderLogo from '../../assets/header-logo.svg'
import ActivityIcon from '../../assets/ic-activity.svg'
import FeddbackIcon from '../../assets/ic-feedback-form.svg'
import OnboardingIcon from '../../assets/ic-ques-circle.svg'
import UploadIcon from '../../assets/upload-icon.svg'
import LogoutIcon from '../../assets/ic-logout.svg';
import './header.scss'
import ToggleSwitch from "../toggleSwitch/toggleSwitch";
import { Button, CustomDialog, FeedbackDialog, OnboardingDialog } from "../../components";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { authChat } from "../../axios";

const Discalaimer = () => {
    return <div className="dialog-disclaimer">
        <p className="dialog-text">If you wish to delete your account, please direct your request at <a className="dialog-text-link" href="mailto:team@mrezpz.ai">team@mrezpz.ai</a></p>
    </div>
}

const Header = ({ showHeaderActions = true, uploadIcon = true, acitivityIcon = true, feedbackFormIcon = true, onboardingIcon = true }) => {

    const [showConfirmLogoutPopup, setShowConfirmLogoutPopup] = useState(false)
    const [openFeedback, setOpenFeedback] = useState(false);
    const [openOnboarding, setOpenOnboarding] = useState(false);
    const [actionButtonDisabled, setActionButtonDisabled] = useState(false)

    const navigate = useNavigate()

    //============================== Function Logout =================================//
    const onClickLogout = () => {
        // localStorage.clear()
        setShowConfirmLogoutPopup(true)
    }

    //================================ confirm logout popup ===========================//
    const onClickConfirmLogoutNo = () => {
        setShowConfirmLogoutPopup(false)
    }

    const Logout = () => {
        return new Promise((resolve, reject) => {
            authChat.get(`/logout/`, {withCredentials: true}).then(() => {
                localStorage.clear()
                return resolve()
            }).catch((e) => {
                console.error(e);
                return reject();
            });
        });
    }

    const onClickConfirmLogoutYes = async () => {
        try {
            setActionButtonDisabled(true);
            await Logout();
            navigate('/login');
        } catch (error) {
            console.error('Failed to Logout :' + error);
        } finally {
            setActionButtonDisabled(false);
        }
    }

    const handleFeedbackOpen = () => {
        setOpenFeedback(true);
    }
    const handleOnboardingOpen = () => {
        setOpenOnboarding(true);
    }

    return (
        <>
        <div className="header-wrap">
            <div className="logo-area">
                <a href="/"><img src={HeaderLogo} alt="" /></a>
            </div>
            {showHeaderActions && (
                <div className="header-actions">
                    {uploadIcon &&
                        <Tooltip title="Upload" arrow placeholder="bottom">
                            <a href="/uploadFile" className="action-icon">
                                <img src={UploadIcon} alt=" " />
                            </a>
                        </Tooltip>
                    }
                    {onboardingIcon &&
                        <Tooltip title="Onboarding" arrow placeholder="bottom">
                            <a className="action-icon cp" onClick={handleOnboardingOpen}>
                                <img src={OnboardingIcon} alt=" " />
                            </a> 
                       </Tooltip>
                    }
                    {feedbackFormIcon &&
                        <Tooltip title="Feedback" arrow placeholder="bottom">
                            <a className="action-icon cp" onClick={handleFeedbackOpen}>
                                <img src={FeddbackIcon} alt=" " />
                            </a> 
                       </Tooltip>
                    }
                    {acitivityIcon &&
                        <Tooltip title="Activity" arrow placeholder="bottom">
                            <a href="/activity" className="action-icon">
                                <img src={ActivityIcon} alt=" " />
                            </a>
                        </Tooltip>
                    }
                    <Tooltip title="Logout" arrow placeholder="bottom">
                        <a href="javascript:void(0)" onClick={onClickLogout} className="action-icon">
                            <img src={LogoutIcon} alt=" " />
                        </a>
                    </Tooltip>
                </div>
            )}

            {/* Logout Popup */}
            <CustomDialog
                open={showConfirmLogoutPopup}
                onClose={() => { }}
                size={'sm'}
                dialogIcon={LogoutIcon}
                dialogTitle={'Logout'}
                dialogAction={
                    <>
                        <Button onClick={onClickConfirmLogoutNo} disabled={actionButtonDisabled} text='No' outlineBtn type={'submit'} />
                        <Button onClick={onClickConfirmLogoutYes} disabled={actionButtonDisabled} text='Yes' primary type={'submit'} />
                    </>
                }
                dialogDisclaimer={<Discalaimer/>}
            >
                <p className="text-body1 text-center m-0">Are you sure you want to logout?</p>
            </CustomDialog>

        </div>
        {openFeedback && <FeedbackDialog openFeeback={openFeedback} setOpenFeedback={setOpenFeedback}/>}
        {openOnboarding && <OnboardingDialog openOnboarding={openOnboarding} setOpenOnboarding={setOpenOnboarding} />}
        </>
    );
};

export default Header;

Header.propTypes = {
    showConfirmLogout: PropTypes.bool
};