import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import './chatWindow.scss'

const UserResponse = ({ userResponse }) => {
    return (
        <div className="chat-bubble-wrap msg-sent">
            <div className="chat-bubble">
                <p className="text-body2 text-muted m-0">{userResponse}</p>
            </div>
        </div>
    );
};

export default UserResponse;

UserResponse.propTypes = {
    userResponse: PropTypes.any
};