import React, { ReactNode, useState } from "react";
import PropTypes from 'prop-types';
import './toggleSwitch.scss'
import { useLocation, useNavigate } from "react-router-dom";

const ToggleSwitch = ({}) => {
    const location = useLocation()

    const navigate = useNavigate()

    const onClickQuiz = () => {

        // console.log("Route", loaction.pathname)
        navigate("/main/quiz")
    }

    const onClickLearning = () => {
        // console.log("Route", loaction.pathname)
        navigate("/main/learning")
    }

    return (
        <div class="switches-container">
            <input onChange={onClickLearning}  type="radio" id="switchLearning" name="switchPlan" value="Learning" checked={location.pathname === '/main/learning'} />
            <input onChange={onClickQuiz} type="radio" id="switchQuiz" name="switchPlan" value="Quiz" checked={location.pathname === '/main/quiz'} />
            <label for="switchLearning">Learning</label>
            <label for="switchQuiz">Quiz</label>
            <div class="switch-wrapper">
                <div class="switch">
                    <div>Learning</div>
                    <div>Quiz</div>
                </div>
            </div>
        </div>
    );
};

export default ToggleSwitch;

ToggleSwitch.propTypes = {
};