import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import EditIcon from '../../assets/ic-edit.svg';
import './quizHeader.scss'
import Button from "../button/button";


const QuizHeader = ({ selectedText, quizCount, questionCount, timer, editAction, toggleAction, quizScore, timeTaken, onClickEdit }) => {
    return (
        <div className="quiz-header-wrap">
            <div className="title-head">
                <h3 className="text-h3 fw-bold m-0">Quiz</h3>
                <div className="quiz-head-action">
                    {/* {editAction &&
                        <div className="edit-action">
                            <Button type={'submit'} onClick={onClickEdit} outlineBtn iconOnly={EditIcon} />
                        </div>
                    } */}
                    {toggleAction &&
                        <div className="toggle-action">
                            {toggleAction}
                        </div>
                    }
                </div>
            </div>
            <div className="selected-text-area">
                <h5 className="text-h5 m-0 text-muted">{selectedText}</h5>
            </div>
            <div className="quiz-counter">
                <div className="item-left">
                    {/* <div className="item">
                        <p className="body2 text-muted m-0 fw-bold">Quiz #</p>
                        <p className="body2 text-muted m-0 fw-bold">{quizCount}</p>
                    </div> */}
                    <div className="item">
                        <p className="body2 text-muted m-0 fw-bold">Questions :</p>
                        <p className="body2 text-muted m-0">{questionCount}</p>
                    </div>
                    {timeTaken &&
                        <div className="item">
                            <p className="body2 text-muted m-0 fw-bold">Time :</p>
                            <p className="body2 text-muted m-0">{timeTaken}</p>
                        </div>
                    }
                </div>
                <div className="item-right">
                    {timer &&
                        <div className="item">
                            <p className="body2 text-muted m-0 fw-bold">Time Left :</p>
                            <p className="body2 text-muted m-0">{timer}</p>
                        </div>
                    }
                    {quizScore &&
                       <div className="item">
                            <p className="body2 text-muted m-0 fw-bold">You Scored :</p>
                            <p className="body2 text-muted m-0">{quizScore}</p>
                        </div> 
                    }
                </div>
            </div>
        </div>
    );
};

export default QuizHeader;

QuizHeader.propTypes = {
    selectedText: PropTypes.string,
    quizCount: PropTypes.number,
    questionCount: PropTypes.number,
    timer: PropTypes.any,
    editAction: PropTypes.bool,
    toggleAction: PropTypes.bool,
    quizScore: PropTypes.any,
    timeTaken: PropTypes.any,
    onClickEdit: PropTypes.any
};