import React from 'react'
import PropTypes from 'prop-types';
import { Container, Grid, Box } from '@mui/material';
import './section-title.scss';

const SectionTitle = ({ title, subTitle, secImage }) => {
  return (
    <section className='section-title-wrap'>
        {secImage &&
            <Box className='sec-img' textAlign={'center'}>
                <img src={secImage} alt='' className='mw-100' />
            </Box>
        }
        <Box className='sec-title text-center'>
            <h1 className='text-h1 title mt-0 mb-0 text-dark'>{title}</h1>
            <h3 className='subtitle text-h3 mb-0 text-muted'>{subTitle}</h3>
        </Box>
    </section>
  )
}

export default SectionTitle;

SectionTitle.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    secImage: PropTypes.string
};