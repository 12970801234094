import React, { useState } from 'react'
import OnboardingDialogIcon from '../../assets/ic-ques-circle-big.svg'
import onboardingImage01 from '../../assets/img-onboard-ht.svg'
import onboardingImage02 from '../../assets/img-oboard-dc.svg'
import onboardingImage03 from '../../assets/img-onboard-sl.svg'
import onboardingImage04 from '../../assets/img-onboard-tabs.svg'
import onboardingImage05 from '../../assets/img-onboard-activity.svg'
import onboardingImage06 from '../../assets/img-onboard-quiz.svg'
import onboardingImage07 from '../../assets/img-onboard-upload.svg'
import Button from '../button/button'
import CustomDialog from '../dialog/dialog'
import "./onboarding-dialog.scss"
import CustomTab from '../tab/tab'

const OnboardingDialog = ({openOnboarding, setOpenOnboarding}) => {
    const items = [
        {
            label: "Upload Document",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage07} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        Streamline your learning with easy document uploads—lecture slides and readings in PDFs, PowerPoints, and Word files. Centralize and access effortlessly for an organized study experience.
                    </p>
                </div>
            )
        },
        {
            label: "Highlight Text",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage01} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        Highlight text from your uploaded class materials to get detailed explanations, summaries and diagrams made by me. I’m here to connect the dots and help you understand everything on your document.
                    </p>
                </div>
            )
        },
        {
            label: "Think Tank",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage02} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        This is a focused zone where you can ask follow-up questions about specific highlights you select. You will receive suggested questions to ask or topics to explore that allow you to increase your understanding holistically. 
                    </p>
                </div>
            )
        },
        {
            label: "Socratic Chat",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage03} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        In this space, we can discuss your uploaded document or related academic topics. You can also turn on Socratic Learning mode that gently guides you to discover the answer yourself.
                    </p>
                </div>
            )
        },
        {
            label: "Quiz Me",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage06} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        Prepare for your upcoming class exams by engaging in targeted practice through personalized quizzes tailored to your study needs. This interactive feature allows you to assess your understanding of the material.
                    </p>
                </div>
            )
        },
        {
            label: "Highlight Tracking",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage04} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        This tab serves as a history for all your direct interactions with the uploaded document. Click on a text bubble to revisit or continue your highlight-specific discussions.
                    </p>
                </div>
            )
        },
        {
            label: "Activity Page",
            content: (
                <div className='onboarding-content'>
                    <div className='img-wrap'>
                        <img src={onboardingImage05} alt='' />
                    </div>
                    <p className='text-body2 fw-medium m-0'>
                        Here, you can access and revisit any of the documents you have uploaded. Pick up from where you left off or look through previous sessions to refresh before assignments or exams.
                    </p>
                </div>
            )
        },
        
        
     ];

    const [tabIndex, setTabIndex] = useState('0');
    const handleCancelOnboarding = () => {
        setOpenOnboarding(false);
    }

    const handleNext = () => {
        setTabIndex((Number(tabIndex) + 1).toString());
    }
    

    const handleBack = () => {
        setTabIndex((Number(tabIndex) - 1).toString());
    }

  return (
    <div className='onboarding-dialog-wrap'>
       <CustomDialog 
        size='md' 
        open={openOnboarding}
        dialogIcon={OnboardingDialogIcon}
        dialogTitle={'Welcome Aboard'}
        onClose={handleCancelOnboarding}
        dialogAction={
            <>
                {tabIndex > '0' && <Button outlineBtn type={'button'} text={'Back'} onClick={handleBack} />}
                {tabIndex < '6' && <Button primary type={'button'} text={'Next'} onClick={handleNext} />}
                {tabIndex === '6' && <Button primary type={'button'} text={'Done'} onClick={handleCancelOnboarding} />}
            </>
        }
       >
        <div className='onboarding-tabs-wrap'>
            <CustomTab
             orientation={'vertical'}
             items={items}
             tabIndex={tabIndex}
             setTabIndex={setTabIndex}
             />
        </div>
       </CustomDialog>
    </div>
  )
}

export default OnboardingDialog;
