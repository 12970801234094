import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { ButtonType } from '../../constants/enums';

export const PdfViewer = ({ fileUrl, setExplainDrawerValues, handleTechAndDiagram }) => {
    const pdfViewer = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (!pdfViewer.current || !fileUrl) return;

        // Check if the fileUrl is a direct PDF file URL
        const isDirectPdfUrl = /\.pdf$/i.test(fileUrl);

        if (!isDirectPdfUrl) {
            // Extract the base64 data from the data URL
            const byteCharacters = atob(fileUrl);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const fileBlob = new Blob([byteArray], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(fileBlob);

            // Initialize WebViewer with the Blob URL
            initializeWebViewer(blobUrl);
        } else {
            // Initialize WebViewer with the file URL directly
            initializeWebViewer(fileUrl);
        }
        
    }, [fileUrl, location]);

    const initializeWebViewer = (fileUrl) => {
        WebViewer(
            {
                path: '/webviewer/lib',
                initialDoc: fileUrl,
                licenseKey: 'Wjl9r6GrXHvClU9tNnTR',
            },
            pdfViewer.current,
        ).then((instance) => {
            const { Core, UI } = instance;
        
            // Removing extra buttons from webviewer header
            UI.setHeaderItems(header => {
                const items = header.getItems().filter(item => item.type === 'customElement' && item.dataElement === 'zoomOverlayButton');
                header.update(items);
            });
            UI.disableElements(['leftPanel', 'leftPanelButton', "contextMenuPopup"]);

            // Setting default zoom to screen fit
            instance.setFitMode(instance.FitMode.FitWidth)

            // Adding buttons and relevant action for text-selection popup menu
            const textPopupItems = UI.textPopup.getItems();
            const filteredTextPopupItems = textPopupItems.filter(item => item.dataElement !== 'copyTextButton');
            let selectedText = {content:{text:''}};
            [{
                type: 'actionButton',
                label: 'Explain',
                onClick: () => {
                    selectedText.content.text = Core.documentViewer.getSelectedText();
                    setExplainDrawerValues(selectedText, ButtonType.Explain)
                    handleTechAndDiagram('teach', selectedText, ButtonType.Explain)
                    localStorage.setItem('HighlightType', JSON.stringify(ButtonType.Explain))
                },
            }, {
                type: 'actionButton',
                label: 'Summarize',
                onClick: () => {
                    selectedText.content.text = Core.documentViewer.getSelectedText();
                    setExplainDrawerValues(selectedText, ButtonType.Summarize)
                    handleTechAndDiagram('teach', selectedText, ButtonType.Summarize)
                    localStorage.setItem('HighlightType', JSON.stringify(ButtonType.Summarize))
                },
            }, {
                type: 'actionButton',
                label: 'Diagram',
                onClick: () => {
                    selectedText.content.text = Core.documentViewer.getSelectedText();
                    setExplainDrawerValues(selectedText, ButtonType.Diagram)
                    handleTechAndDiagram('visualize', selectedText, ButtonType.Diagram)
                    localStorage.setItem('HighlightType', JSON.stringify(ButtonType.Diagram))
                },
            }].map(button => filteredTextPopupItems.push(button));
            UI.textPopup.update(filteredTextPopupItems);

            const iframeDoc = UI.iframeWindow.document;
            // Overwriting zoom-panel styles
            const header = iframeDoc.querySelector("[data-element='header']");
            if (header) {
                header.style.backgroundColor = '#fff';
                header.style.height = '0px';
                const headerItems = header.querySelector('.HeaderItems');
                if (headerItems) {
                    headerItems.style.top = '1.7rem';
                    headerItems.style.right = '1rem';
                }
                const headerBorder = header.querySelector('.view-header-border');
                if (headerBorder) headerBorder.remove();
            }

            const content = iframeDoc.querySelector(".content");
            if (content) {
                content.style.backgroundColor = '#fff';
            }

            const zoomOverlay = iframeDoc.querySelector(".zoom-overlay");
            if (zoomOverlay) { 
                const zoomDropdown = zoomOverlay.querySelector(".OverlayContainer");
                if (zoomDropdown) zoomDropdown.remove();
                zoomOverlay.style.backgroundColor = 'transparent';
            }

            const zoomButtonsOverlay = iframeDoc.querySelector("[data-element='zoomOverlayButton']");
            if (zoomButtonsOverlay) zoomButtonsOverlay.style.marginLeft = 'auto';

            const zoomOutButton = iframeDoc.querySelector("[data-element='zoomOutButton']");
            if (zoomOutButton){
                zoomOutButton.style.backgroundColor = '#fff';
                zoomOutButton.style.width = '35px';
                zoomOutButton.style.height = '35px';
                zoomOutButton.style.border = '1px solid #A6ACB1';
            }
            const zoomInButton = iframeDoc.querySelector("[data-element='zoomInButton']");
            if (zoomInButton){
                zoomInButton.style.backgroundColor = '#fff';
                zoomInButton.style.width = '35px';
                zoomInButton.style.height = '35px';
                zoomInButton.style.border = '1px solid #A6ACB1';
                zoomInButton.style.marginLeft = '3px';
            }
            
            const zoomButtonsIcon = iframeDoc.querySelectorAll(".Icon");
            if (zoomButtonsIcon.length) {
                zoomButtonsIcon.forEach(icon => {
                    icon.style.color = '#000';
                });
            }
            
            // Overwriting text-selection popup menu styles
            const textPopup = iframeDoc.querySelector("[data-element='textPopup']");
            if (textPopup) {
                // Disable Popup menu when route is quiz
                // if (location.pathname.includes('quiz')) {
                // } else {
                    textPopup.style.width = '460px';
                    textPopup.style.height = '76px';
                    textPopup.style.boxShadow = '0px 2px 16px 7px rgb(49 50 61 / 39%)';
                    textPopup.style.backgroundColor = '#fff';
                    textPopup.style.gap = '14px !important';
                    // Overwriting text-selection popup menu stylesR
                    const buttonElement = textPopup.querySelectorAll('.Button.ActionButton');
                    if (buttonElement.length) {
                        buttonElement.forEach(button => {
                            button.style.backgroundColor = '#000';
                            button.style.color = '#fff';
                            button.style.borderRadius = '7px';
                            button.style.cursor = 'pointer';
                            button.style.fontSize = '14px';
                            button.style.fontWeight = 'bolder';
                            button.style.letterSpacing = '-.28px';
                            button.style.margin = '0 5px';
                            button.style.minWidth = '124px';
                            button.style.padding = '10px';
                            button.style.textTransform = 'none';
                            button.classList.add('text-popup-btns');
                        });
                    }
                // }
            }
        });
    };

    return <section className='webviewer' ref={pdfViewer} style={{ height: '100%' }}></section>
}