import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import ChatBotIcon from '../../assets/chat-bot-bubble-icon.svg'
import './chatWindow.scss'

const FormattedAIResponse = ({ pointsArray, chatBotIcon }) => {
    return (
        <div className="chat-bubble-wrap msg-received">
            {chatBotIcon &&
                <div className="chat-bot-icon">
                    <img className="chat-bot-icon-magic" src={ChatBotIcon} alt="" />
                </div>
            }
            <div className="chat-bubble">
                <div className="text-body2 text-muted m-0">
                    {pointsArray.map((point, index) => (
                        <p key={index}>{point}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FormattedAIResponse;

FormattedAIResponse.propTypes = {
    pointsArray: PropTypes.array,
    chatBotIcon: PropTypes.bool
};