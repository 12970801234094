import React from 'react'
import { Container, Grid, Box } from '@mui/material';
import SocialTiktok from '../../assets/social-tiktok.svg'
import SocialInsta from '../../assets/social-insta.svg'
import SocialLinkedin from '../../assets/social-Linkedin.svg'
import './home-footer.scss';

const HomeFooter = ({ }) => {
  return (
    <section className='home-footer-wrap'>
        <Container>
            <Grid className='grid-container' container item spacing={{xs:3, md:3}} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={4} lg={4} order={{xs:2, md:1}} className='grid-item-first'>
                    <Box className='footer-text' textAlign={{xs: 'center', md: 'left'}}>
                        <p className='text-body2 subtitle text-white mt-0 mb-0'>&copy; 2024 Mr. EzPz  <span>|</span>  All Rights Reserved</p>
                    </Box>  
                </Grid>
                <Grid item xs={12} md={4} lg={4} alignItems={'center'} order={{xs:1, md:2}} className='grid-item-sec'>
                    <Box className="social-links" display={'flex'} alignItems={'center'} gap={'11px'} justifyContent={{xs: 'center', md: 'center'}}>
                        <a href="https://www.tiktok.com/@mr.ezpz?_t=8jUs0ZQcxTT&_r=1" className='item' style={{display: 'none'}}>
                            <img src={SocialTiktok} alt='' />
                        </a>
                        <a href="https://www.instagram.com/mrezpz.ai?igsh=NXE5bHBoMHBmOGdp&utm_source=qr" className='item'>
                            <img src={SocialInsta} alt='' />
                        </a>
                        <a href="https://www.linkedin.com/company/easypz-ai/" className='item'>
                            <img src={SocialLinkedin} alt='' />
                        </a>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={4} order={{xs:3, md:3}} className='grid-item-third'>
                    <Box className='footer-text' textAlign={{xs: 'center', md: 'right'}}>
                        <p className='text-body2 subtitle text-white mt-0 mb-0'>
                            <a style={{cursor: 'pointer'}} onClick={() => window.open('/terms-and-conditions','_blank')}>Terms & Conditions </a>
                                <span>|</span>  
                            <a style={{cursor: 'pointer'}} onClick={() => window.open('/privacy-policy','_blank')}> Privacy Policy</a>
                        </p>
                    </Box>  
                </Grid>
            </Grid> 
        </Container>
    </section>
  )
}

export default HomeFooter;
