import * as React from "react";
import { render } from "react-dom";
import ReactDOM from 'react-dom/client'
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from "./App";

const rootElement = document.getElementById("root");
render(<GoogleOAuthProvider clientId="158071293888-ql223j2s2u1hh1fuq1v30rkam0c5segh.apps.googleusercontent.com"><App/></GoogleOAuthProvider>, rootElement);

if (process.env.NODE_ENV === 'production' && !window.location.href.includes('dev.mrezpz') && !window.location.href.includes('localhost')) {
    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=G-2J0HDWX0N0";
    document.head.appendChild(scriptTag);
  
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
  
      gtag('config', 'G-2J0HDWX0N0');
    `;
    document.head.appendChild(inlineScript);
  }

//====================Setting GoogleOAuthProvider ClientID globally=====================//
// ReactDOM.createRoot(document.getElementById('root')).render(
//     <React.StrictMode>
//         <GoogleOAuthProvider clientId="158071293888-ql223j2s2u1hh1fuq1v30rkam0c5segh.apps.googleusercontent.com">
//             <App/>
//         </GoogleOAuthProvider>
//     </React.StrictMode>
// )