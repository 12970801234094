import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import GuidlineIcon from '../../assets/ic-bulb.svg';
import TickIcon from '../../assets/ic-tick-green.svg';
import CrossIcon from '../../assets/ic-cross-red.svg';
import './quizQuestions.scss'
import { clsx } from "clsx";
import Radio from "../radio/radio";


const QuizQuestion = ({ questionItem }) => {
    return (
        <div className="quiz-questions-wrap">
            {(questionItem ??[]).map(({ questionCount, question, questionGuidline, correct, wrong, questionOptions, answer, explaination, showExplaination }, index) => (
                <div className="ques-item" key={index}>
                    <div className="question">
                        <div className="ques-left">
                            <div className="ques-count">
                            <h3 className="text-h3 fw-bold m-0">{questionCount}.</h3> 
                            </div>
                            <div className="question-text">
                                <p className="text-body2 fw-bold m-0">{question}</p>
                            </div>
                        </div>
                        <div className="ques-actions">
                            {questionGuidline && 
                                <a href="javascript:void(0)" onClick={questionGuidline}  className="m-0">
                                    <img src={GuidlineIcon} alt="Guidline" />
                                </a>
                            }
                            {correct &&
                                <div className="ques-status-icon">
                                    <img src={TickIcon} alt="Correct" />
                                </div>
                            }
                            {wrong &&
                                <div className="ques-status-icon">
                                    <img src={CrossIcon} alt="Wrong" />
                                </div>
                            }
                        </div>
                    </div>
                    <div className="question-options">
                        <ul className="options-list">
                            {(questionOptions ??[]).map(({ option, optionName,  optionValue, onOptionChange, checked, readonly}, index) => (
                                <li key={index}>
                                    <Radio
                                        label={option} name={optionName} value={optionValue} onChange={onOptionChange} checked={checked} readOnly={readonly} disabled={readonly}
                                    />
                                </li>
                            ))}
                        </ul>
                        {wrong &&
                            <div className="answer">
                                <p className="text-body2 fw-bold m-0 text-danger">
                                    Answer : 
                                </p>
                                <p className="text-body2 m-0 text-muted">{answer}</p>
                            </div>
                        }
                        {showExplaination &&
                            <div className="explaination">
                                <p 
                                 className={clsx({
                                    'text-body2 fw-bold m-0' : true,
                                    'text-danger': wrong,
                                    'text-success': correct
                                  })}
                                >
                                    Explanation : 
                                </p>
                                <p className="text-body2 m-0 text-muted">{explaination}</p>
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
    );
};

export default QuizQuestion;

QuizQuestion.propTypes = {
    questionItem: PropTypes.array
};