import React, { ReactNode, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import StyleIcon from "../../assets/ic-style.svg";
import LengthIcon from "../../assets/ic-length.svg";
import CopyIcon from "../../assets/ic-copy.svg";
import RegenrateIcon from "../../assets/ic-regenrate.svg";
import DropIcon from "../../assets/ic-arrow-up.svg";
import EnlargeIcon from "../../assets/ic-enlarge.svg";
import DownloadIcon from "../../assets/ic-download.svg";
import DropDownIcon from "../../assets/ic-drop.svg"
import './explainContent.scss'
import Pager from "../pagination/pagination";
import { clsx } from "clsx";
import { RegenrateOptionType } from "../../constants/enums";
import Loader from "../loader/loader";


const ExplainContent = ({ title, contentBrief, contentType, selctedText, actionArea, style, length, copy, regenrate, download, enlarge, onClickCopy, getOptionIdFromChild, pager, contentArray, showContentLoader = false, updateObjExplanation, mermaidJsCode, openDiagramDialog }) => {

    const [showRegenerateDropdown, setShowRegenerateDropdown] = useState(false)

    //=================================== Pager States ===================================//
    const [totalCount, setTotalCount] = useState(contentArray.length)
    const [currentPage, setCurrentPage] = useState(contentArray.length - 1)
    const [prevDisabled, setPrevDisabled] = useState(false)
    const [nextDisabled, setNextDisabled] = useState(false)

    //############################## Use Effects ###############################//
    useEffect(() => {
        setTotalCount(contentArray.length)
        setNextDisabled(currentPage === contentArray.length)
        setCurrentPage(contentArray.length)
    }, [contentArray])

    useEffect(() => {
        setNextDisabled(currentPage === contentArray.length)
        setPrevDisabled(currentPage === 1)
        updateObjExplanation(contentArray[currentPage - 1])
    }, [currentPage])

    const onClickRegenerateDropdown = () => {
        setShowRegenerateDropdown(!showRegenerateDropdown)
    }

    const handleOnClickRegenerateOption = (optionId) => {
        // console.log(optionId)
        getOptionIdFromChild(optionId)
        setShowRegenerateDropdown(false)
    }

    const onPreviousClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            setNextDisabled(false)
        }

    }

    const onNextClick = () => {
        if (currentPage < contentArray.length) {
            setCurrentPage(currentPage + 1)
            setPrevDisabled(false)
        }
    }

    const formatContent = (strContent) => {
        if (strContent) {
            if (strContent.includes("\n")) {
                const pointsArray = strContent.split('\n').filter(point => point.trim() !== '')

                // Trim each substring until an alphabet or a number is encountered
                // const trimmedArray = pointsArray.map(point => point.replace(/^[^\w\d]+/, ''));
                return (
                    pointsArray.map((point, index) => (
                        <p key={index} className="text-body1 text-grey-light-a mt-6 fw-normal" >{point}</p>
                    ))
                )
            } else {
                return <p className="text-body1 text-grey-light-a mt-6 fw-normal" >{strContent}</p>
            }
        }
    }

    return (
        <div className="content-container">
            <div className="title-area">
                <h4 className="text-h4 fw-bold m-0 mb-6">{title}</h4>
                <p className="text-body1 text-grey m-0 selected-text fw-normal">{selctedText}</p>
            </div>
            <div className="content-brief">
                <h4 className="text-h4 fw-bold m-0">{contentType}</h4>
                {contentBrief && contentBrief}
                {(showContentLoader || (contentArray.length === 0 && !contentBrief)) && <Loader />}
                {!showContentLoader && formatContent(contentArray[currentPage - 1])}
                {((contentArray.length > 1) && !showContentLoader) &&
                    <Pager totalCount={totalCount} currentPage={currentPage} prevDisabled={prevDisabled} nextDisabled={nextDisabled} onPreviousClick={onPreviousClick} onNextClick={onNextClick} />
                }
            </div>
            {actionArea &&
                <div className="action-area">
                    <div className="action-left">
                        {style &&
                            <div className="action-dropdown open">
                                <a href="javascript:void(0)" className="action-btn btn-style">
                                    <img src={StyleIcon} alt="" />
                                    <span className="btn-text text-body2">Style</span>
                                </a>
                                <ul className="action-list">
                                    <li><a href="javascript:void(0)" className="text-small text-muted m-0">Paragraph</a></li>
                                    <li><a href="javascript:void(0)" className="text-small text-muted m-0">Bullet Points</a></li>
                                </ul>
                            </div>
                        }
                        {length &&
                            <div className="action-dropdown">
                                <a href="javascript:void(0)" className="action-btn btn-length">
                                    <img src={LengthIcon} alt="" />
                                    <span className="btn-text text-body2">Length</span>
                                </a>
                                <ul className="action-list">
                                    <li><a href="javascript:void(0)" className="text-small text-muted m-0">Shorter</a></li>
                                    <li><a href="javascript:void(0)" className="text-small text-muted m-0">Longer</a></li>
                                </ul>
                            </div>
                        }
                    </div>
                    <div className="action-right">
                        {enlarge && mermaidJsCode &&
                            <a href="javascript:void(0)" className="action-btn" onClick={() => openDiagramDialog()}>
                                <img src={EnlargeIcon} alt="" />
                                <span className="btn-text text-body2">Enlarge</span>
                            </a>
                        }
                        {download &&
                            <a href="javascript:void(0)" className="action-btn">
                                <img src={DownloadIcon} alt="" />
                                <span className="btn-text text-body2">Download</span>
                            </a>
                        }
                        {regenrate &&
                            <div
                                className={clsx({
                                    'action-dropdown': true,
                                    'open': showRegenerateDropdown
                                })}
                            >
                                <a onClick={onClickRegenerateDropdown} href="javascript:void(0)" className="action-btn btn-regenrate">
                                    <img src={RegenrateIcon} alt="" />
                                    <span className="btn-text text-body2">Regenerate</span>
                                    {
                                        showRegenerateDropdown &&
                                        <img className="drop-icon" src={DropIcon} alt="" />
                                    }
                                    {
                                        !showRegenerateDropdown && <img className="drop-icon" src={DropDownIcon} alt="" />
                                    }
                                </a>
                                <ul className="action-list">
                                    <li><a onClick={() => { handleOnClickRegenerateOption(RegenrateOptionType.Paragraph) }} href="javascript:void(0)" className="text-small text-muted m-0">Paragraph</a></li>
                                    <li><a onClick={() => { handleOnClickRegenerateOption(RegenrateOptionType.BulletPoints) }} href="javascript:void(0)" className="text-small text-muted m-0">Bullet Points</a></li>
                                    <li><a onClick={() => { handleOnClickRegenerateOption(RegenrateOptionType.Longer) }} href="javascript:void(0)" className="text-small text-muted m-0">Longer</a></li>
                                    <li><a onClick={() => { handleOnClickRegenerateOption(RegenrateOptionType.Shorter) }} href="javascript:void(0)" className="text-small text-muted m-0">Shorter</a></li>
                                </ul>
                            </div>
                        }
                        {copy &&
                            <a href="javascript:void(0)" onClick={onClickCopy} className="action-btn">
                                <img src={CopyIcon} alt="" />
                                <span className="btn-text text-body2">Copy</span>
                            </a>
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default ExplainContent;

ExplainContent.propTypes = {
    title: PropTypes.string,
    selctedText: PropTypes.string,
    contentType: PropTypes.string,
    contentBrief: PropTypes.any,
    actionArea: PropTypes.any,
    style: PropTypes.bool,
    length: PropTypes.bool,
    copy: PropTypes.bool,
    regenrate: PropTypes.bool,
    download: PropTypes.bool,
    enlarge: PropTypes.bool,
    pager: PropTypes.bool
};