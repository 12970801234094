import React, { Component, createRef } from "react";
import styles from "../pdfUploaded/PDFUploaded.module.css";
import mobilestyles from "../pdfMobile/PDFMobile.module.css";
import { RotatingLines } from "react-loader-spinner";
import '../../styles/components/pdf-select-text-action.scss'
import { PdfViewer} from "../pdfViewer/PdfViewer";
import { getDocData } from "../../methods/commonMethods";

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const PRIMARY_PDF_URL =
  "https://pdfezypzy.s3.amazonaws.com/media/documents/Document_Service.pdf";
const SECONDARY_PDF_URL = "https://arxiv.org/pdf/1604.02480.pdf";

const searchParams = new URLSearchParams(document.location.search);

const initialUrl = searchParams.get("url") || PRIMARY_PDF_URL;


class PDFsection extends Component {
  constructor(props) {
    super(props);
    this.addHighlight = this.addHighlight.bind(this);
    this.pdfHighlighterRef = createRef();
    this.propToSetExplainDrawerData = {
      isExplainDrawerVisible: false,
      selectedText: ''
    }
    this.propForDocLoaded = false
    this.state = {
      url: initialUrl,
      highlights: [],
      showSummary: false,
      anchorEl: null,
      bottom: false,
      bottom2: false,
      highlighterKey: Date.now(),
      showTeachText: false,
      showDiagramText: false,
      showTrackerText: false,
      anchorEl2: null,
      trackerView: false,
      searchKey: "",
      showHighlightActions: true,
      pdfScale: 1,
      isDownloadingPDF: false,
      percentCompleted: 0
    };


  }

  setExplainDrawerValues = (objSelectedText, buttonType) => {
    // console.log("objSelectedText", objSelectedText)
    this.props.propToSetExplainDrawerData({
      isDrawerVisible: true,
      selectedText: objSelectedText.content.text
    }, buttonType)
    // console.log("PDFSectionDrawerValues", this.propToSetExplainDrawerData)
  }


  //Mobile Screen Drawer Function

  toggleDrawer1 = (anchor, open, type, handleDiagram, searchHighlight, handleSearch) => (event) => {
    console.log(anchor, open);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (type === "teach" || type === "diagram") {
      this.setState({ ...this.state, trackerView: false, [anchor]: open })
      handleDiagram(type, searchHighlight === "" ? this.state.highlights.length > 0 ? this.state.highlights[0] : "no data" : searchHighlight)
    } else if (type === "tracker") {
      this.setState({ ...this.state, trackerView: true, [anchor]: open })
      if (handleSearch) {

        handleSearch()
      }
    } else {
      this.setState({ ...this.state, trackerView: false, [anchor]: open })

    }
  };
  //Mobile Screen Drawer2 Function

  toggleDrawer2 = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ ...this.state, [anchor]: open });
  };
  handleMouseEnterTeach = () => {
    this.setState({ showTeachText: true });
  };

  handleMouseLeaveTaech = () => {
    this.setState({ showTeachText: false });
  };

  handleMouseEnterDiagram = () => {
    this.setState({ showDiagramText: true });
  };

  handleMouseLeaveDiagram = () => {
    this.setState({ showDiagramText: false });
  };
  handleMouseEnterTracker = () => {
    this.setState({ showTrackerText: true });
  };

  handleMouseLeaveTracker = () => {
    this.setState({ showTrackerText: false });
  };

  componentDidUpdate(prevProps, prevState) {
    // Log the state after a state or prop update
    console.log("Current state after update:", this.state.highlights);
  }

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };
  handleToggleSummary = () => {
    this.setState((prevState) => ({
      showSummary: !prevState.showSummary,
    }));
  };

  toggleDocument = () => {
    const newUrl =
      this.state.url === PRIMARY_PDF_URL ? SECONDARY_PDF_URL : PRIMARY_PDF_URL;

    this.setState({
      url: newUrl,
      highlights: [],
    });
  };

  scrollViewerTo = (highlight) => { };

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.progressInterval);
  }

  componentDidMount() {
    console.log(this.statefontScale)
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );

    //this.checkPdfDownload()

    // Code for updating progress bar
    // Simulate progress with a setInterval
    this.progressInterval = setInterval(() => {
      // Increase the progress by a certain value (you can adjust this)
      const newProgress = this.state.percentCompleted;

      // Update the state with the new progress
      this.setState({ percentCompleted: newProgress });

      // If the progress reaches 100%, clear the interval
      if (newProgress >= 100) {
        clearInterval(this.progressInterval);
        this.props.propForDocLoaded(true)
      }
    }, 1000); // Adjust the interval time (in milliseconds) as needed
  }

  async checkPdfDownload() {
    // console.log("download checking")

    let docdata = await getDocData()
    if (docdata) {
      docdata = JSON.parse(docdata)

      this.setState({
        isDownloadingPDF: true
      })

      const fileUrl = docdata.documentURL
      fetch(fileUrl)
        .then((response) => {
          const contentLength = response.headers.get('content-length')
          const total = contentLength ? parseInt(contentLength, 10) : 0

          const reader = response.body.getReader();
          let received = 0;

          function read() {
            return reader.read().then(({ done, value }) => {
              if (done) {
                // File download completed
                this.setState({
                  isDownloadingPDF: false
                })
                // localStorage.setItem("isDocLoaded", true)
                // console.log("download completed")
                return;
              }

              received += value.length;
              this.state.percentCompleted = Math.round((received * 100) / total);
              // console.log(this.state.percentCompleted);
              // Update your progress bar or perform other actions based on the progress

              return read();
            });
          }
          read = read.bind(this)
          read()
        })
    }
  }

  updateHash = (highlight, setSearchHighlight) => {
    this.setState({
      highlights: [{ ...highlight }],
    });
    document.location.hash = `highlight-${highlight.id}`;
    setSearchHighlight(highlight.content.text);
  };
  getHighlightById(id) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.id === id);
  }

  addHighlight(highlight, searchHighlight, setSearchHighlight) {
    // const { highlights } = this.state;
    if (searchHighlight) {
      setSearchHighlight("")
    }
    this.setState({
      highlights: [{ ...highlight, id: getNextId() }],
    });

  }

  handleClick = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl2: null });
  };

  updateHighlight(highlightId, position, content) {
    // console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
            id,
            position: { ...originalPosition, ...position },
            content: { ...originalContent, ...content },
            ...rest,
          }
          : h;
      }),
    });
  }

  // componentDidMount = () => {
  //   let pdfScaleFromLS = localStorage.getItem("pdfScale")
  //   if(pdfScaleFromLS) {
  //     this.setState((prevState) => ({
  //       pdfScale: pdfScaleFromLS
  //     }))
  //   }
  // }

  onClickZoomIn = () => {
    if (this.state.pdfScale <= 1.5) {
      window.PdfViewer.viewer.currentScaleValue = this.state.pdfScale + 0.05
      this.setState((prevState) => ({
        pdfScale: prevState.pdfScale + 0.05
      }))
      // console.log("scale", this.state.pdfScale)
      // localStorage.setItem("pdfScale", window.PdfViewer.viewer.currentScaleValue)
    }
  }

  onClickZoomOut = () => {
    if (this.state.pdfScale >= 0.5) {
      window.PdfViewer.viewer.currentScaleValue = this.state.pdfScale - 0.05
      this.setState((prevState) => ({
        pdfScale: prevState.pdfScale - 0.05
      }))
      // console.log("scale", this.state.pdfScale)
      // localStorage.setItem("pdfScale", window.PdfViewer.viewer.currentScaleValue)
    }
  }


  render() {
    const {
      url,
      highlights,
      showSummary,
      pdfHighlighterRef,
      anchorEl,
      showTeachText,
      showDiagramText,
      showTrackerText,
      bottom,
      trackerView
    } = this.state;
    const {
      UpdateFileTitle,
      fileData,
      isSmallScreen,
      handleTechAndDiagram,
      summeryData,
      lines,
      dropDownData,
      mermaidCode,
      Thread,
      handleZoomOut,
      handleZoomIn,
      fontScale,
      highlighterKey,
      position,
      handleChat,
      loading,
      typingKey,
      setSearchHighlight,
      searchHighlight,
      bgColor,
      setBgColor,
      setShowDrawer,
      showDrawer,
      chatLoading,
      askedQuestion,
      TypingTextState,
      setTypingTextState,
      trackerList,
      handleOpenModal,
      openModal,
      handleCloseModal,
      modalMermaidCode,
      handleSearch,
      showFields,
      summryLoading,
      showHighlightActions
    } = this.props;

    return (
      <>
        {isSmallScreen ? (
          <div className={mobilestyles.main_div}>
            <div className={mobilestyles.footer}>
              <div
                className={mobilestyles.pdf_container}
                style={{
                  height: showSummary ? "67vh" : "79vh",
                  width: "100%",
                  position: "relative",
                }}
              >
                <PdfViewer fileUrl={fileData?.documentURL} setExplainDrawerValues={this.setExplainDrawerValues} handleTechAndDiagram={handleTechAndDiagram} />
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className={styles.header}>
              <div className={styles.heading_section}>
                <input
                  className={styles.heading}
                  defaultValue={
                    fileData?.documentTitle?.length > 15
                      ? fileData?.documentTitle.slice(0, 15) + "..."
                      : fileData?.documentTitle
                  }
                  onBlur={(e) => {
                    UpdateFileTitle(e.target.value);
                  }}
                />
                <button
                className={styles.summary_btn}
                style={{background:bgColor==="#FFF" || bgColor==="var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))"?"#1d1d1d":bgColor,
                color:bgColor==="#FFF" || bgColor==="var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))"?"#fff":"#1d1d1d",
                display: showSummary ? "none" : "block"
              }}
                  onClick={this.handleToggleSummary}
                >
                  Summary
                </button>
              </div>
               <div className={styles.edit_section}>
                <div className={styles.highlight}>
                  <img src={highlighticon} alt="Highlight Icon" />
                  
                </div>
                {/* <div className={styles.text_edit}>
                  <p className={styles.small_A} onClick={handleZoomOut}>
                    A
                  </p>
                  <p className={styles.seperator}>|</p>
                  <p className={styles.capital_A} onClick={handleZoomIn}>
                    A
                  </p>
                </div> */}
            {/* </div>  */}
            {/* </div> */}
            {/* <Fade>
            <div
              className={styles.summary_section}
              style={{display: showSummary ? "block" : "none",background:bgColor==="#FFF" || bgColor==="var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))"?"rgba(189, 189, 189, 0.30)":bgColor,
              color:"#1d1d1d"
            }}
              // style={{  }}
            >
              <p className={styles.summary_heading}>Summary</p>
              {summryLoading?<div> <RotatingLines
                                strokeColor="grey"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="30"
                                visible={true}
                              /></div>: <p className={styles.summary}>
                              {summeryData}
                             </p>}
             
              <div className={styles.hide_parent}>
              <button onClick={this.handleToggleSummary}>Hide</button>
              </div>
            </div>
            </Fade> */}
            {/* <div className={styles.pdf_icons}>
              <div
                className={styles.chalkboard_icon}
                onMouseEnter={this.handleMouseEnterTeach}
                onMouseLeave={this.handleMouseLeaveTaech}
                style={{
                  background: showTeachText ? bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? "#1d1d1d" : bgColor : "transparent",
                  color: bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? "#fff" : "#1d1d1d"
                }}
                onClick={() => { handleTechAndDiagram("teach", searchHighlight === "" ? highlights.length > 0 ? highlights[0] : "no data" : searchHighlight) }}
              >

                <img src={showTeachText ? bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? chalkboarduserwhite : chalkboarduser : chalkboarduser} alt="Chalkboard Icon" />
                {showTeachText ? (
                  <p style={{ marginLeft: "5px" }}>Teach Me</p>
                ) : null}
              </div>
              <div
                className={styles.penswirl_icon}
                onMouseEnter={this.handleMouseEnterDiagram}
                onMouseLeave={this.handleMouseLeaveDiagram}
                style={{
                  background: showDiagramText ? bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? "#1d1d1d" : bgColor : "transparent",
                  color: bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? "#fff" : "#1d1d1d"
                }}
                onClick={() => { handleTechAndDiagram("diagram", searchHighlight === "" ? highlights.length > 0 ? highlights[0] : "no data" : searchHighlight) }}
              >

                <img src={showDiagramText ? bgColor === "#FFF" || bgColor === "var(--colors-default-bg, linear-gradient(180deg, #FDA88F 0%, rgba(255, 223, 156, 0.60) 100%))" ? penswirlwhite : penswirl : penswirl} alt="Pen Swirl Icon" />


                {showDiagramText ? (
                  <p style={{ marginLeft: "5px" }}>Draw For Me</p>
                ) : null}
              </div>
              <div
                className={styles.rectangle_icon}
                onClick={e => { setShowDrawer(!showDrawer) }}
                onMouseEnter={this.handleMouseEnterTracker}
                onMouseLeave={this.handleMouseLeaveTracker}
                style={{ minWidth: showDrawer ? "297px" : "20px", maxWidth: showDrawer ? "297px" : "20px", justifyContent: showDrawer ? "start" : "center", border: showDrawer ? "1px solid white" : "1px solid #cecece", background: showDrawer ? "#ffffff" : "transparent", padding: showDrawer ? "2px 6px" : "10px 6px", boxShadow: showDrawer ? "rgba(0, 0, 0, 0.16) 0px 1px 4px" : "none" }}
              >
                <img src={rectangle} alt="Rectangle Icon" />
                {showDrawer ? (
                  <p className={showDrawer ? styles.tracker_text : ""} style={{ marginLeft: showDrawer ? "30px" : "5px", fontWeight: showDrawer ? "600" : "400" }}>Tracker</p>
                ) : null}
                {showDrawer ?
                  <div className={styles.drawer}>
                    {trackerList.map((val, key) => (
                      <div

                      >
                        <p className={styles.tracker_title} style={{ paddingTop: key === 0 ? "40px" : "0" }} key={key}
                          onClick={() => this.updateHash(val, setSearchHighlight, searchHighlight)}><span style={{ padding: "0px 7px" }}>{key + 1} </span> {val?.content.text.length > 20
                            ? val.content.text.slice(0, 20) + "..."
                            : val.content.text}</p>
                      </div>
                    ))}


                  </div> : null}

              </div>
            </div> */}
            <div className={styles.pdf_section}>
              {/* <div className="zoom-btn">
                <Button onClick={this.onClickZoomIn} outlineBtn iconOnly={PlusIcon} />
                <Button onClick={this.onClickZoomOut} outlineBtn iconOnly={MinusIcon} />
              </div> */}
              <div className="App" style={{ display: "flex", height: "100%", width: '100%' }}>
                <div
                  style={{
                    height: "100%",
                    width: '100%',
                    position: "relative",
                  }}
                >
                  {
                    this.state.isDownloadingPDF
                      ? <div className='text-center'>
                        <RotatingLines strokeColor='#000000' strokeWidth='30px' width="35px" />
                        <h3>Your document is loading ...</h3>
                        <h4>Progress: {this.state.percentCompleted}%</h4>
                        <div style={{ width: `${this.state.percentCompleted}%`, background: '#C4CED3', height: '5px' }}>
                          {/* This div represents the progress bar */}
                        </div>
                      </div>
                      : <PdfViewer fileUrl={fileData?.documentURL} setExplainDrawerValues={this.setExplainDrawerValues} handleTechAndDiagram={handleTechAndDiagram} />
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default PDFsection;
