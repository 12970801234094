import React from "react";
import PropTypes from 'prop-types';
import './checkbox.scss'

const Checkbox = ({ label, checked, name, value, onChange, disabled }) => {
    return (
        <label class="checkbox-btn">{label}
            <input type="checkbox" checked={checked} name={name} value={value} onChange={onChange} disabled={disabled} />
            <span class="checkmark"></span>
        </label>
    );
};

export default Checkbox;

Checkbox.propTypes = {
    checked: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    disabled: PropTypes.any
};