import { Observable } from "rxjs";
import { authChat } from "../../axios";
import { getDocData } from "../../methods/commonMethods";

export const getHighlights = new Observable((subscriber) => {
    getDocData()
        .then(docData => {
            if (docData) {
                authChat.get(`/docHistory/GetDocHistoryItems?documentId=${docData.document_id}`)
                    .then((res) => {
                        // console.log("GetDocHistoryItems", res)
                        subscriber.next(res.data.docHistoryItems)
                        subscriber.complete()
                    })
                    .catch((err) => {
                        console.log("getHighlights error: ", err)
                    })
            }
        })
        .catch(error => console.error('Failed to get document data :' + error))
})

export const getQuizHistoryItems = new Observable((subscriber) => {
    getDocData()
        .then(docData => {
            if (docData) {
                authChat.get(`/quizHistory/GetQuizHistoryItems?documentId=${docData.document_id}`)
                    .then((res) => {
                        // console.log("res", res)
                        subscriber.next(res.data.quizHistoryItems)
                        subscriber.complete()
                    })
            }
        })
        .catch(error => console.error('Failed to get document data :' + error))
})
