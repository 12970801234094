import React from "react";
import PropTypes from 'prop-types';
import ToggleSwitch from "../toggleSwitch/toggleSwitch";
import './pageHeader.scss'

const PageHeader = ({ title, subTitle, actionArea }) => {
    return (
        <div className="page-header-wrap">
            <div className="title-area">
                {title &&
                    <h3 className="text-h3 m-0">{title}</h3> 
                }
                {subTitle &&
                    <small className="fw-normal text-muted text-small">{subTitle}</small>
                }
                <ToggleSwitch />
            </div>
            {actionArea &&
                <div className="action-area">
                    {actionArea}
                </div>
            }
        </div>
    );
};

export default PageHeader;

PageHeader.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    actionArea: PropTypes.any
};