import React from 'react'
import ConfirmationIcon from '../../assets/ic-confirm-tick.svg'
import CustomDialog from '../dialog/dialog'
import Button from '../button/button'

const FeedbackConfirmationDialog = ({openFeedbackConfirmation, setFeedbackConfirmation, setOpenFeedback}) => {
    const handleFeedbackClose = () => {
        setFeedbackConfirmation(false);
        setOpenFeedback(false);
    }
  return (
    <div className='feedback-confirmation-dialog-wrap'>
        <CustomDialog 
            size='sm' 
            open={openFeedbackConfirmation}
            dialogIcon={ConfirmationIcon}
            dialogTitle={'Confirmation'}
            dialogAction={
                <>
                    <Button primary text={'Ok'} onClick={handleFeedbackClose}/>
                </>
            }
            >
            <p className='text-body1 fw-medium text-center m-0'>
                Thanks for sharing <br />your thoughts with us!
            </p>
        </CustomDialog>
    </div>
  )
}

export default FeedbackConfirmationDialog;
