import React from 'react'
import CustomDialog from '../dialog/dialog'
import DialogIcon from '../../assets/ic-exp-y.svg'
import Button from '../button/button'
import "./home-confirmation-dialog.scss"

const HomeConfirmationDialog = ({ open, onClickOk, isNewRegistered }) => {
  return (
    <div className='home-confirmation-dialog'>
      <CustomDialog
        size={'sm'}
        dialogIcon={DialogIcon}
        dialogTitle={'Confirmation'}
        open={open}
        dialogAction={
          <Button primary onClick={onClickOk} text={'Ok'} />
        }
      >
        <div className='dialog-content'>
          {isNewRegistered
            ? <h4 className='text-h4 mt-0 mb-0 text-center'>
              Thanks! We'll email soon. Be sure to check spam.
            </h4>
            : <h4 className='text-h4 mt-0 mb-0 text-center'>
              Thanks! You are already registered. We'll email soon. Be sure to check spam.
            </h4>
          }
        </div>
      </CustomDialog>
    </div>
  )
}

export default HomeConfirmationDialog;