import axios from 'axios';
import config from './config';

const apiBaseUrl = config.apiUrl;
//const apiBaseUrl = "https://dev-api.mrezpz.ai";
//const apiBaseUrl = "http://localhost:8000";
//const apiBaseUrl = "https://api.mrezpz.ai";


// Helper function to process headers object and resolve function values
const processHeaders = (headers) => {
  const processedHeaders = { ...headers };
  for (const headerName in processedHeaders) {
    if (typeof processedHeaders[headerName] === 'function') {
      processedHeaders[headerName] = processedHeaders[headerName]();
    }
  }
  return processedHeaders;
};

// Function to create and configure an Axios instance
const createAxiosInstance = (baseURL, headers) => {
  const instance = axios.create({
    baseURL,
    headers: {},
  });

  // Interceptor to update headers before each request
  instance.interceptors.request.use(config => {
    const processedHeaders = processHeaders(headers); // Process headers before each request
    for (const headerName in processedHeaders) {
      config.headers[headerName] = processedHeaders[headerName];
    }
    return config;
  });

  // Interceptor to handle token refresh on 401 response
  instance.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshResponse = await axios.get(`${apiBaseUrl}/refresh_token/`, { withCredentials: true });
          const newAccessToken = refreshResponse.data.api_token;
          localStorage.setItem('api_token', `"${newAccessToken}"`);
          originalRequest.headers['Apitoken'] = `"${newAccessToken}"`;
          return axios(originalRequest);
        } catch (refreshError) {
          window.location.href = '/login';
          console.error('Error refreshing token:', refreshError);
          throw refreshError;
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

// Create instances with different configurations
const unauth = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'multipart/form-data',
  'Accept': 'application/json',
  'idToken': () => {
    const idToken = localStorage.getItem("idToken");
    return idToken;
  },
  'CLIENT_ID': () => {
    const clientId = localStorage.getItem("client_id");
    return clientId;
  }
});

const authAxios = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Apitoken': () => {
    const apiToken = localStorage.getItem("api_token");
    return apiToken;
  },
  'user-Email': () => {
    const userEmail = localStorage.getItem("userEmail");
    return userEmail;
  }
});

const authUploadDoc = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'multipart/form-data',
  'Accept': 'application/json',
  'Apitoken': () => {
    const apiToken = localStorage.getItem("api_token");
    return apiToken;
  },
  'userId': () => {
    const userState = JSON.parse(localStorage.getItem("userState"))
    if (userState) return userState.userId
    return null
  }
});

const authChat = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'apiToken': () => {
    const apiToken = localStorage.getItem("api_token");
    return apiToken;
  },
  'userId': () => {
    const userState = JSON.parse(localStorage.getItem("userState"))
    if (userState) return userState.userId
    return null
  }
});

const authToken = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'idToken': () => {
    const idToken = localStorage.getItem('idToken');
    return idToken;
  },
  'user-Email': () => {
    const userEmail = localStorage.getItem("userEmail")
    return userEmail
  },
});

const authFeedback = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Apitoken': () => {
    const apiToken = localStorage.getItem("api_token");
    return apiToken;
  },
  'user-id': () => {
    const userState = JSON.parse(localStorage.getItem("userState"))
    if (userState) return userState.userId
    return null
  }
});

const axiosInstance = createAxiosInstance(apiBaseUrl, {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
});

export { unauth, authAxios, authUploadDoc, authChat, authToken, authFeedback, axiosInstance };
