import React, { forwardRef, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import ArrowBack from '../../assets/arrow-left.svg';
import SendIcon from '../../assets/send-icon.svg'
import QuestionIcon from '../../assets/ic-question-fill.svg';
import TextArea from "../textArea/textArea";
import Button from "../button/button";
import SocraticIconDark from '../../assets/ic-brain-dark.svg'
import SocraticIconLight from '../../assets/ic-brain-light.svg'
import ToolTip from "../tooltip/tooltip";
import { clsx } from "clsx";
import { ClickAwayListener, Grid } from "@mui/material";
import './drawer.scss'

const CustomDrawer = ({
    children, anchor, size, open, onClose, disablePortal = 'true', onBackBtn, enableChat, backdropLight,
    chatArea, quizHeader, quizAction, onClickSendBtn, refDrawerContentScrollable, onKeyPress, disableSendBtn
}) => {



    //################################################## States start ##################################################//

    //=========================== State for socratic learning ============================//
    const [isSocraticLearning, setIsSocraticLearning] = useState(localStorage.getItem("isSocraticLearning") ?? true)
    const [showSocraticLearningBtnTooltip, setShowSocraticLearningBtnTooltip] = useState(false)

    //======================= State to record text area value ===============================//
    const [valueTextArea, setValueTextArea] = useState('')
    const [textLength, setTextLength] = useState(0)

    const scrollableContainerRef = useRef(null);


    //################################################# State end ###################################################//


    //################################################### Function Area start ##############################################//

    //======================== Function to handle onclick of send btn =======================//
    const handleOnClickSend = () => {
        onClickSendBtn(valueTextArea)

        // remove the text area value
        setValueTextArea('')

        //reset the text length
        setTextLength(0)
    }

    //===================== Function to handle onClick of back btn ========================//
    const handleOnClickBackBtn = () => {
        onBackBtn()
        setValueTextArea('')
    }

    //======================== Function call on press Enter ===========================//
    const onKeyPressInDrawerChat = (e) => {

        // Chceck if value is given by user
        if (valueTextArea.trim()) {
            if (e.key === "Enter" && !e.shiftKey) {
                if (!disableSendBtn) {
                    onClickSendBtn(valueTextArea)

                    // remove the text area value
                    setValueTextArea('')

                    // prevent line break if value is submitted
                    e.preventDefault()

                    //reset the text length
                    setTextLength(0)
                }
            }
        }
    }

    //========================== Function for on click socratic learning btn ===============================//
    const onClickSocraticLearningBtn = () => {
        const temp = !isSocraticLearning
        setShowSocraticLearningBtnTooltip(true)
        setIsSocraticLearning(temp)
        localStorage.setItem("isSocraticLearning", temp)
    }

    //============================ Function on Socratic learning btn tooltip close =========================//
    const handleSocraticLearningBtnCloickAway = () => {
        setShowSocraticLearningBtnTooltip(false)
    }

    //################################################### Function Area end ##############################################//

    const scrollToBottom = () => {
        const container = scrollableContainerRef.current;
        if (container && !quizHeader) {
            const scrollHeight = container.scrollHeight;
            setTimeout(() => {
                container.scrollTo({
                    top: scrollHeight,
                    behavior: 'smooth'
                });
            }, 300);
        }
    };

    useEffect(() => {
        scrollToBottom()
    }, [children]);

    return (
        <div
            className={clsx({
                'drawer-wrap': true,
                'has-chat': enableChat,
                'size-sm': size == 'sm',
                'size-md': size == 'md',
                'light-backdrop': backdropLight,
                'quiz-drawer': quizHeader,
                'quiz-action': quizAction
            })}>
            <Drawer
                anchor={anchor}
                size={size}
                open={open}
                onClose={onClose}
                disablePortal={disablePortal}
            >
                <div className="drawer-content">
                    {onBackBtn &&
                        <a href="javascript:void(0)" className="back-btn m-0" onClick={handleOnClickBackBtn}>
                            <img src={ArrowBack} alt="" />
                        </a>
                    }
                    {quizHeader &&
                        <div className="quiz-header">
                            {quizHeader}
                        </div>
                    }
                    <div ref={scrollableContainerRef} className="drawer-content-inner scrollable">
                        <div className="content-area">
                            {children}
                        </div>
                        {chatArea &&
                            <div className="chat-area">

                                {chatArea}
                            </div>
                        }
                    </div>
                </div>
                {enableChat &&
                    <div className="chat-typing-area">
                        <TextArea
                            onKeyPress={onKeyPressInDrawerChat}
                            placeholder={'Ask me anything ...'}
                            value={valueTextArea}
                            rows={8}
                            actionBtn={
                                <>
                                    <Button className={`${disableSendBtn ? "unclickable" : ""}`} outlineBtn type={'submit'} onClick={handleOnClickSend} iconOnly={SendIcon} />
                                    <Grid item>
                                        <ClickAwayListener onClickAway={handleSocraticLearningBtnCloickAway}>
                                            <div>
                                                <ToolTip
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    // open={showSocraticLearningBtnTooltip}
                                                    disableFocusListener
                                                    // disableHoverListener
                                                    disableTouchListener
                                                    arrow
                                                    title={
                                                        <span className="tooltip-content text-body1 lh-20 fw-medium">
                                                            {isSocraticLearning ?
                                                                <span>
                                                                    <span className="text-body1 lh-18 d-block">Socratic Learning : <span className="text-success">Enabled</span></span>
                                                                    <p className="mt-0 mb-10 soc-learning-small">(Experimental Mode)</p>
                                                                    <span className="text-body2">Guided learning through thought-provoking questions to help you discover your own answers.</span>
                                                                </span> 
                                                                :
                                                                <span>
                                                                    <span className="text-body1 lh-18 d-block mb-10">Socratic Learning : <span className="text-danger">Disabled</span></span>
                                                                    <span className="text-body2">You are in a direct learning mode where your questions are answered straightforwardly.</span>
                                                                </span>
                                                            }
                                                            {/* Socratic Learning : {isSocraticLearning ? <span className="text-success fw-bold">On</span> : <span className="text-danger fw-bold">Off</span>} */}
                                                            {/* <span className="text-success fw-bold">On</span> */}
                                                        </span>
                                                    }
                                                    placement={'left'}>
                                                    <Button className='socratic-btn' onClick={onClickSocraticLearningBtn} outlineBtn type={'submit'} iconOnly={isSocraticLearning ? SocraticIconDark : SocraticIconLight} />
                                                </ToolTip>
                                            </div>
                                        </ClickAwayListener>
                                    </Grid>
                                </>
                            }
                            onChange={(e) => {
                                const value = e.target.value
                                if (value.length <= 1500) {
                                    setValueTextArea(e.target.value)
                                    setTextLength(e.target.value.length)
                                }
                            }}
                            helpText={`${textLength} / 1500`}
                        />
                    </div>
                }
                {quizAction &&
                    <div className="quiz-action-area">
                        {quizAction}
                    </div>
                }
            </Drawer>
        </div>
    );
};

export default CustomDrawer;

CustomDrawer.propTypes = {
    children: PropTypes.any,
    anchor: PropTypes.string,
    size: 'sm' | 'md',
    open: PropTypes.bool,
    onClose: PropTypes.func,
    disablePortal: PropTypes.bool,
    onBackBtn: PropTypes.func,
    enableChat: PropTypes.bool,
    backdropLight: PropTypes.bool,
    chatArea: PropTypes.any,
    quizHeader: PropTypes.any,
    quizAction: PropTypes.any,
    onClickSendBtn: PropTypes.any
};