import React, { forwardRef, useRef, useState } from "react";
import PropTypes from 'prop-types';
import DialogCloseIcon from '../../assets/ic-dialog-close.svg'
import Dialog from '@mui/material/Dialog';
import { clsx } from "clsx";
import './dialog.scss'

const CustomDialog = ({
    children, size, open, onClose, disablePortal = 'true', dialogIcon, dialogTitle, dialogAction, dialogDisclaimer
}) => {

    return (
        <div 
            className={clsx({
                'dialog-wrap': true,
                'dialog-sm': size == 'sm',
                'dialog-md': size == 'md',
                'dialog-lg': size == 'lg'
            })}
        >
            <Dialog onClose={onClose} open={open} disablePortal={disablePortal} size={size}>
                <div className={`dialog-header ${dialogTitle === "Diagram" &&  'dialog-diagram'}`}>
                    {dialogIcon &&
                        <div className="dialog-icon">
                            <img src={dialogIcon} alt={dialogTitle} />
                        </div>
                    }
                    {dialogTitle &&
                        <div className="dialog-title">
                            <h2 className="text-h2 m-0 fw-bold">{dialogTitle}</h2>        
                        </div>
                    }
                    {onClose && ['Welcome Aboard', 'Diagram'].includes(dialogTitle) && 
                        <a className="dialog-close cp" onClick={onClose}>
                            <img src={DialogCloseIcon} alt={'close'} />
                        </a>
                    }
                </div>
                <div className={`dialog-body scrollable ${dialogTitle === "Diagram" &&  'dialog-diagram'}`}>
                    {children}
                </div>
                {dialogAction &&
                    <div className="dialog-action">
                        {dialogAction}
                    </div>
                }
                {dialogDisclaimer}
            </Dialog>
        </div>
        
    );
};

export default CustomDialog;

CustomDialog.propTypes = {
    children: PropTypes.any,
    size: 'sm' | 'md',
    open: PropTypes.bool,
    onClose: PropTypes.func,
    disablePortal: PropTypes.bool,
    dialogIcon: PropTypes.string,
    dialogTitle: PropTypes.string,
    dialogAction: PropTypes.any,
    dialogType: PropTypes.string,
    dialogDisclaimer: PropTypes.func
};