export const ButtonType = {
    Explain: 1,
    Simplyfy: 2,
    Summarize: 3,
    Diagram: 4
}

export const ButtonDictionary = {
    1: "Explain",
    2: "Simplify",
    3: "Summarize",
    4: "Diagram"
}

export const ConversationTypId = {
    MainConversation: 1,
    DrawerConversation: 2
}

export const QuizSettingsQuestionTypeId = {
    MultipleChoice: 1,
    TrueFalse: 2,
    ShortAnswer: 3,
    SelectAll: 4
}

export const FileExtension = {
    Pdf: "pdf",
    Doc: "doc",
    Docx: "docx",
    Ppt: "ppt",
    Pptx: "pptx"
}

export const RegenrateOptionType = {
    Paragraph: 1,
    BulletPoints: 2,
    Longer: 3,
    Shorter: 4
}