import BaseLayout from "../baseLayout/baseLayout";
import "./privacy-policy.scss";

export const PrivacyPolicy = () => {
    return <BaseLayout showRightChatArea={false} noRightArea={true} showHeaderActions={false} showFullHeader={false}>
        <div className='login-container-wrap'>
            <div className="privacy-container">
                <div style={{ textAlign: 'center' }}>
                    <h4>
                        EZ PZ LLC <br></br>
                        PRIVACY POLICY <br></br>
                        Last Updated: March 3, 2024 <br></br>
                    </h4>
                </div>

                <p>This privacy policy (“Policy”) describes how EZ PZ LLC and its related companies (“Company”) may collect, use, and share personal information of consumer users of this website, www.mrezpz.ai (the “Site”) or any website of ours that links to this privacy notice. Policy also applies when you engage with us in other related ways, including any sales, marketing, or events.</p>
                <p>We respect your privacy and are strongly committed to keeping secure any information we obtain from you or about you. Our commitment to your privacy is not just an obligation, but a vital part of our mission to provide a secure and private learning experience.
                </p>

                <h3>WHAT WE MAY COLLECT</h3>
                <p>We may collect information about you (collectively, “Personal Data”) in a range of ways.</p>
                <ul>
                    <li>
                        <p>
                            <b>Information You Give Us.</b> We may collect your name, email address, as well as other information you directly give us on our site.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Information Automatically Collected.</b>We use Google Analytics to automatically log information about you and your computer. For example, when visiting our Site, we may log information including your computer operating system type, browser type, browser language, IP address, region of the IP address, pages you viewed, clicks on a page, how long you spent on a page, access times and information about your use of and actions on our Site.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Information Created on Our Site.</b> As you interact with our Site, you may participate in chat logs, create quizzes, and generate other content. We may collect information about such content you create on our Site. When you upload a document to our Site, you give Company permission to store and protect this content on your behalf. We will not use, host, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display, or distribute the document for any other purposes. We additionally do not use the specific content of your uploaded educational materials for the purpose of training our AI models.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Cookies.</b> We may log information using “cookies.” Cookies are small data files stored on your hard drive by a website. We may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site. This type of information is collected to make the Site more useful to you and to tailor the experience with us to meet your special interests and needs.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b> Web Beacons.</b> Pages of our Site and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related Site statistics (for example, recording the popularity of certain Site content and verifying system and server integrity).
                        </p>
                    </li>
                </ul>

                <h3>USE OF PERSONAL INFORMATION</h3>
                <p>We may use the information we collect for several purposes aimed at providing and improving our Site. We use your Personal Data as follows:</p>
                <ul>
                    <li>
                        <p>
                            We may use your Personal Data to authenticate and register you with our Site.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use your data to personalize your experience on Site, including remembering your preferences, suggesting relevant features, or showing you content that aligns with your interests.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use aggregated and anonymized data to identify trends, track user behavior, and understand how users interact with Site.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use your Personal Data to operate, maintain, and improve our sites, products, and services or develop new or improved educational offerings.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use your Personal Data to send information including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use your Personal Data to communicate about promotions, upcoming events, and other news about products and services offered by us and our selected partners.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may use your Personal Data to provide and deliver products and services customers request.
                        </p>
                    </li>
                </ul>

                <h3>SHARING OF PERSONAL INFORMATION</h3>
                <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

                <h3>SHARING OF PERSONAL INFORMATION</h3>
                <p>EZ PZ LLC stands firmly in its commitment to safeguard your sensitive data. It is against our values and company ethos to share, sell, or distribute your sensitive data to any third parties. We will never do this unless absolutely required by law. </p>

                <h3>MINORS</h3>
                <p>Our Service is not directed to those under the age of 18. Company does not knowingly collect Personal Data from those under the age of 18. If you have reason to believe that a user under the age of 18 has provided Personal Data to EZ PZ LLC through the Site, please email us at team@mrezpz.ai. We will investigate any notification and if appropriate, delete the Personal Information from our systems</p>

                <h3>TERMS AND CONDITIONS</h3>
                <p>Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of the Site.</p>

                <h3>CONTACT INFORMATION</h3>
                <p>We welcome your comments or questions about this privacy policy. If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us at <a href="mailto:team@mrezpz.ai">team@mrezpz.ai</a>. You may also contact us at our address:</p>

                <p>EZ PZ LLC <br></br>
                    5900 Balcones Dr, STE 100 <br></br>
                    Austin, TX 78731 <br></br>
                </p>

                <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                <p>We may change this privacy policy. If we make any changes, we will change the Last Updated date above.</p>
            </div>
            <div className='login-footer'>
                <p className='fw-medium mt-0 mb-0'>
                    &copy; 2024 Mr. EzPz<span className='seperator'> | </span>All Rights Reserved
                </p>
            </div>
        </div>
    </BaseLayout>
};