import React from 'react'
import { Container, Grid, Box } from '@mui/material';
import Image01 from '../../assets/bulb-img.svg'
import Image02 from '../../assets/pyramid-img.svg'
import Image03 from '../../assets/cam-img.svg'
import Image04 from '../../assets/gear-img.svg'
import ToolsImage from '../../assets/framework-img.svg'
import SectionSeperator from '../../assets/border-img.svg'
import './home-frameworks.scss';
import SectionTitle from '../section-title/section-title';

const Frameworks = ({ }) => {
  return (
    <section className='section-wrap-framework'>
        <Container maxWidth='lg'>
            <Box mb={{xs:5, md:10}}>
                <SectionTitle
                    secImage={ToolsImage}
                    title={'Proven Frameworks Infused In Mr. EzPz'}
                    subTitle={'Bringing traditional educational tools into the new age'} 
                />
            </Box>
            <Grid container item spacing={{xs:1.25, md:2}} alignItems={'stretch'} justifyContent={'center'}>
                <Grid item xs={6} lg={3}>
                    <Box className='content-box'>
                        <Box className='icon'>
                            <img src={Image01} alt='Socratic Learning' />
                        </Box>
                        <Box className="content">
                            <h3 className='text-h3 title mt-0 mb-0'>Socratic Learning</h3>
                            <p className='text-body2 subtitle mb-0 text-muted'>A method of teaching that uses questioning to help students discover answers and stimulate critical thinking.</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <Box className='content-box'>
                        <Box className='icon'>
                            <img src={Image02} alt='Bloom’s Taxonomy' />
                        </Box>
                        <Box className="content">
                            <h3 className='text-h3 title mt-0 mb-0'>Bloom’s Taxonomy</h3>
                            <p className='text-body2 subtitle mb-0 text-muted'>A scaffolded learning journey where instruction and questions align with the different levels of Bloom's Taxonomy.</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <Box className='content-box'>
                        <Box className='icon'>
                            <img src={Image03} alt={'Vygotsky Zone'} />
                        </Box>
                        <Box className="content">
                            <h3 className='text-h3 title mt-0 mb-0'>Vygotsky's Zone</h3>
                            <p className='text-body2 subtitle mb-0 text-muted'>A framework that focuses on elevating a learner's capability by providing the right level of challenge and support.</p>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <Box className='content-box'>
                        <Box className='icon'>
                            <img src={Image04} alt='Feynman Technique' />
                        </Box>
                        <Box className="content">
                            <h3 className='text-h3 title mt-0 mb-0'>Feynman Technique</h3>
                            <p className='text-body2 subtitle mb-0 text-muted'>A technique where a student simplifies complex concepts in their own words, as if teaching a beginner.</p>
                        </Box>
                    </Box>
                </Grid>
            </Grid> 
            <Box className="section-seperator text-center" mt={{xs:4.75, md: 11.6}} >
                <img src={SectionSeperator} alt='' className='mw-100 w-100' />
            </Box>
        </Container>
    </section>
  )
}

export default Frameworks;
