import React, { ReactNode, useEffect } from "react";
import PropTypes from 'prop-types';
import DeleteIcon from '../../assets/ic-del.svg';
import ChatWindowLogo from '../../assets/chat-window-logo.svg'
import NoQuizes from '../../assets/img-quiz-empty.svg';
import './historyContent.scss'


const HistoryContent = ({ title, historyTitle, historyDateTitle, listItemOptions, hasLogo, updateQuizHistory}) => {
    useEffect(() => {
        updateQuizHistory()
    }, [])

    return (
        <div className="content-container">
            {title &&
                <div className="title-area">
                    <h5 className="text-h5 m-0 mb-6 text-fw-normal">{title}</h5>
                </div>
            }
            <div className="history-list-wrap">
                {historyTitle && historyDateTitle &&
                    <div className="history-header">
                        <p className="text-body1 m-0 fw-bold">{historyTitle}</p>
                        <p className="text-body1 m-0 fw-bold">{historyDateTitle}</p>
                    </div>
                }
                <div className="history-list-area">
                    <div className="list-area-inner scrollable">
                        {hasLogo && 
                        <>
                            <div className="logo-area">
                                <img src={ChatWindowLogo} alt="" />
                            </div>
                            <p className="logo-bottom-text">
                                Click on any of the text bubbles to retake your specific quiz
                            </p>
                        </>
                        }   
                        {listItemOptions.length ? (listItemOptions ?? []).map(({ title, description, date, onClick, onDelete }, index) => (
                            <div className="list-item" key={index}>
                                <a href="javascript:void(0)" className="list-item-content text-dark m-0 text-decoration-none" onClick={onClick}>
                                    <p className="text-body1 fw-bold m-0 mb-6">{title}</p>
                                    <p className="text-body2 text-muted m-0">{description}</p>
                                </a>
                                <div className="list-item-meta">
                                    <a href="javascript:void(0)" className="list-item-action m-0" onClick={onDelete}>
                                        <img src={DeleteIcon} alt="delete" />
                                    </a>
                                    <small className="text-small date">{date}</small>
                                </div>
                            </div>
                        )) : (!listItemOptions.length && <div className="no-quizes-wrap">
                            <img src={NoQuizes} alt="No Quizes" />
                        </div>)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HistoryContent;

HistoryContent.propTypes = {
    title: PropTypes.string,
    historyTitle: PropTypes.string,
    historyDateTitle: PropTypes.string,
    selctedText: PropTypes.string,
    contentType: PropTypes.string,
    contentBrief: PropTypes.any,
    listItemOptions: PropTypes.array,
    hasLogo: PropTypes.bool
};