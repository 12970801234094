import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { clsx } from "clsx";
import arrowIcon from '../../assets/img-arrow-right-grey.svg'
import './tab.scss';

const CustomTab = ({ items, chatTabs, orientation, tabIndex, setTabIndex }) => {
    const [value, setValue] = useState(tabIndex || '0')

    const onChange = (event, newValue) => {
        setValue(newValue)
        if (tabIndex) setTabIndex(newValue)
    }

    useEffect(() => {
        if (tabIndex) setValue(tabIndex)
    }, [tabIndex])
    

    return (
        <div 
            className={clsx({
                'tabs-wrap': true,
                'chat-area-tabs': chatTabs
            })}
        >
            <TabContext value={value}>
                <TabList onChange={onChange} orientation={orientation}>
                    {items.map(({disabled, label}, index) => (
                        <Tab label={
                        <span className='tab-label-wrap'>
                            <span className='tab-label'>{label}</span> 
                            {orientation === 'vertical' && 
                                <span className='icon'>
                                    <img src={arrowIcon} alt='' />
                                </span>
                            }
                        </span>
                        } value={index + ''} key={index} className="tab-btn" disabled={disabled} />
                    ))}
                </TabList>
                <div className='tab-content-wrap'>
                {items.map(({content}, index) => (
                    <TabPanel value={index + ''} index={index}>
                        {content}
                    </TabPanel>
                ))}
                </div>
            </TabContext>
        </div>
    )
}

export default CustomTab;

CustomTab.propTypes = {
    items: PropTypes.array,
    value: PropTypes.number,
    onChange: PropTypes.any,
    chatTabs: PropTypes.bool,
    orientation: 'vertical' | 'horizontal'
};