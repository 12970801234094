import React from "react";
import PropTypes from 'prop-types';
import './radio.scss'

const Radio = ({ label, checked, name, value, onChange, readOnly, disabled }) => {
    return (
        <label class="radio-btn text-body2 text-muted">{label}
            <input type="radio" checked={checked} name={name} value={value} onChange={onChange} readOnly={readOnly} disabled={disabled} />
            <span class="checkmark"></span>
        </label>
    );
};

export default Radio;

Radio.propTypes = {
    checked: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.any,
    value: PropTypes.any,
    readOnly: PropTypes.bool
};