import React, { ReactNode } from "react";
import PropTypes from 'prop-types';
import './loader.scss'

const Loader = ({ }) => {
    return (
        <div class="loader">
            <span class="jumping-dots">
                <span class="dot-1"></span>
                <span class="dot-2"></span>
                <span class="dot-3"></span>
            </span>
        </div>
    );
};

export default Loader;

Loader.propTypes = {
};