import React, { useState, useEffect } from 'react';
import myGif from '../../assets/EzPz_Upload.svg'
import { json, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { authToken } from "../../axios";
import { jwtDecode } from "jwt-decode";
import { getAccessToken } from "../../methods/commonMethods"
import BaseLayout from '../baseLayout/baseLayout';
import './login.scss';
import ezpzLogo from '../../assets/ezpz-logo-new.svg';
import ezpzLoginHeading from '../../assets/login-ezpz-heading.svg';
import ezpzGoogleLogo from '../../assets/google-logo.png';
import GifMainLoader from "../../assets/gif-loader-main.gif";

import config from '../../config'

export default function Login() {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [error, setError] = useState(false)
	const [showLoader, setShowLoader] = useState(false)
	const [showStaticPasscode, setshowStaticPasscode] = useState(localStorage.getItem("isLoggedInByKey") ? false : config.showStaticPasscode)

	// window.handleLogin = (response) => {
	// 	// Handle the login logic here
	// 	console.log("Google Sign-In response:", response);

	// 	// Your additional logic, like redirecting or updating UI
	//   }

	useEffect(() => {

		if(showStaticPasscode){
			// Focus on the input element when the component is mounted
			const passcodeInput = document.getElementById('passcode');
			if (passcodeInput) {
			passcodeInput.focus();
			}
		}	
	  }, []); 

	const handleLogin = (response) => {
		if (email === "mrezppzy@ai.com" && password === "mrezypzy@0900") {
			localStorage.setItem('idToken', "fh77f7rhuruwhrw9hrw38r3wru3w98r983wr8hw8rw83r8hr9898hr3w8r8hw8hrw98hr")
			navigate("/uploadFile")
		} else {
			//setError(true)
			console.log("Response", response)
		}
	}

	const checkStaticPasscode = () => {
		const passcode = document.getElementById('passcode').value
		if (passcode === "MrEzPz@passCode123!@#") {
			setshowStaticPasscode(false)
			localStorage.setItem("isLoggedInByKey", true)
		}
		else{
			alert("Incorrect Passcode");
			document.getElementById('passcode').value = '';
			document.getElementById('passcode').focus();
		}
	}

	const onSuccess = async (credentialResponse) => {
		setShowLoader(true)
		// console.log('credential Response', credentialResponse);
		localStorage.setItem('idToken', JSON.stringify(credentialResponse.credential))

		const decodedToken = jwtDecode(credentialResponse.credential)
		//console.log("loginEmail", decodedToken.email)
		localStorage.setItem("userEmail", JSON.stringify(decodedToken.email))
		localStorage.setItem("client_id", JSON.stringify(credentialResponse.clientId))

		// // enable socratic learning for drawer chat by default
		// localStorage.setItem("isSocraticLearning", true)

		// // enable socratic learning for main chat by default
		// localStorage.setItem("isMainChatSocraticLearning", true)

		//getAccessToken()
		// setError(false)

		setTimeout(() => {
			addUserIfNotExist(decodedToken)
		}, 200);
		// await addUserIfNotExist(decodedToken)
	}

	const onFailure = () => {
		console.log("Failed to log in")
		// setError(true)
	}

	const addUserIfNotExist = async (decodedToken) => {
		if (localStorage.getItem("idToken") === null || localStorage.getItem("idToken") === undefined) {
			console.log("Cannot get id Token when calling add user if not exist")
		}
		if (decodedToken) {

			const addUserModel = {
				Email: decodedToken.email,
				FirstName: decodedToken.given_name,
				LastName: decodedToken.family_name == undefined ? "" : decodedToken.family_name,
				EmailVerified: decodedToken.email_verified,
				Name: decodedToken.name,
				GoogleId: decodedToken.sub
			}
			// const response = await fetch("http://localhost:8000/add_user/", {
			// 	method: "POST",
			// 	headers: {"Content-Type": "application/json"},
			// 	body: JSON.stringify(addUserModel)
			// });

			// if (response.ok) {
			// 	console.log("Data to add_user sent successfully");
			// } else {
			// 	console.error("Failed to send data to add_user");
			// }

			authToken.post("/add_user/", JSON.stringify(addUserModel), {withCredentials: true})
				.then((res) => {
					localStorage.setItem("api_token", JSON.stringify(res.data.api_token))
					const user_id = parseInt(res.data.user_id)

					//========================= SET USER STATE IN LOCAL STORAGE ==========================//
					const userState = {
						userId: user_id,
						email: JSON.stringify(decodedToken.email),
						googleId: JSON.stringify(decodedToken.sub)
					}
					localStorage.setItem("userState", JSON.stringify(userState))
					setShowLoader(false)

					// navigate to activity page if user already exists
					if (res.data.message === "User already exists") {
						navigate("/activity")
					} else {
						navigate("/uploadFile")
					}
				})
				.catch((error) => {
					console.log("addUser", error);
					setShowLoader(false)
				})
		}
	}

	const onKeyPressForPasscode = (e) => {
		if(e.key === 'Enter'){
			checkStaticPasscode()
		}
	}

	return (

		<BaseLayout showRightChatArea={false} noRightArea={true} showHeaderActions={false} showFullHeader={false}>
			<div className='login-container-wrap'>
				<div className='login-container'>
					<div className="login-wrap">
						<div className='logo-area'>
							<img className='ezpz-logo' src={ezpzLogo} alt="ezpz logo" />
						</div>
						{
							!showStaticPasscode && !showLoader && <div>
								<div className="login-heading text-center">
									<img src={ezpzLoginHeading} alt="ezpz login heading" />
								</div>
								<div className='login-google-default'>
									<GoogleLogin onSuccess={onSuccess} onError={onFailure} />
								</div>
							</div>
						}
						{
							showStaticPasscode && <div> 
								<div className='login-heading text-center'>
								<input onKeyPress={onKeyPressForPasscode} autoComplete='off' id='passcode' className='activity-search' type="text" placeholder="Enter Passcode" />
								<br></br>
								<button style={{cursor:'pointer'}} onClick={checkStaticPasscode}>Continue</button>
								</div>
							</div>
						}
					</div>
					{showLoader && <div className='text-center'>
						<img src={GifMainLoader} />
					</div>}
				</div>
				{/* {
				!showStaticPasscode && <div className='login-footer-text'>
					<p>
						Mr. EzPz is an AI-powered educational application designed to assist students in their studies,
						providing features that enable question-answering and quiz preparation based on documents provided by the user.
						Logging into this platform implies your acceptance of our terms of service and understanding that the content presented here is intended for informational purposes and should not be construed as professional guidance.
					</p>
				</div>
				} */}
				<div className='login-footer'>
					<p className='fw-medium mt-0 mb-0'>
						&copy; 2024 Mr. EzPz<span className='seperator'> | </span>All Rights Reserved
					</p>
					<p className='fw-medium mt-0 mb-0'>
						<a href="" onClick={() => window.open('/terms-and-conditions','_blank')}>Terms & Conditions</a>
						<span className='seperator'> | </span>
						<a href="" onClick={() => window.open('/privacy-policy','_blank')}>Privacy Policy</a>
					</p>
				</div>
			</div>

		</BaseLayout>

		/* <div className='parent'>
		<div className="containerMain" id="container">
			<div className="form-container sign-up-container">
				<form >
					<h1>Create Account</h1>
					<div className="social-container">
					</div>
					<span>or use your email for registration</span>
					<input className='input_field' type="text" placeholder="Name" />
					<input className='input_field' type="email" placeholder="Email" />
					<input className='input_field' type="password" placeholder="Password" />
					<button>Sign Up</button>
				</form>
			</div>
			<div className="form-container sign-in-container">
				<form >
					<h1>Welcome Back</h1>
					<div className="social-container">
					</div>
					<input className='input_field' type="email" placeholder="Email" onChange={e=>{setEmail(e.target.value)}} />
					<input className='input_field' type="password" placeholder="Password" onChange={e=>{setPassword(e.target.value)}}/>
					{error?<p style={{margin:0, fontSize:'10px', fontWeight:'bold', color:'red'}}>Incorrect Email or Password</p>:null}
					<a href="javascript:void(0)">Forgot your password?</a>
					<button onClick={e=>{handleLogin()}}>Login</button>
				</form>
				<div>
				<GoogleLogin
						onSuccess={onSuccess}
						onError={onFailure}
				/>
				</div>
			</div>
			<div className="overlay-container">
				<div className="overlay">
					<div className="overlay-panel overlay-left">
						<h1>Welcome Back!</h1>
						<img src={myGif} />
					</div>
					<div className="overlay-panel overlay-right">
						<img src={myGif}  width={"40%"}/>
					</div>
				</div>
			</div>
		</div>
		</div> */
	);
}