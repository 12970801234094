import { jwtDecode } from "jwt-decode"
import { authChat, unauth } from "../axios"
import moment from "moment/moment"
import { getDataIndexedDB } from "./indexedDB"
import { Base64 } from "js-base64"


export const getAccessToken = async () => {
    unauth.get("/get_access_token")
        .then((response) => {
            //console.log("response", response)
            localStorage.setItem("access_token", JSON.stringify(response.data.access_token))
            //console.log("Access Token successfully set")
            return true
        })
        .catch((error) => {
            console.log("getAccessTokenError", error)
            return false
        })
}

//=========================== Function to scroll to the bottom of the container ===========================//
export const scrollToBottom = (componentReference) => {
    if (componentReference.current) {
        componentReference.current.scrollIntoView({ behavior: "smooth", block: "end" })
    }
};

//============================ Function to convert date string to local time =============================//
export const convertToLocalDate = (strDate) => {
    const dateString = strDate;
    const dateObject = new Date(dateString + ' UTC'); // Add 'UTC' to ensure correct parsing

    const options = {
        year: '2-digit',
        month: 'short',
        day: 'numeric',
    };

    const localizedDate = dateObject.toLocaleDateString(undefined, options);
    return localizedDate
}

//============================ Function to convert date string to local time =============================//
export const convertToLocalTime = (dateTime) => {
    const momentObj = moment.utc(dateTime).local();
    const timeLocal = momentObj.format("hh:mm A");
    return timeLocal;
};


//=========================== Function to get first 5 words from a string =============================//
export const extractWordsFromStart = (str, wordCount) => {
    let result = str
    //=================== Logic to extract first five words from selected text =====================//
    // Split the string into an array of words, then get first 5 words, join them and concatenate with "..." to get description
    const strArray = str.split(' ');
    if (strArray.length > wordCount) {
        result = strArray.slice(0, wordCount).join(" ") + "..."
    }

    return result
}

//========================== Function to get file name without extension =================================//
export const getFileNameWithoutExtension = (fileNameWithExtension) => {
    if (typeof fileNameWithExtension === "string") {
        return fileNameWithExtension.replace(/\.[^/.]+$/, '') // Remove the file extension
    }
}

//========================== Function to convert bytes to size ================================//
export const bytesToSize = (bytes) => {
    if (typeof (bytes) === "number") {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;

        if (bytes < kilobyte) {
            return bytes + ' B';
        } else if (bytes < megabyte) {
            return (bytes / kilobyte).toFixed(2) + ' KB';
        } else {
            return (bytes / megabyte).toFixed(2) + ' MB';
        }
    }
}

//========================== Function to check if user is logged in ==============================//
export const checkLoggedIn = async () => {
    try {
      const res = await authChat.get(`/verify_me/`);
      return true;
    } catch (error) {
      return false;
    }
  }

//========================== FUnction to check if token is expired ==============================//
export const isTokenExpired = (token) => {
    let decodedToken = jwtDecode(token)
    let currentDate = new Date()

    // JWT exp is in seocnds
    if ((decodedToken.exp * 1000) < currentDate.getTime()) {
        return true
    } else {
        return false
    }
}

//======================== Function to validate email ==========================//
export const isEmailValid = (email) => {
    return email.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
};

export const getDocData = async () => {
    try {
        const docDataId = localStorage.getItem("doc_data_id")
        const docData = await getDataIndexedDB(docDataId)
        return JSON.parse(docData.doc_data)
    } catch (error) {
        console.error("Failed to get document data from IndexedDB :" + error)
    }
};

/**
 * Function to encode request payload object to base64
 * @param payload
 * @returns string
 */
export const encodeToBase64 = (payload) => {
    try {
        const encodedPayload = Base64.encode(JSON.stringify(payload));;
        return encodedPayload
    } catch (error) {
        console.error("Failed to encode payload :" + error)
    }
}

/**
 * Function to shuffle array
 * @param {*} array 
 * @returns array 
 */
export const shuffleArray = (array) => {
    const arrLength = array.length;
    for (let i = arrLength- 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

/**
 * Function to randomize quiz object
 * @param {*} quiz 
 * @returns object
 */

export const randomizeQuiz = (quiz) => {
    quiz.Questions = shuffleArray(quiz.Questions);
    quiz.Questions.forEach(question => {
        question.Options = shuffleArray(question.Options);
    });
    return quiz;
}
