import React, { useRef } from 'react'
import { LandingHeader, HomeBanner, HomeIntro, LearningExperience, Frameworks, Security, HomeFooter, HomeConfirmationDialog } from '../../components'
import './home.scss'

const HomePage = ({ }) => {

  //=============================== Ref for scroll content ================================//
  // const refDrawerContentScrollable = useRef(null)
  const emailRef = useRef(null)

  const onClickSignup = () => {
    if(emailRef.current){
      emailRef.current.focus()
    }
  }

  return (
    <div className='page-wrap'>
        <LandingHeader />
        <HomeBanner emailRef={emailRef}/>
        <HomeIntro onClickSignup={onClickSignup}/>
        <LearningExperience />
        <Frameworks />
        <Security />
        <HomeFooter />
        
    </div>
  )
}

export default HomePage;
