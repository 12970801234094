import React from 'react'
import { Container, Grid, Box } from '@mui/material';
import SelfLearningImage from '../../assets/girl-with-glasses.svg'
import PersonImage from "../../assets/int-img.svg"
import RightArrow from '../../assets/arrow-b-right.svg'
import SectionSeperator from '../../assets/border-img.svg'
import './home-intro.scss';
import Button from '../button/button';
import { useNavigate } from 'react-router-dom';
import config from '../../config'

const HomeIntro = ({ onClickSignup }) => {
    const navigate = useNavigate()
    
    const onClickTryNow = () => {
        navigate("/login")
    }


    return (
        <section className='home-intro-wrap'>
            <Container maxWidth='lg'>
                <Grid container item spacing={{xs:2.25, md:15}} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className='sec-img text-center'>
                            <img src={SelfLearningImage} alt='' className='mw-100' />
                        </Box>
                        <Box display={{xs:'block', md: 'none'}} mt={2.25}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz empowers students to achieve academic goals and accelerate learning anytime, anywhere, all in one place.</h3>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className='sec-content'>
                            <h1 className='text-h1 title mt-0 mb-0'>Self-Learning is the Catalyst for Unlocking Student Potential</h1>
                            <Box display={{xs:'none', md: 'block'}}>
                                <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz empowers students to achieve academic goals and accelerate learning anytime, anywhere, all in one place.</h3>
                            </Box>                        
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item spacing={{xs:2.25, md:15}} alignItems={'center'} justifyContent={'center'} className='order-reverse'>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className='sec-img' mt={{xs: 2.25, md: 2.75}} textAlign={{xs: 'center', md: 'right'}}>
                            <img src={PersonImage} alt='' className='mw-100' />
                        </Box>
                        <Box display={{xs:'block', md: 'none'}} mt={2.25}>
                            <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz takes on a mentor role and cares for your aspirations, your academic struggles, and your self-fulfillment.</h3>
                            <Box textAlign={'center'} mt={2.25}>
                                {
                                    config.showStaticPasscode &&
                                    <Button onClick={onClickSignup} primary text={'Sign Up'} iconOnly={RightArrow} withIcon />
                                }
                                {
                                    !config.showStaticPasscode &&
                                    <Button onClick={onClickTryNow} primary text={'Try Now'} iconOnly={RightArrow} withIcon />
                                }
                                
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className='sec-content' mt={{xs:5.6, md: 2.75}}>
                            <h1 className='text-h1 title mt-0 mb-0'>Intelligent, Perceptive, Thought-Provoking</h1>
                            <Box display={{xs:'none', md: 'block'}}>
                                <h3 className='text-h3 subtitle text-muted mb-0'>Mr. EzPz takes on a mentor role and cares for your aspirations, your academic struggles, and your self-fulfillment.</h3>
                                {
                                    config.showStaticPasscode &&
                                    <Button onClick={onClickSignup} primary text={'Sign Up'} iconOnly={RightArrow} withIcon />
                                }
                                {
                                    !config.showStaticPasscode &&
                                    <Button onClick={onClickTryNow} primary text={'Try Now'} iconOnly={RightArrow} withIcon />
                                }
                                
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box className="section-seperator text-center" mt={{xs:4.75, md: 11.6}} >
                    <img src={SectionSeperator} alt='' className='mw-100 w-100' />
                </Box>
            </Container>

        </section>
    )
}

export default HomeIntro;
