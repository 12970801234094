import React, { ReactNode, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import ChatWindowLogo from '../../assets/chat-window-logo.svg'
import ChatBotIcon from '../../assets/chat-bot-bubble-icon.svg'
import ArrowIcon from '../../assets/arrow-right.svg'
import SendIcon from '../../assets/send-icon.svg'
import SocraticIconDark from '../../assets/ic-brain-dark.svg'
import SocraticIconLight from '../../assets/ic-brain-light.svg'
import OnboardingIcon from '../../assets/ic-ques-circle.svg'
import ClearIcon from '../../assets/ic-clear.svg'
import './chatWindow.scss'
import TextArea from "../textArea/textArea";
import Button from "../button/button";
import AIResponse from "./aiResponse";
import UserResponse from "./userResponse";
import FormattedAIResponse from "./formattedAIResponse";
import { authChat } from "../../axios";
import { encodeToBase64, getDocData, scrollToBottom } from "../../methods/commonMethods";
import { Loader } from "../../components"
import { ConversationTypId } from "../../constants/enums";
import ToolTip from "../tooltip/tooltip";
import { ClickAwayListener, Grid } from "@mui/material";
import "../onboarding-dialog/onboarding-dialog.scss"
import {OnboardingDialog} from "../../components";

const ChatWindow = ({ }) => {

    //###################################### States Area start #####################################//

    const [disableSendBtn, setDisableSendBtn] = useState(false)

    //=========================== State for socratic learning ============================//
    const [isSocraticLearning, setIsSocraticLearning] = useState(localStorage.getItem("isMainChatSocraticLearning") ?? true)
    const [showSocraticLearningBtnTooltip, setShowSocraticLearningBtnTooltip] = useState(false)

    //========================== State for text length ==========================//
    const [textLength, setTextLength] = useState(0)

    //========================= State for main conversation is updated ==========================//
    const [isArrayMainConversationUpdated, setIsArrayMainConversationUpdated] = useState(false)

    //========================= State for loader =========================//
    const [showAIResponseLoader, setShowAIResponseLoader] = useState(false)

    //======================= State to record text area value ===============================//
    const [valueTextArea, setValueTextArea] = useState("")

    //===================== State for user input ====================//
    // Example Model:   {message:'', role:'', isUpdated: false}
    const [objMainChatInput, setObjMainChatInput] = useState({})

    //===================== State for AI response ======================//
    // Example Model:   {message:'', role:''}
    const [objMainChatOutput, setObjMainChatOutput] = useState({})

    //====================== State for Conversation ==========================//
    // Example model:   [array contains objects of objMainChatInput and objExpDrawerOutput]
    const [arrayMainConversation, setArrayMainConversation] = useState([])

    //=============================== Ref for scrollable drawer content ================================//
    const refMainChatContentScrollable = useRef(null)

    const [openOnboarding, setOpenOnboarding] = useState(false);


    //#################################### State Area end #####################################//




    //################################### Use Effect Area start ###################################//

    useEffect(() => {
        if (showAIResponseLoader) {
            setDisableSendBtn(true)
        } else {
            setDisableSendBtn(false)
        }
    }, [showAIResponseLoader])

    //===================================== Use effect to show user input when a user sends input ==============================//
    useEffect(() => {

        // Handling to call function only when objMainChatInput.message is updated
        if (objMainChatInput.isUpdated) {
            //console.log("Function called")

            handleMainChat(objMainChatInput.message)
            setObjMainChatInput({})
        }
    }, [objMainChatInput.message])

    //================================ Use effect for scroll to bottom for main chat window ========================//
    useEffect(() => {
        if (isArrayMainConversationUpdated) {

            //localStorage.setItem("main_conversation", JSON.stringify(arrayMainConversation))
            scrollToBottom(refMainChatContentScrollable)
        }
    }, [arrayMainConversation])

    //=============================== Use effect to load main conversation if it is present in local storage =============================//
    useEffect(() => {
        getDocData().then(docData => {
            if (docData) {
                getMainConversationByDocId(docData.document_id)
            }
        }).catch(error => {
            console.error('Failed to get document id :' + error)
        })


        // const mainConversationArray = JSON.parse(localStorage.getItem("main_conversation"))
        // if (mainConversationArray) {
        //     setArrayMainConversation(mainConversationArray)
        // }
    }, [])

    //################################## Use Effect Area end ###################################//


    //######################################### API Calls ###############################################//

    //========================== Function to fetch conversation by doc id ==============================//
    const getMainConversationByDocId = (documentId) => {
        authChat.get(`/mainChat/GetMainConversation?documentId=${documentId}&conversationTypeId=${ConversationTypId.MainConversation}`)
            .then((res) => {
                console.log("ConversationByDocId", res)
                res.data.conversation.map((objMessage => {
                    setArrayMainConversation((preValues) => [
                        ...preValues,
                        { message: objMessage.content, role: objMessage.role }
                    ])
                }))
            })
    }


    //#################################### Functions Area start #######################################//

    //========================== Function for main chat ==============================//
    const handleMainChat = async (message) => {
        //console.log("message", message)
        const trimmedMessage = message.trim();
        const docData = await getDocData();

        if (trimmedMessage === '' || trimmedMessage === '"' || trimmedMessage === '" "' || trimmedMessage === '""') {
            message = ''; // Clear the input field
            alert("Invalid input! Please enter valid text.");



        } else {
            const main_conversation_id = JSON.parse(localStorage.getItem("main_conversation_id"))
            // console.log(main_conversation_id)
            if (main_conversation_id) {

                // Object to post to main chat endpoint
                const mainChatModel = {
                    message: trimmedMessage,
                    mainConversationId: main_conversation_id,
                    documentId: docData.document_id,
                    conversationTypeID: ConversationTypId.MainConversation,
                    isSocraticLearning: localStorage.getItem("isMainChatSocraticLearning") ?? true
                }
                authChat.post("/mainChat", {payload: encodeToBase64(mainChatModel)})
                    .then((res) => {
                        // hide loader when ai response is received
                        setShowAIResponseLoader(false)

                        const aiResponseModel = { message: res.data.message, role: "assisstant" }

                        setObjMainChatOutput(aiResponseModel)
                        setArrayMainConversation((preValues) => [
                            ...preValues,
                            aiResponseModel
                        ])
                    })
            } else {
                const mainChatModel = {
                    message: trimmedMessage,
                    documentId: docData.document_id,
                    conversationTypeID: ConversationTypId.MainConversation,
                    isSocraticLearning: localStorage.getItem("isMainChatSocraticLearning") ?? true
                }
                authChat.post("/mainChat", {payload: encodeToBase64(mainChatModel)})
                    .then((res) => {
                        //console.log("Response", res)

                        // hide loader when ai response is received
                        setShowAIResponseLoader(false)

                        localStorage.setItem("main_conversation_id", JSON.stringify(res.data.main_conversation_id))

                        const aiResponseModel = { message: res.data.message, role: "assisstant" }

                        setObjMainChatOutput(aiResponseModel)
                        setArrayMainConversation((preValues) => [
                            ...preValues,
                            aiResponseModel
                        ])
                    })
            }
        }
    }

    const onClickSendBtn = () => {
        const trimmedMessage = valueTextArea.trim()
        if (trimmedMessage) {
            if (trimmedMessage === '' || trimmedMessage === '"' || trimmedMessage === '" "' || trimmedMessage === '""') {
                alert("Invalid input! Please enter valid text.");
            } else {
                //console.log("InputVal", valueTextArea)

                setIsArrayMainConversationUpdated(true)
                const inputModel = { message: valueTextArea, role: 'user', isUpdated: true }

                setObjMainChatInput(inputModel)

                setArrayMainConversation((preValues) => [
                    ...preValues,
                    inputModel
                ])

                // show loader when ai response is being fetched
                setShowAIResponseLoader(true)

                // empty the text area
                setValueTextArea("")

                // reset text length
                setTextLength(0)
            }
        }
    }

    //====================== Function on click clear chat ========================//
    const onClickClearChat = () => {
        setArrayMainConversation([])
        // const mainConversationId = JSON.parse(localStorage.getItem("main_conversation_id"))
        // authChat.get(`/mainChat/Delete?mainConversationId=${mainConversationId}`)
        // .then(() => {
        //     localStorage.removeItem("main_conversation_id")
        //     setArrayMainConversation([])
        //     console.log("Chat cleared success")
        // })

    }

    //======================== Function call on press Enter ===========================//
    const onKeyPressInMainChat = (e) => {

        // Chceck if value is given by user
        if (valueTextArea.trim()) {
            if (e.key === "Enter" && !e.shiftKey) {
                if (!disableSendBtn) {
                    onClickSendBtn(valueTextArea)

                    // remove the text area value
                    setValueTextArea('')

                    // prevent line break if value is submitted
                    e.preventDefault()

                    //reset the text length
                    setTextLength(0)
                }
            }
        }
    }

    //========================== Function for on click socratic learning btn ===============================//
    const onClickSocraticLearningBtn = () => {
        const temp = !isSocraticLearning
        setShowSocraticLearningBtnTooltip(true)
        setIsSocraticLearning(temp)
        localStorage.setItem("isMainChatSocraticLearning", temp)
    }

    //============================ Function on Socratic learning btn tooltip close =========================//
    const handleSocraticLearningBtnCloickAway = () => {
        setShowSocraticLearningBtnTooltip(false)
    }

    //########################################### Functions Area end ##########################################//





    return (
        <>
        <div className="chat-window-wrap">

            {/*********************  Code for clear chat *******************/}
            {/* <div className="clear-chat text-right">
                <a href="javascript:void(0)" onClick={onClickClearChat} className="clear-btn m-0 text-decoration-none">
                    <span className="btn-icon">
                        <img src={ClearIcon} alt="Clear Chat" />
                    </span>
                    <span className="btn-text">Clear chat</span>
                </a>
            </div> */}
            {/* ********************************************************** */}
            <div className="chat-area-wrap scrollable">
                <div className="chat-logo-area">
                    <img src={ChatWindowLogo} alt="" />
                </div>
                <p className="logo-bottom-text">
                    In this space, we can discuss this document or related academic topics
                </p>
                <div className="chat-area">
                    <AIResponse aiResponse={
                        <>
                            Hey, there! Welcome to Mr. EzPz. Let's walk through some features that can make studying easier for you. Click the {' '}
                            <a className="action-icon cp">
                                <img src={OnboardingIcon} alt='' style={{position: 'relative', top: '3px'}}/>
                            </a> 
                            {''} above to start the onboarding process.
                        </>
                    } />
                    {
                        arrayMainConversation.map((objMessage, index) => {
                            // Check if role is user then show user response div else show ai response div since role will be assisstant
                            //================ Div to show user response ================//
                            if (objMessage.role === 'user') {
                                return (<UserResponse userResponse={objMessage.message} />)
                            }

                            //============ Div to show assisstant response ===========//
                            else {
                                if (objMessage.message.includes("\n")) {
                                    const pointsArray = objMessage.message.split('\n').filter(point => point.trim() !== '')

                                    // Trim each substring until an alphabet or a number is encountered
                                    // const trimmedArray = pointsArray.map(point => point.replace(/^[^\w\d]+/, ''));
                                    return (
                                        <FormattedAIResponse pointsArray={pointsArray} />
                                    )
                                } else {
                                    return (<AIResponse aiResponse={objMessage.message} />)
                                }
                            }
                        })
                    }
                    {showAIResponseLoader && (<Loader />)}
                    <div ref={refMainChatContentScrollable} />
                    {/* <AIResponse
                        aiResponse={'Following our recent conversations and your latest educational materials, I would like to propose several topics for discussion.'}
                    />
                    <AIResponse
                        aiResponse={'What would you like to chat about?'}
                        chatBotIcon={false}
                    />
                    <div className="chat-suggestions-link">
                        <div className="chat-bubble">
                            <img src={ArrowIcon} alt="" />
                            <a href="javascript:void(0)" className="text-dark decoration m-0"><small className="text-small">Ketone body formation</small></a>
                        </div>
                        <div className="chat-bubble">
                            <img src={ArrowIcon} alt="" />
                            <a href="javascript:void(0)" className="text-dark decoration m-0"><small className="text-small">Converting fatty acids into ketone bodies</small></a>
                        </div>
                        <div className="chat-bubble">
                            <img src={ArrowIcon} alt="" />
                            <a href="javascript:void(0)" className="text-dark decoration m-0"><small className="text-small">Nanotechnology in Drug Delivery</small></a>
                        </div>
                    </div>
                    <UserResponse
                        userResponse={'Ketone body formation'}
                    />
                    <AIResponse
                        aiResponse={'Absolutely, let explore this together. To start, think about what happens when our body needs an alternative energy source due to a shortage of glucose. Can you describe the situation where ketone bodies come into play?'}
                    />
                    <UserResponse
                        userResponse={'Well, I remember that our body starts using fat for energy when there not enough glucose'}
                    />
                    <AIResponse
                        aiResponse={'Absolutely, let explore this together. To start, think about what happens when our body needs an alternative energy source due to a shortage of glucose. Can you describe the situation where ketone bodies come into play?'}
                    /> */}
                </div>
            </div>
            <div className="chat-typing-area">
                <TextArea
                    onKeyPress={onKeyPressInMainChat}
                    placeholder={'Ask me anything ...'}
                    value={valueTextArea}
                    rows={8}
                    actionBtn={
                        <>
                            <Button className={`${disableSendBtn ? "unclickable" : ""}`} outlineBtn type={'submit'} onClick={onClickSendBtn} iconOnly={SendIcon} />
                            <Grid item>
                                <ClickAwayListener onClickAway={handleSocraticLearningBtnCloickAway}>
                                    <div>
                                        <ToolTip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            // open={showSocraticLearningBtnTooltip}
                                            disableFocusListener
                                            // disableHoverListener
                                            disableTouchListener
                                            arrow
                                            title={
                                                <span className="tooltip-content fw-sbold">
                                                    {isSocraticLearning ? 
                                                        <span>
                                                            <span className="text-body1 lh-18 d-block">Socratic Learning : <span className="text-success">Enabled</span></span>
                                                            <p className="mt-0 mb-10 soc-learning-small">(Experimental Mode)</p>
                                                            <span className="text-body2">Guided learning through thought-provoking questions to help you discover your own answers.</span>
                                                        </span> 
                                                        :
                                                        <span>
                                                            <span className="text-body1 lh-18 d-block mb-10">Socratic Learning : <span className="text-danger">Disabled</span></span>
                                                            <span className="text-body2">You are in a direct learning mode where your questions are answered straightforwardly.</span>
                                                        </span>
                                                    }
                                                    {/* {isSocraticLearning ? 
                                                        <span>Disable Socratic Learning. Switch to a direct learning mode where your questions are answered straightforwardly</span> 
                                                        :
                                                        <span>Enable Socratic Learning. Guided learning through thought-provoking questions to help you discover your own answers.</span>
                                                    } */}
                                                    {/* Socratic Learning : {isSocraticLearning ? <span className="text-success fw-bold">On</span> : <span className="text-danger fw-bold">Off</span>} */}
                                                    {/* <span className="text-success fw-bold">On</span> */}
                                                </span>
                                            }
                                            placement="left-end">
                                            <Button className='socratic-btn' onClick={onClickSocraticLearningBtn} outlineBtn type={'submit'} iconOnly={isSocraticLearning ? SocraticIconDark : SocraticIconLight} />
                                            {/* for light icon SocraticIconLight */}
                                        </ToolTip>
                                    </div>
                                </ClickAwayListener>
                            </Grid>
                        </>
                    }
                    onChange={(e) => {
                        const value = e.target.value
                        if (value.length <= 1500) {
                            setValueTextArea(value)
                            setTextLength(value.length)
                        }
                    }}
                    helpText={`${textLength} / 1500`}
                />
            </div>
        </div>
        {openOnboarding && <OnboardingDialog openOnboarding={openOnboarding} setOpenOnboarding={setOpenOnboarding} />}
        </>
    );
};

export default ChatWindow;

ChatWindow.propTypes = {
};