import React, { useEffect, useRef, useState } from 'react'
import { Container, Grid, Box } from '@mui/material';
import BannerFormBg from '../../assets/banner-form-bg.svg'
import BannerFormBgMobile from '../../assets/form-bg-mobile.svg'
import BannerPerson from '../../assets/ezpz-person.svg'
import ButtonImage from '../../assets/btn-img.svg'
import ButtonHoverImage from '../../assets/btn-hover-img.svg'
import EmailPlaceholder from '../../assets/input-ph-email.svg'
import MajorPlaceholder from '../../assets/input-ph-major.svg'
import TooltipIcon from '../../assets/ic-exc-w-bg.svg'
import './home-banner.scss';
import { isEmailValid } from '../../methods/commonMethods'
import { authToken } from '../../axios';
import ToolTip from '../tooltip/tooltip';
import { HomeConfirmationDialog } from '../../components'

const HomeBanner = ({ emailRef }) => {

    //################################# States ##############################//
    const [isRequestSent, setIsRequestSent] = useState(false)
    const [showConfirmationDialog, setConfirmationDialog] = useState(false)
    const [openEmailTip, setEmailTip] = useState(false)
    const [openMajorTip, setMajorTip] = useState(false)
    const emailInput = useRef(null)
    const majorInputRef = useRef(null)
    const [isNewRegistered, setIsNewRegistered] = useState(true)

    const [registerationData, setRegisterationData] = useState({
        email: "",
        major: ""
    })

    //########################### UseEffects ############################//
    useEffect(() => {
        function handleClickOutside(event) {
            // if (openEmailTip) {

            // }
            if (emailRef.current && !emailRef.current.contains(event.target)) {
                setEmailTip(false)
            }
            
            if (majorInputRef.current && !majorInputRef.current.contains(event.target)) {
                setMajorTip(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [emailRef, majorInputRef])

    //############################## Functions ##############################//


    const onEmailValueChange = (e) => {
        const value = e.target.value
        setRegisterationData({
            ...registerationData,
            email: value
        })

        if (value.length > 0) {
            setEmailTip(false)
        }

        // if (document.activeElement === document.getElementById('emailInput')) {
        //     if (value.length === 0) {
        //         setEmailTip(true)
        //     } else {
        //         setEmailTip(false)
        //     }
        // }
    }

    const onMajorValueChange = (e) => {
        const value = e.target.value
        setRegisterationData({
            ...registerationData,
            major: value
        })

        if (value.length > 0) {
            setMajorTip(false)
        }
    }

    const onClickSubmit = () => {
        setIsRequestSent(true)

        const trimmedEmail = registerationData.email.trim()
        const trimmedMajor = registerationData.major.trim()

        // Check if email is empty
        if (trimmedEmail.length === 0) {
            setEmailTip(true)
            setIsRequestSent(false)
        } else {
            if (isEmailValid(trimmedEmail)) {
                if (trimmedMajor) {
                    addBetaUser(trimmedEmail, trimmedMajor)

                } else {
                    // alert("Please enter your major")
                    setMajorTip(true)
                    setIsRequestSent(false)
                }
            } else {
                setEmailTip(true)
                setIsRequestSent(false)
            }
        }

    }

    const onClickConfirmationOk = () => {
        setConfirmationDialog(false)
    }

    const onFocusEmail = () => {
        setEmailTip(false)
    }

    const onFocusMajor = () => {
        setMajorTip(false)
    }

    // const onEmailFocus = () => {
    //     if (registerationData.email === "" || registerationData.email === null || registerationData.email === "undefined") {
    //         setEmailTip(true)
    //     } else {
    //         setEmailTip(false)
    //     }
    // }

    //############################################# API Calls ############################################//
    const addBetaUser = (email, major) => {
        const addBetaUserModel = {
            email: email,
            major: major
        }

        authToken.post("/betaRegisteration", addBetaUserModel)
            .then((res) => {
                // alert(res.data.message)
                if (res.data.message.includes("already")) {
                    setIsNewRegistered(false)
                } else {
                    setIsNewRegistered(true)
                }
                setIsRequestSent(false)
                setConfirmationDialog(true)

                // Clear the input fields after successfull server response
                setRegisterationData({
                    email: "",
                    major: ""
                })
            })
            .catch((err) => {
                alert("Closed Beta signup failed!")
                setIsRequestSent(false)
            })
    }

    return (
        <section className='banner-wrap'>
            <Container maxWidth='lg'>
                <Grid container item spacing={{ xs: 2.5, md: 10 }} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div className='banner-content'>
                            <h1 className='title text-dark mt-0 mb-0 text-xl'>Rethinking the Art of Learning</h1>
                            <h2 className='subtitle text-dark mb-0'>An AI-based learning suite that offers hyper-personalized instruction to help you master your courses</h2>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box className='banner-img'>
                            <Box className='banner-form'>
                                <ToolTip
                                    open={openEmailTip}
                                    arrow={true}
                                    title={
                                        <div className='tooltip-content'>
                                            <span className='icon'>
                                                <img className='va-middle' src={TooltipIcon} alt='' />
                                            </span>
                                            <span className='tooltip-text'>
                                                Please enter a valid email address
                                            </span>
                                        </div>
                                    }
                                    placement={'bottom-start'}
                                >
                                    <Box className='custom-field' display={'none'}>
                                        <input onFocus={onFocusEmail} ref={emailRef} value={registerationData.email} onChange={onEmailValueChange} type='email' className={`form-control ${(registerationData.email.length > 0) ? 'has-value' : ''}`} /> {/* has-value */}
                                        <span className='input-placeholder'>
                                            <img src={EmailPlaceholder} alt='' />
                                        </span>
                                    </Box>
                                </ToolTip>
                                <ToolTip
                                    open={openMajorTip}
                                    arrow={true}
                                    title={
                                        <div className='tooltip-content'>
                                            <span className='icon'>
                                                <img className='va-middle' src={TooltipIcon} alt='' />
                                            </span>
                                            <span className='tooltip-text'>
                                                Please fill out this field
                                            </span>
                                        </div>
                                    }
                                    placement={'bottom-start'}
                                >
                                    <Box className='custom-field' display={'none'}>
                                        <input ref={majorInputRef} onFocus={onFocusMajor} value={registerationData.major} onChange={onMajorValueChange} type='text' className={`form-control ${((registerationData.major.length > 0) ? 'has-value' : '')}`} />
                                        <span className='input-placeholder'>
                                            <img src={MajorPlaceholder} alt='' />
                                        </span>
                                    </Box>
                                </ToolTip>
                                <Box className={`custom-button ${isRequestSent ? 'unclickable' : ''}`} display={'none'}>
                                    <button onClick={onClickSubmit} type='button'>
                                        <span className='btn-img'>
                                            <img className='user-highlight-none' src={ButtonImage} alt='' />
                                        </span>
                                        <span className='btn-hover-img'>
                                            <img className='user-highlight-none' src={ButtonHoverImage} alt='' />
                                        </span>
                                    </button>
                                </Box>
                            </Box>
                            <img src={BannerFormBgMobile} alt='' className='form-bg-mobile' />
                            <img src={BannerFormBg} alt='' className='mw-100 form-bg' />
                            <img className='ezpz-person mw-100' src={BannerPerson} alt='' />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <HomeConfirmationDialog isNewRegistered={isNewRegistered} open={showConfirmationDialog} onClickOk={onClickConfirmationOk} />
        </section>
    )
}

export default HomeBanner;
