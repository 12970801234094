import React from 'react'
import { Container, Grid, Box } from '@mui/material';
import Image01 from '../../assets/monitor-keyboard-img.svg'
import ArrowRight from '../../assets/arrow-y-right.svg'
import './home-security.scss';
import SectionTitle from '../section-title/section-title';

const Security = ({ }) => {
  return (
    <section className='security-section-wrap'>
        <Container maxWidth='lg'>
            <Grid container item spacing={{xs:6.25, md:11.25}} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box display={{xs:'block', md: 'none'}}>
                        <h1 className='text-h1 title mt-0 mb-0'>AI, Security, and Safety</h1>
                    </Box>
                    <Box className='sec-img' mt={{xs:6.25, md:0}}>
                        <img src={Image01} alt='' className='w-100 mw-100' />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box className='sec-content'>
                        <Box display={{xs:'none', md: 'block'}} textAlign={{xs: 'center', md: 'left'}}>
                            <h1 className='text-h1 title mt-0 mb-0'>AI, Security, and Safety</h1>
                        </Box>
                        <h2 className='text-h2 title mb-0 mt-24'>How We Address Your Top Concerns</h2> 
                        <ul className='list text-muted'>
                            <li className='subtitle'>
                                <span className='text-sm-bold'>End-to-End Encryption </span>
                            </li>
                            <li className='subtitle'>
                                <span className='text-sm-bold'>Student Data Protection: </span>We do not share student data with third parties, nor use it to train AI models.
                            </li>
                            <li className='subtitle'>
                                <span className='text-sm-bold'>Responsible AI: </span>We strive to develop a contextual AI system that delivers accurate and equitable responses, fine-tuned for education.
                            </li>
                        </ul>
                    </Box>
                </Grid>
            </Grid> 
        </Container>
    </section>
  )
}

export default Security;
