import React from "react";
import PropTypes from 'prop-types';
import { clsx } from "clsx";
import './toggleButton.scss'

const ToggleButton = ({ id, checked, varient, onChange }) => {
    return (
        <div
            className={clsx({
                'toggle-btn': true,
                'btn-on-off': varient == 'on-off'
            })}>
            <input onChange={onChange} type="checkbox" id={id} checked={checked} />
            <label for={id}></label>
        </div>
    );
};

export default ToggleButton;

ToggleButton.propTypes = {
    id: PropTypes.string,
    checked: PropTypes.string,
    varient: 'on-off' | 'basic'
};