import './App.css';
import './styles/custom/utilities.scss';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import UploadFile from './components/uploadFile/UploadFile';
import ViewPdf from './components/pdfUploaded/PDFUploaded';
import LetsStart from './components/pdfMobile/LetsStart';
import Index from './components/LandingPage/Index';
import Login from './components/LandingPage/Login';
import { BaseLayout } from './components';
import { Main, ActivityPage, HomePage } from './pages';
import { checkLoggedIn } from './methods/commonMethods';
import { Restrict } from './components/restrict/restrict';
import { TermsAndCondition } from './components/terms-and-conditions/terms-and-conditions';
import { PrivacyPolicy } from './components/privacy-policy/privacy-policy';

function PublicRoute({ element }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {
    const checkLogStatus = async () => {
      const apiToken = JSON.parse(localStorage.getItem("api_token"));
      if (apiToken) {
        try {
          const loggedIn = await checkLoggedIn();
          setIsLoggedIn(loggedIn);
        } catch (error) {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };
    checkLogStatus();
  }, []);

  if (isLoggedIn === null) return null;
  return isLoggedIn ? <Navigate to="/activity" /> : element;
}

function ProtectedRoute({ element }) {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkLogStatus = async () => {
      const apiToken = JSON.parse(localStorage.getItem("api_token"));
      if (apiToken) {
        try {
          const loggedIn = await checkLoggedIn();
          setIsLoggedIn(loggedIn);
        } catch (error) {
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the threshold as needed
    };

    checkLogStatus();
    handleResize(); // Check on initial render
  }, []);

  if (isLoggedIn === null) return null;
  if (isMobile) {
    return <Navigate to="/restrict" />; // Redirect to a mobile not supported page
  }
  return isLoggedIn ? element : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/login" element={
          <PublicRoute element={<Login />} />
      } />
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path='/home' element={<HomePage />} />
        <Route path="/restrict" element={<Restrict/>} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />


        <Route
          path="/uploadFile"
          element={
            <ProtectedRoute element={<UploadFile />} />
          }
        />
        <Route
          path="/viewPdf"
          element={
            <ProtectedRoute element={<ViewPdf />} />
          }
        />
        {/* <Route
          path="/letstart"
          element={
            <ProtectedRoute element={<LetsStart />} />
          }
        /> */}
        <Route path='/main/:type' element={<ProtectedRoute element={<Main/>}/>} />
        <Route
          path="/activity"
          element={
            <ProtectedRoute element={<ActivityPage />} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
