import React from 'react'
import { Container } from '@mui/material';
import LogoImage from '../../assets/landing-header-logo.svg'
import RightArrowIcon from '../../assets/arrow-b-right.svg'
import Button from '../button/button';
import './landing-header.scss';
import { useNavigate } from 'react-router-dom';

import config from '../../config'

const LandingHeader = ({ }) => {
  const navigate = useNavigate()

  const onClickTryNow = () => {
    navigate("/login")
  }

  return (
    <header>
        <Container maxWidth='lg'>
            <div className='header-logo'>
                <img src={LogoImage} alt='' />
            </div>
            
            {/* Try now to redirect user to login */}
            { !config.showStaticPasscode &&
              <div className='header-action'>
                <Button onClick={onClickTryNow} secondary outlineBtn withIcon text={'Try Now'} iconOnly={RightArrowIcon} exception={true} />
              </div>
            }
            
        </Container>
    </header>
  )
}

export default LandingHeader;
